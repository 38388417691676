import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  get,
} from 'lodash';
import {
  API,
  LocationActions,
  NavigationSide,
  NavigationSideItem,
  viCalendar,
  viMicrophoneLibrary,
  viStore,
  viExact,
  viFrequency,
  viDollar,
  withRouter,
} from 'vibeguide';
import LocationHeader from './LocationHeader';
import './IndividualLocationContainer.scss';

class IndividualLocationContainer extends Component {
  componentDidMount() {
    const {
      params,
      socket,
    } = this.props;

    const locationId = get(params, 'id', null);

    socket.emit('subscribe', {
      topic: 'location',
      id: locationId,
    });

    this.resetCurrentLocation();
  }

  componentWillUnmount() {
    const {
      params,
      socket,
      resetCurrentLocation,
    } = this.props;

    const locationId = get(params, 'id', null);

    socket.emit('unsubscribe', {
      topic: 'location',
      id: locationId,
    });

    resetCurrentLocation();
  }

  resetCurrentLocation = async () => {
    const {
      params,
      setCurrentLocation,
    } = this.props;

    const locationId = get(params, 'id', null);
    const locationObj = await API.Location.getById(locationId);

    setCurrentLocation(locationObj);
  };

  render() {
    const {
      user,
      location,
      currentLocation,
    } = this.props;

    const startLocId = location.pathname.substring(10);
    const endLocId = startLocId.indexOf('/');
    const locId = startLocId.substring(0, endLocId);

    let adTargetingUrl = '';

    if (user.can('location.assign_targeting_tag')) {
      adTargetingUrl = `/location/${locId}/targeting/triton`;
    } else if (user.can('location.assign_device_attribute')) {
      adTargetingUrl = `/location/${locId}/targeting/vistar`;
    } else if (user.can('location.assign_order_id')) {
      adTargetingUrl = `/location/${locId}/targeting/vistar-order`;
    }

    return (
      <div className="IndividualLocationContainer">
        <NavigationSide>
          <NavigationSideItem
            text="Schedule"
            id="schedule-link"
            url={`/location/${locId}/schedule`}
            icon={viCalendar}
            show
          />

          <NavigationSideItem
            text="Profile"
            id="profile-link"
            url={`/location/${locId}/profile`}
            icon={viStore}
            show
          />

          <NavigationSideItem
            text="Assignments"
            id="assignments-link"
            url={`/location/${locId}/assignments`}
            icon={viMicrophoneLibrary}
            show={user.can('message.view')
              || user.can('messagelist.view')
              || user.can('playlist.view')
              || user.can('mix.view')
              || user.can('music.view')
              || user.can('baseline.view')
              || user.can('event.view')
              || user.can('user.view')}
          />

          <NavigationSideItem
            text="Ad Programs"
            id="ad-programs-link"
            url={`/location/${locId}/programs`}
            icon={viDollar}
            show={user.can('location-ad-program.view')}
          />

          <NavigationSideItem
            text="Ad Targeting"
            id="ad-targeting-link"
            url={adTargetingUrl}
            matchUrl={`/location/${locId}/targeting`}
            icon={viExact}
            show={user.hasAnyOf([
              'location.assign_targeting_tag',
              'location.assign_device_attribute',
              'location.assign_order_id',
            ])}
          />

          <NavigationSideItem
            text="Telemetry"
            id="telemetry-link"
            url={`/location/${locId}/telemetry`}
            icon={viFrequency}
            show={user.sysAdmin && user.can('location.view_telemetry')}
          />
        </NavigationSide>

        <div className="location-page-header">
          {currentLocation ? (
            <LocationHeader />
          ) : null}
        </div>

        <Outlet />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
    socket: state.socket.connection,
  };
}

const mapDispatchToProps = {
  setCurrentLocation: LocationActions.setCurrentLocation,
  resetCurrentLocation: LocationActions.resetCurrentLocation,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndividualLocationContainer));
