import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  API,
  ToastActions,
  FlightModel,
  AdProviderHelper,
  Field2 as Field,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  VibeButtonNew,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import './SegmentFlightNew.scss';

function SegmentFlightNew({
  className,
  user,
  segmentStartDate,
  segmentEndDate,
  queueToast,
  onCloseFlights,
}) {
  const [flight, setFlight] = useState(new FlightModel());
  const [advertiserName, setAdvertiserName] = useState('');

  const onClose = (flight) => {
    onCloseFlights(flight._id ? flight : null);
    setFlight(new FlightModel());
  };

  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    setFlight({
      ...flight,
      [name]: value,
    });
  };

  const onChangeStartDate = (startDate) => {
    setFlight({
      ...flight,
      startDate,
    });
  };

  const onChangeEndDate = (endDate) => {
    setFlight({
      ...flight,
      endDate,
    });
  };

  const onChangeDropdown = ({
    name,
    value,
    // data,
  }) => {
    setFlight({
      ...flight,
      [name]: value,
    });
  };

  const onChangeAdvertiser = (data) => {
    const {
      id,
      value,
    } = data;

    if (id) {
      setFlight({
        ...flight,
        advertiserId: id,
      });
    }

    setAdvertiserName(value);
  };

  const onSave = async () => {
    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      // remove the flight ID from the model when creating a flight
      delete flight._id;

      const flightResponse = await API.Flight.create(flight);
      const success = get(flightResponse, '[0].type') === 'FLIGHT.CREATED';

      if (success) {
        const flightId = get(flightResponse, '[0].documentId');
        const flight = get(flightResponse, '[0].data');

        if (flightId && flight) {
          const newFlight = {
            _id: flightId,
            ...flight,
          };
          onClose(newFlight);
        }

        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSaveFlight'));
      } else {
        console.error('Error saving flight to API', flightResponse);

        queueToast({
          type: 'error',
          title: 'Error Saving Flight',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error saving flight', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Flight',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  const disableSave = !flight.name
    || !flight.opportunityName
    || !flight.opportunityId
    || !flight.startDate
    || !flight.endDate;

  return (
    <SidePanelContainer className={classNames('SegmentFlightNew', className)}>
      <SidePanelHeader
        className="flight-header"
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="title">
          New Flight
        </div>
      </SidePanelHeader>

      <SidePanelContent className="flight-content">
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              type="text"
              label="Flight Name"
              name="name"
              placeholder="Name"
              value={flight.name}
              tabIndex={50}
              onChange={onChange}
              autoFocus
              required
            />
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Field
              type="text"
              label="Opportunity Name"
              name="opportunityName"
              placeholder="Opportunity Name"
              value={flight.opportunityName}
              tabIndex={51}
              onChange={onChange}
              required
            />
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Field
              type="text"
              label="Opportunity Salesforce ID"
              name="opportunityId"
              placeholder="Opportunity Salesforce ID"
              value={flight.opportunityId}
              tabIndex={52}
              onChange={onChange}
              required
            />
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Field
              type="text"
              label="Deal ID"
              name="dealId"
              placeholder="Deal ID"
              value={flight.dealId}
              tabIndex={53}
              onChange={onChange}
            />
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Field
              type="text"
              label="Order Reference ID"
              name="extReferenceId"
              placeholder="Order Reference ID"
              value={flight.extReferenceId}
              tabIndex={54}
              onChange={onChange}
            />
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Field
              type="dropdown"
              label="Delivery System"
              name="deliverySystem"
              value={flight.deliverySystem}
              tabIndex={55}
              dropdownProps={{
                type: 'delivery system',
                attr: 'value',
                canCreate: user.can('flight_delivery_system.create'),
                successMessage: 'FLIGHTDELIVERYSYSTEM.CREATED',
                create: API.Flight.DeliverySystem.create,
                fetch: API.Flight.DeliverySystem.list,
              }}
              onChange={onChangeDropdown}
              required
            />
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Field
              type="select"
              label="Ad Provider"
              name="adProvider"
              value={flight.adProvider}
              options={[
                {
                  label: '',
                  value: '',
                },
                ...AdProviderHelper.list.map((adProvider) => {
                  return {
                    label: adProvider.name,
                    value: adProvider.value,
                  };
                }),
              ]}
              tabIndex={56}
              onChange={onChange}
              required
            />
          </Grid>

          <Grid
            xs={12}
            item
          >
            <Field
              type="dropdown"
              label="Advertiser"
              name="advertiser"
              value={advertiserName}
              tabIndex={57}
              dropdownProps={{
                type: 'advertiser',
                attr: 'name',
                fetch: API.Advertiser.list,
                create: API.Advertiser.create,
                canCreate: user.can('advertiser.create'),
                successMessage: 'ADVERTISER.CREATED',
              }}
              onChange={onChangeAdvertiser}
              required
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="datepicker"
              label="Start Date"
              placeholder="YYYY-MM-DD"
              value={flight.startDate}
              dateProps={{
                format: 'YYYY-MM-DD',
                // flight must start on/before the segments start date
                maxDate: moment(segmentStartDate).toDate(),
                tooltipDate: flight.startDate
                  ? moment(flight.startDate)
                    .startOf('day')
                    .format('MMMM DD, YYYY [at] hh:mm:ss A')
                  : '',
              }}
              tabIndex={58}
              onChange={onChangeStartDate}
              required
            />
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="datepicker"
              label="End Date"
              placeholder="YYYY-MM-DD"
              value={flight.endDate}
              dateProps={{
                format: 'YYYY-MM-DD',
                // flight must end on/after the segments end date
                minDate: moment(segmentEndDate).toDate(),
                tooltipDate: flight.endDate
                  ? moment(flight.endDate)
                    .subtract(1, 'second')
                    .format('MMMM DD, YYYY [at] hh:mm:ss A')
                  : '',
              }}
              tabIndex={59}
              onChange={onChangeEndDate}
              required
            />
          </Grid>
        </Grid>
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButtonNew
          text="Save Flight"
          color={color.violetVibe}
          disabled={disableSave}
          onClick={onSave}
        />
      </SidePanelFooter>
    </SidePanelContainer>
  );
}

SegmentFlightNew.propTypes = {
  className: PropTypes.string,
  /** Segment Start Date */
  segmentStartDate: PropTypes.string.isRequired,
  /** Segment End Date */
  segmentEndDate: PropTypes.string.isRequired,
  onCloseFlights: PropTypes.func,
};

SegmentFlightNew.defaultProps = {
  className: '',
  onCloseFlights: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentFlightNew);
