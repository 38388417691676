// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chips .chips-input-container {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #E1E1E9;
  border-radius: 4px;
  overflow: auto;
}
.Chips .chips-input-container .added-chips {
  display: flex;
  align-items: center;
  padding-left: 8px;
}
.Chips .chips-input-container .chips-field {
  border: none;
  flex-grow: 1;
  min-width: 50px;
  padding-right: 30px;
}
.Chips .chips-input-container .chips-field.focus {
  border: none;
}
.Chips .chips-input-container .add-chip {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 0;
  width: 24px;
  height: 100%;
}
.Chips .chips-input-container .add-chip .add-chip-icon {
  cursor: pointer;
}
.Chips .chips-input-container.focus {
  border: 1px solid #6d6db5;
}
.Chips .chips-list {
  display: flex;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/InputChips/Chips.scss"],"names":[],"mappings":"AAIE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;AA2EJ;AAzEI;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AA2EN;AAvEI;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;AAyEN;AAvEM;EACE,YAAA;AAyER;AArEI;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,MAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AAuEN;AArEM;EACE,eAAA;AAuER;AAnEI;EACE,yBAAA;AAqEN;AAjEE;EACE,aAAA;EACA,eAAA;AAmEJ","sourcesContent":["@import '../../sass/color.scss';\n\n.Chips {\n\n  .chips-input-container {\n    position: relative;\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n    border: 1px solid $whiteSmoke;\n    border-radius: 4px;\n    overflow: auto;\n\n    .added-chips {\n      display: flex;\n      align-items: center;\n      padding-left: 8px;\n      // overflow: auto;\n    }\n\n    .chips-field {\n      border: none;\n      flex-grow: 1;\n      min-width: 50px;\n      padding-right: 30px;\n\n      &.focus {\n        border: none;\n      }\n    }\n\n    .add-chip {\n      position: absolute;\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      top: 0;\n      right: 0;\n      width: 24px;\n      height: 100%;\n\n      .add-chip-icon {\n        cursor: pointer;\n      }\n    }\n\n    &.focus {\n      border: 1px solid $violetVibe;\n    }\n  }\n\n  .chips-list {\n    display: flex;\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aquaForest": `#00a474`,
	"aquaForest8": `rgba(0, 164, 116, 0.08)`,
	"aquaForest16": `rgba(0, 164, 116, 0.16)`,
	"aquaForest75": `rgba(0, 164, 116, 0.75)`,
	"black": `#000000`,
	"boxRed": `#cf0838`,
	"boxPink": `#f57a7a`,
	"boxOrange": `#ff892e`,
	"boxYellow": `#ffd22e`,
	"boxGreen": `#95c653`,
	"boxDarkGreen": `#00a474`,
	"boxBlue": `#33ace1`,
	"boxDarkBlue": `#3361e1`,
	"boxPurple": `#6d6db5`,
	"boxDarkPurple": `#332a4b`,
	"carrot": `#ff892e`,
	"carrot16": `rgba(255, 137, 46, 0.16)`,
	"cerulean": `#33ace1`,
	"cerulean16": `rgba(51, 172, 225, 0.16)`,
	"cosmicVibe": `#332a4b`,
	"cosmicVibe25": `rgba(51, 42, 75, 0.25)`,
	"cosmicVibe50": `rgba(51, 42, 75, 0.5)`,
	"cosmicVibe75": `rgba(51, 42, 75, 0.75)`,
	"crimson": `#c90a31`,
	"darkGray": `#a4a7b7`,
	"darkSlateGray": `#2f2947`,
	"dimGray": `#545454`,
	"fireBrick": `#cf0838`,
	"fireBrick8": `rgba(207, 8, 56, 0.08)`,
	"fireBrick10": `rgba(207, 8, 56, 0.1)`,
	"fireBrick16": `rgba(207, 8, 56, 0.16)`,
	"fireBrick20": `rgba(207, 8, 56, 0.2)`,
	"fireBrick50": `rgba(207, 8, 56, 0.5)`,
	"fireBrick75": `rgba(207, 8, 56, 0.75)`,
	"fireBrick85": `rgba(207, 8, 56, 0.85)`,
	"flamingo": `#f66733`,
	"flamingo16": `rgba(246, 103, 51, 0.16)`,
	"flamingo75": `rgba(246, 103, 51, 0.75)`,
	"grainsboro": `#d5d8e1`,
	"graphBlue": `#1570EF`,
	"gray": `#757575`,
	"lavendarVibe": `#eae9fc`,
	"lightBlue": `#ccdfe8`,
	"lightDimGray": `#6b6b6b`,
	"lighterGray": `#e3e7f4`,
	"lightGray": `#f6f6f6`,
	"lightSlateGray": `#8a8fa3`,
	"lightSmoke": `#f5f4f5`,
	"manatee": `#959aad`,
	"manatee16": `rgba(149, 154, 173, 0.16)`,
	"manatee50": `rgba(149, 154, 173, 0.5)`,
	"mediumGray": `#e0e0e0`,
	"mediumOrchid": `#9932cc`,
	"lightOrchid": `#7F56D9`,
	"obsidian": `#383838`,
	"periwinkle": `#e7e7ff`,
	"rainboots": `#3361e1`,
	"rainboots16": `rgba(51, 97, 225, 0.16)`,
	"royalBlue": `#4085de`,
	"royalBlue16": `rgba(64, 133, 222, 0.16)`,
	"sushi": `#95c653`,
	"teal": `#029a69`,
	"vibeLightPurple": `#e2e1fe`,
	"vibeMediumPurple": `#e7e7ff`,
	"violetVibe": `#6d6db5`,
	"violetVibe16": `rgba(109, 109, 181, 0.16)`,
	"violetVibe40": `rgba(109, 109, 181, 0.4)`,
	"violetVibe50": `rgba(109, 109, 181, 0.5)`,
	"violetVibe60": `rgba(109, 109, 181, 0.6)`,
	"white": `#ffffff`,
	"whiteSmoke": `#E1E1E9`,
	"yellow": `#ffc107`,
	"yellow16": `rgba(255, 193, 7, 0.16)`,
	"yellow75": `rgba(255, 193, 7, 0.75)`,
	"lemon": `#ffd22e`
};
export default ___CSS_LOADER_EXPORT___;
