import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClipboardJS from 'clipboard';
import {
  VibeTooltip,
  color,
} from 'vibeguide';

let tooltipTimeout;
const defaultTooltipText = 'Click to copy';

function ProfileExternalIdItem({
  className,
  style,
  source,
  extId,
}) {
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);
  const clipboardRef = useRef();

  /**
   * Clear the tooltip timeout if the component unmounts before it is run
   */
  useEffect(() => {
    if (clipboardRef) {
      const clipboard = new ClipboardJS(clipboardRef.current);

      clipboard.on('success', () => {
        setTooltipText('Copied!');

        tooltipTimeout = setTimeout(() => {
          setTooltipText(defaultTooltipText);
        }, 2000);
      });
    }

    return () => {
      clearTimeout(tooltipTimeout);
    };
  }, []);

  return (
    <div
      className={classNames('ProfileExternalIdItem', className)}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        ...style,
      }}
    >
      <div
        style={{
          color: color.manatee,
        }}
      >
        {source}
      </div>

      <VibeTooltip
        title={tooltipText}
        placement="top"
      >
        <div
          ref={clipboardRef}
          style={{
            cursor: 'pointer',
            color: color.violetVibe,
          }}
          data-clipboard-text={extId}
        >
          {extId}
        </div>
      </VibeTooltip>
    </div>
  );
}

ProfileExternalIdItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  source: PropTypes.string,
  extId: PropTypes.string,
};

ProfileExternalIdItem.defaultProps = {
  className: '',
  style: {},
  source: '',
  extId: '',
};

export default ProfileExternalIdItem;
