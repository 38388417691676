import React, { PureComponent, forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {
  get,
  uniqueId,
  isFinite,
  isNaN,
  isEmpty,
  isString,
  toInteger,
  toNumber,
} from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import {
  Select,
  MenuItem,
} from '@mui/material';
import CustomSelect from './Custom/CustomSelect';
import TimeField from './TimeField';
import ToggleField from './ToggleField';
import RadioField from './RadioField';
import DollarField from './DollarField';
import BaselineSelector from '../BaselineSelector/BaselineSelector';
import DmaSelector from '../DmaSelector/DmaSelector';
import CompanySelector from '../CompanySelector/CompanySelector';
import DropdownSelector from '../DropdownSelector/DropdownSelector';
import Chips from '../InputChips/Chips';
import UploadDropzone from '../UploadDropzone/UploadDropzone';
import Tags from '../Tags/Tags';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viExpandMore from '../../icons/viExpandMore';
import viLock from '../../icons/viLock';
import viUnlock from '../../icons/viUnlock';
import viCalendar from '../../icons/viCalendar';
import viCloseCircle from '../../icons/viCloseCircle';
import CountryField from './CountryField';
import color from '../../sass/color.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './Field.scss';
import PhoneField from './PhoneField';

const CustomDateInput = forwardRef((props, ref) => (
  <input
    type="text"
    ref={ref}
    name={props.name}
    className={props.className}
    tabIndex={props.tabIndex}
    placeholder={props.placeholder}
    value={props.value || ''}
    autoFocus={props.autoFocus}
    autoComplete={props.autoComplete}
    disabled={props.disabled}
    onChange={props.onChange}
    onBlur={props.onBlur}
    onFocus={props.onFocus}
    onKeyDown={props.onKeyDown}
  />
));

class Field2 extends PureComponent {
  constructor(props) {
    super(props);

    this.fieldId = uniqueId('vibeField');

    this.state = {
      hasError: false,
      decorateError: true, // used for pre init validation to not apply field styles
    };
  }

  componentDidMount() {
    const {
      value,
      validation: {
        events,
      },
    } = this.props;

    if (events.includes('pre-init') || events.includes('init')) {
      this.validateField(value, {
        preInit: events.includes('pre-init'),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      value,
      validation: {
        events,
      },
    } = this.props;

    const {
      value: prevValue,
    } = prevProps;

    if (value !== prevValue && events.includes('init')) {
      this.validateField(value);
    }
  }

  onFocus = (e) => {
    const {
      onFocus,
    } = this.props;

    onFocus(e);
  };

  onDrop = (files) => {
    const {
      onDrop,
    } = this.props;

    onDrop(files);
  };

  onChange = (e) => {
    let {
      target: {
        value,
      },
    } = e;

    const {
      type,
      validation: {
        events = [],
      },
      numberProps: {
        min,
        max,
      },
      onChange,
    } = this.props;

    const {
      hasError,
    } = this.state;

    // only trim strings
    value = typeof value === 'string'
      ? (value || '').trimLeft()
      : value;

    if (
      events.includes('onChange')
      || hasError
    ) {
      // Run field validation if configured for onChange, or if the field already has an error
      this.validateField(value);
    }

    if (type === 'number' && (isFinite(min) || isFinite(max))) {
      const num = toNumber(value);

      if (isFinite(min) && num < min) {
        // Number is lower than the minimum allowed
        console.warn('Minimum number allowed', min);
        return;
      }

      if (isFinite(max) && num > max) {
        // Number is lower than the minimum allowed
        console.warn('Maximum number allowed', max);
        return;
      }
    }

    onChange(e);
  };

  onChangeSelectMultiple = (e) => {
    const {
      onChange,
    } = this.props;

    const {
      target: {
        value,
      },
    } = e;

    // remove empty strings if selecting multiple
    const newValue = value.filter(selected => !isEmpty(selected));
    e.target.value = newValue.join(',');
    onChange(e);
  };

  onChangeDate = (value) => {
    const {
      dateProps,
      onChange,
    } = this.props;

    if (value && !isNaN(value)) {
      const newDate = new moment(value);
      const formatDateStr = dateProps.format || 'MM/DD/YYYY';

      if (dateProps.startOfDay) {
        newDate.startOf('day');
      } else if (dateProps.endOfDay) {
        newDate.endOf('day');
      }

      onChange(newDate.format(formatDateStr));
    } else {
      onChange('');
    }
  };

  onChangeToggle = (toggled) => {
    const {
      onChange,
    } = this.props;

    onChange(toggled);
  };

  /**
   * When the lock button is toggled
   */
  onToggleLock = () => {
    const {
      name,
      textProps: {
        onToggleLock,
      },
    } = this.props;

    onToggleLock(name);
  };

  onBlur = (e) => {
    const {
      validation: {
        events = [],
      },
      onBlur,
    } = this.props;

    if (events.includes('onBlur')) {
      const value = get(e, 'target.value');
      this.validateField(value);
    }

    onBlur(e);
  };

  /**
   * When the wrapper form is submitted
   */
  onSubmit = (e) => {
    e.preventDefault();
  };

  /**
   * Get the date value for a datepicker
   */
  getDateValue = (value) => {
    const {
      dateProps: {
        format = 'YYYY-MM-DD',
      },
    } = this.props;

    if (!value) {
      return '';
    }

    switch (format) {
      case 'YYYY-MM-DD': {
        // split the date into parts
        const splt = value.split('-');
        const year = toInteger(splt[0]);
        const month = toInteger(splt[1]);
        const day = toInteger(splt[2]);

        const yearStr = `${year}`;
        const monthStr = month < 10
          ? `0${month}`
          : `${month}`;
        const dayStr = day < 10
          ? `0${day}`
          : `${day}`;

        if (day > 0 && day <= 31
          && month > 0 && month <= 12
          && year > 2000 && year < 3000
        ) {
          // year/month/day all provided
          return new moment(`${yearStr}-${monthStr}-${dayStr}`).toDate();
        }

        if (month > 0 && month <= 12
          && year > 2000 && year < 3000
        ) {
          // year/month provided
          return new moment(`${yearStr}-${monthStr}`).toDate();
        }

        if (year > 2000 && year < 3000) {
          // year provided
          return new moment(yearStr).toDate();
        }

        // invalid string provided, do not update the datepicker
        return new Date();
      }

      default:
        return new moment(value).toDate();
    }
  };

  /**
   * Validate the field
   */
  validateField = (value, options = {}) => {
    const {
      name,
      dataId,
      validation: {
        check,
        checkEmpty,
        regex,
      },
      onValidate,
    } = this.props;

    if (!check) {
      // Do not validate when this config option is set
      return;
    }

    let valid = false;

    if (regex) {
      // test regular expression
      valid = regex.test(value);
    } else {
      // test for empty value
      valid = (!isEmpty(value))
        || (isEmpty(value) && !checkEmpty);
    }

    this.setState((state) => {
      const {
        hasError,
      } = state;

      if (
        (valid && hasError)
        || (!value && !checkEmpty)
      ) {
        // Valid field or allowed empty field value, remove error status
        onValidate({
          name,
          dataId,
          valid,
        });

        return {
          hasError: false,
          decorateError: true,
        };
      }

      if (!valid && !hasError) {
        // Invalid field, add error status
        onValidate({
          name,
          dataId,
          valid,
        });

        return {
          hasError: true,
          decorateError: !options.preInit,
        };
      }

      return state;
    });
  };

  render() {
    const {
      type,
      rootClassName,
      rootStyle,
      className,
      style,
      label,
      hideLabel,
      placeholder,
      name,
      value,
      error,
      tabIndex,
      autoFocus,
      autoComplete,
      disabled,
      dataId,
      marginTop,
      marginBottom,
      options,
      required,
      multipleValues,
      validation: {
        errorText,
      },
      formProps,
      labelProps,
      textProps,
      numberProps,
      selectProps,
      baselineProps,
      dmaProps,
      chipProps,
      companyProps,
      dropdownProps,
      dateProps,
      timeProps,
      tagProps,
      dropzoneProps,
      toggleProps,
      radioProps,
      phoneProps,
      dropdownPosition,
      children,
      onClick,
      onChange,
      onKeyDown,
    } = this.props;

    const {
      hasError,
      decorateError,
    } = this.state;

    const isInput = type !== 'company'
      && type !== 'baseline'
      && type !== 'dma'
      && type !== 'datepicker'
      && type !== 'textarea'
      && type !== 'select'
      && type !== 'select-multiple'
      && type !== 'dropdown'
      && type !== 'radio'
      && type !== 'time'
      && type !== 'toggle'
      && type !== 'dropzone'
      && type !== 'tags'
      && type !== 'dollar'
      && type !== 'label'
      && type !== 'inputChips'
      && type !== 'custom'
      && type !== 'country'
      && type !== 'phone';

    const dateValue = type === 'datepicker'
      ? this.getDateValue(value || '')
      : '';

    // check for multiple values in a field (split by a comma) if allowed
    const values = multipleValues && isString(value)
      ? value.split(',').filter(v => !isEmpty(v))
      : [];
    const hasMultipleValues = values.length > 1;

    return (
      <form
        style={formProps.style}
        autoComplete={autoComplete}
        onSubmit={this.onSubmit}
      >
        <div
          className={classNames('Field', rootClassName, { 'has-error': (hasError || error) && decorateError })}
          style={{
            marginTop,
            marginBottom,
            ...rootStyle,
          }}
        >
          {label !== null
            && type !== 'tags'
            && type !== 'radio'
            && type !== 'hidden'
            && (
              <VibeTooltip
                title={labelProps.tooltip}
                placement={labelProps.tooltipPlacement}
              >
                <label
                  className={classNames('field-label', { required })}
                  htmlFor={this.fieldId}
                >
                  {label}
                </label>
              </VibeTooltip>
            )}

          {isInput && (
            <VibeTooltip
              title={hasMultipleValues ? (
                <div>
                  {values.map((v) => (
                    <div
                      key={`selected-item-${v}`}
                      style={{
                        padding: '4px 0',
                      }}
                    >
                      {v}
                    </div>
                  ))}
                </div>
              ) : ''}
              placement="bottom"
              arrow
            >
              <div className="input-container">
                {type === 'number' ? (
                  <input
                    id={this.fieldId}
                    name={name}
                    className={classNames('field-input', className, { disabled, 'has-button': textProps.lockButton })}
                    style={style}
                    type={type}
                    min={numberProps.min}
                    max={numberProps.max}
                    step={numberProps.step}
                    tabIndex={tabIndex}
                    placeholder={!hasMultipleValues
                      ? placeholder
                      : ''}
                    value={!hasMultipleValues
                      ? value
                      : ''}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    disabled={disabled || textProps.locked || hasMultipleValues}
                    data-id={dataId}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onKeyDown={onKeyDown}
                    onFocus={this.onFocus}
                  />
                ) : (
                  <input
                    id={this.fieldId}
                    name={name}
                    className={classNames('field-input', className, { disabled, 'has-button': textProps.lockButton })}
                    style={style}
                    type={type}
                    tabIndex={tabIndex}
                    placeholder={!hasMultipleValues
                      ? placeholder
                      : ''}
                    value={!hasMultipleValues
                      ? value
                      : ''}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    disabled={disabled || textProps.locked || hasMultipleValues}
                    data-id={dataId}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onKeyDown={onKeyDown}
                    onFocus={this.onFocus}
                  />
                )}

                {textProps.lockButton ? (
                  <VibeIcon
                    className="lock-button"
                    type="button"
                    icon={textProps.locked
                      ? viLock
                      : viUnlock}
                    color={textProps.locked
                      ? color.fireBrick
                      : color.manatee}
                    size={16}
                    buttonProps={{
                      size: 32,
                      borderColor: textProps.locked
                        ? color.fireBrick
                        : color.whiteSmoke,
                    }}
                    disabled={disabled}
                    onClick={this.onToggleLock}
                  />
                ) : null}

                {hasMultipleValues && (
                  <div className="multiple-values-container">
                    <div className="multiple-values">
                      <VibeIcon
                        icon={viCloseCircle}
                        color={color.violetVibe}
                        hoverColor={color.cosmicVibe}
                        size={16}
                        style={{
                          marginRight: 4,
                        }}
                        onClick={() => onChange({ target: { value: '' } })}
                      />

                      <div className="text">
                        {values.length} Search Values
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </VibeTooltip>
          )}

          {type === 'textarea' ? (
            <textarea
              id={this.fieldId}
              name={name}
              className={classNames('field-textarea', className, { disabled })}
              style={style}
              tabIndex={tabIndex}
              placeholder={placeholder}
              value={value}
              autoFocus={autoFocus}
              disabled={disabled}
              data-id={dataId}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onKeyDown={onKeyDown}
              onFocus={this.onFocus}
            />
          ) : null}

          {type === 'select' ? (
            <VibeTooltip
              title={hasMultipleValues ? (
                <div>
                  {values.map((v) => (
                    <div
                      key={`selected-item-${v}`}
                      style={{
                        padding: '4px 0',
                      }}
                    >
                      {v}
                    </div>
                  ))}
                </div>
              ) : ''}
              placement="bottom"
              arrow
            >
              <div className="select-container">
                <select
                  id={this.fieldId}
                  name={name}
                  className={classNames('field-input', className, { disabled })}
                  style={style}
                  tabIndex={tabIndex}
                  placeholder={!hasMultipleValues
                    ? placeholder
                    : ''}
                  value={!hasMultipleValues
                    ? value
                    : ''}
                  autoFocus={autoFocus}
                  disabled={disabled || hasMultipleValues}
                  data-id={dataId}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                >
                  {options.map((option) => {
                    return (
                      <option
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                      >
                        {option.label}
                      </option>
                    );
                  })}
                </select>

                {/* Show a placeholder if the first option has one set */}
                {!value && get(options, '[0].placeholder') ? (
                  <input
                    className="field-input select-placeholder"
                    type="text"
                    placeholder={get(options, '[0].placeholder')}
                  />
                ) : null}

                {!get(selectProps, 'hideArrow', false) && (
                  <VibeIcon
                    className="arrow-down"
                    style={get(selectProps, 'arrowStyle', {})}
                    icon={viExpandMore}
                    color={color.manatee}
                    size={16}
                  />
                )}

                {hasMultipleValues && (
                  <div className="multiple-values-container">
                    <div className="multiple-values">
                      <VibeIcon
                        icon={viCloseCircle}
                        color={color.violetVibe}
                        hoverColor={color.cosmicVibe}
                        size={16}
                        style={{
                          marginRight: 4,
                        }}
                        onClick={() => onChange({ target: { value: '' } })}
                      />

                      <div className="text">
                        {values.length} Search Values
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </VibeTooltip>
          ) : null}

          {type === 'select-multiple' ? (
            <div className="select-container">
              <Select
                classes={{
                  select: 'field-input mui-select',
                }}
                id={this.fieldId}
                multiple
                value={value.split(',')}
                tabIndex={tabIndex}
                onChange={this.onChangeSelectMultiple}
                input={<CustomSelect />}
                MenuProps={{
                  PopoverClasses: {
                    root: 'SelectPopoverRoot',
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              {/* Show a placeholder */}
              {value.length <= 0 ? (
                <div>
                  <input
                    className="field-input select-placeholder"
                    type="text"
                    placeholder="Search..."
                  />

                  <VibeIcon
                    className="arrow-down"
                    icon={viExpandMore}
                    color={color.manatee}
                    size={16}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {type === 'radio' ? (
            <RadioField
              className={className}
              title={radioProps.title}
              label={label}
              selected={radioProps.selected}
              disabled={disabled}
              icon={radioProps.icon}
              onClick={onClick}
            />
          ) : null}

          {type === 'time' ? (
            <TimeField
              placeholder={placeholder}
              time={value}
              min={timeProps.min}
              max={timeProps.max}
              endOfDay={timeProps.endOfDay}
              inputStyle={timeProps.inputStyle}
              tabIndex={tabIndex}
              disabled={disabled}
              onChange={onChange}
            />
          ) : null}

          {type === 'toggle' ? (
            <ToggleField
              fieldId={this.fieldId}
              className={className}
              label={toggleProps.label}
              helpText={toggleProps.helpText}
              helpTextStyle={toggleProps.helpTextStyle}
              toggled={value === 'on'}
              disabled={disabled}
              onChange={this.onChangeToggle}
            />
          ) : null}

          {type === 'dropzone' ? (
            <UploadDropzone
              fieldId={this.fieldId}
              style={dropzoneProps.style}
              dragOverStyle={dropzoneProps.dragOverStyle}
              placeholder={dropzoneProps.placeholder}
              type={dropzoneProps.type}
              image={dropzoneProps.image}
              acceptedMimeTypes={dropzoneProps.mimeTypes}
              allowUpload={dropzoneProps.allowUpload}
              disabled={disabled}
              onDrop={this.onDrop}
            />
          ) : null}

          {type === 'company' ? (
            <CompanySelector
              name={name}
              value={value}
              id={companyProps.companyId}
              style={companyProps.inputStyle}
              disabled={disabled}
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              multipleValues={multipleValues}
              onClick={onClick}
              onChange={onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          ) : null}

          {type === 'dropdown' && (
            <DropdownSelector
              name={name}
              value={value}
              type={dropdownProps.type}
              attr={dropdownProps.attr}
              style={dropdownProps.style}
              inputStyle={dropdownProps.inputStyle}
              companyId={dropdownProps.companyId}
              canCreate={dropdownProps.canCreate}
              fetch={dropdownProps.fetch}
              create={dropdownProps.create}
              successMessage={dropdownProps.successMessage}
              disabled={disabled}
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              multipleValues={multipleValues}
              onClick={onClick}
              onChange={onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          )}

          {type === 'baseline' ? (
            <BaselineSelector
              fieldId={this.fieldId}
              baselineName={baselineProps.baselineName}
              baselineId={baselineProps.baselineId}
              locationId={baselineProps.locationId}
              dropdownPosition={dropdownPosition}
              disabled={disabled}
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              onClick={onClick}
              onChange={onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          ) : null}

          {type === 'dma' ? (
            <DmaSelector
              name={name}
              value={value}
              id={dmaProps.dmaId}
              style={dmaProps.inputStyle}
              disabled={disabled}
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              multipleValues={multipleValues}
              onClick={onClick}
              onChange={onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          ) : null}

          {type === 'datepicker' ? (
            <div className="datepicker-container">
              <VibeIcon
                className="datepicker-icon"
                icon={viCalendar}
                color={color.manatee}
                size={16}
              />

              <VibeTooltip
                placement="top"
                title={dateProps.tooltipDate ? (
                  <div>
                    <div
                      style={{
                        marginBottom: 4,
                      }}
                    >
                      Actual Date &amp; Time
                    </div>

                    {dateProps.tooltipDate}
                  </div>
                ) : dateProps.tooltip || ''}
              >
                <div>
                  <DatePicker
                    // root element for the datepicker to render
                    // required for the popper to be on top of the dom
                    // if changed also change in "VibenomicsApp.js"
                    portalId="datepicker-root-portal"
                    className={classNames('field-input', 'datepicker-input', className, { disabled })}
                    style={style}
                    tabIndex={tabIndex}
                    showPopperArrow
                    selected={dateValue || ''}
                    startDate={dateProps.startDate}
                    minDate={dateProps.minDate}
                    maxDate={dateProps.maxDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={this.onChangeDate}
                    popperPlacement={dateProps.placement}
                    placeholderText={dateProps.format || placeholder}
                    customInput={(
                      <CustomDateInput />
                    )}
                  />
                </div>
              </VibeTooltip>
            </div>
          ) : null}

          {type === 'inputChips' ? (
            <Chips
              id={this.fieldId}
              className="field-input"
              chips={chipProps.chips}
              assign={chipProps.assign}
              style={chipProps.chipStyle}
              label={label}
              hideLabel={hideLabel}
              name={name}
              showChipsList={chipProps.showChipsList}
              showChipsInline={chipProps.showChipsInline}
              tabIndex={tabIndex}
              placeholder={placeholder}
              value={value}
              autoFocus={autoFocus}
              disabled={disabled}
              required={required}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onKeyDown={onKeyDown}
              onFocus={this.onFocus}
              onSet={chipProps.onSet}
            />
          ) : null}

          {type === 'tags' && (
            <Tags
              id={this.fieldId}
              dataId={dataId}
              name={name}
              tags={tagProps.tags}
              assign={tagProps.assign}
              allowTypes={tagProps.allowTypes}
              showAs={tagProps.showAs}
              label={label}
              hideLabel={hideLabel}
              dropdownStyle={tagProps.style}
              companyId={tagProps.companyId}
              disabled={disabled}
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              multipleValues={multipleValues}
              onClick={onClick}
              onChange={onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          )}

          {type === 'dollar' && (
            <DollarField
              name={name}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              tabIndex={tabIndex}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              onChange={onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          )}

          {type === 'country' && (
            <CountryField
              name={name}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              tabIndex={tabIndex}
              onChange={this.onChange}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
            />
          )}

          {type === 'custom' ? (
            <div>
              {children}
            </div>
          ) : null}

          {type === 'phone' && (
            <PhoneField
              name={name}
              placeholder={placeholder}
              value={value}
              defaultCountry={phoneProps.country}
              onChange={this.onChange}
            />
          )}

          {(hasError && decorateError && errorText) && (
            <div className="field-errors">
              <div className="field-error">
                {errorText}
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

Field2.propTypes = {
  /** What type of input field */
  type: PropTypes.oneOf([
    'text',
    'password',
    'number',
    'checkbox',
    'radio',
    'email',
    'tel',
    'hidden',
    'textarea',
    'select',
    'select-multiple',
    'dropdown',
    'toggle',
    'time',
    'dropzone',
    'company',
    'baseline',
    'dma',
    'datepicker',
    'inputChips',
    'tags',
    'dollar',
    'label',
    'custom',
    'country',
    'phone',
  ]).isRequired,
  /** Root component class */
  rootClassName: PropTypes.string,
  /** Root component custom style */
  rootStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Field class */
  className: PropTypes.string,
  /** Field custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Field label */
  label: PropTypes.string,
  /** Force hide the label */
  hideLabel: PropTypes.bool,
  /** Field placeholder */
  placeholder: PropTypes.string,
  /** Field name */
  name: PropTypes.string,
  /** Field value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** Field has an error */
  error: PropTypes.bool,
  /** Input tab index position */
  tabIndex: PropTypes.number,
  /** Allow AutoComplete */
  autoComplete: PropTypes.oneOf([
    'on',
    'off',
  ]),
  /** Auto focus the field */
  autoFocus: PropTypes.bool,
  /** Field is disabled */
  disabled: PropTypes.bool,
  /** Field ID for data attribute on input */
  dataId: PropTypes.string,
  /** Margin at the top of the field */
  marginTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Margin at the bottom of the field */
  marginBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Options for select field */
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
  })),
  /** Is the field required */
  required: PropTypes.bool,
  /** Allow multiple values for the field (used in table search columns) */
  multipleValues: PropTypes.bool,
  /** Validation properties */
  validation: PropTypes.shape({
    check: PropTypes.bool,
    checkEmpty: PropTypes.bool,
    regex: PropTypes.instanceOf(RegExp),
    errorText: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.oneOf([
      'pre-init', // validates without error styles
      'init',
      'onBlur',
      'onChange',
    ])),
  }),
  /** Properties for the form field */
  formProps: PropTypes.shape({
    style: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Properties for the form label */
  labelProps: PropTypes.shape({
    tooltip: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    tooltipPlacement: PropTypes.string,
  }),
  /** Text properties for text field types */
  textProps: PropTypes.shape({
    lockButton: PropTypes.bool,
    locked: PropTypes.bool,
    onToggleLock: PropTypes.func,
  }),
  /** Select properties for select field types */
  selectProps: PropTypes.shape({
    hideArrow: PropTypes.bool,
    arrowStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Number properties for number field */
  numberProps: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
  }),
  /** Company properties for company selector */
  companyProps: PropTypes.shape({
    companyName: PropTypes.string,
    companyId: PropTypes.string,
    inputStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Dropdown properties for dropdown selector */
  dropdownProps: PropTypes.shape({
    type: PropTypes.oneOf([
      'category',
      'banner',
      'screen resolution',
      'layout template',
      'loop template',
      'delivery system',
      'network owner',
      'lookup',
      'tag',
      'advertiser',
    ]).isRequired,
    // attribute to use when showing data in the list
    attr: PropTypes.string.isRequired,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    /** Company ID for Company Banners */
    companyId: PropTypes.string,
    canCreate: PropTypes.bool,
    successMessage: PropTypes.string,
    fetch: PropTypes.func,
    create: PropTypes.func,
  }),
  /** Baseline properties for baseline selector */
  baselineProps: PropTypes.shape({
    baselineName: PropTypes.string,
    baselineId: PropTypes.string,
    locationId: PropTypes.string,
  }),
  /** DMA properties for DMA selector */
  dmaProps: PropTypes.shape({
    dmaName: PropTypes.string,
    dmaId: PropTypes.string,
    inputStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Chips properties for Input field with chips */
  chipProps: PropTypes.shape({
    chips: PropTypes.arrayOf(PropTypes.string),
    chipStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    assign: PropTypes.bool,
    showChipsList: PropTypes.bool,
    showChipsInline: PropTypes.bool,
    onSet: PropTypes.func,
  }),
  /** Time properties for time field */
  timeProps: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
    // use 24 instead of 12 for 12:00am times
    endOfDay: PropTypes.bool,
    inputStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Tag properties for tags field */
  tagProps: PropTypes.shape({
    style: PropTypes.object,
    assign: PropTypes.bool,
    /** Company ID for tag lookup */
    companyId: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      active: PropTypes.bool,
      name: PropTypes.string,
      type: PropTypes.string,
    })),
    /** Allow client/admin tags in this component */
    allowTypes: PropTypes.arrayOf(PropTypes.oneOf([
      'client',
      'admin',
    ])),
    /** Show the tags as a list under the field, inline with the field, or not at all */
    showAs: PropTypes.oneOf([
      'list',
      // 'inline',
      'none',
    ]),
  }),
  /** Date properties for tags field */
  dateProps: PropTypes.shape({
    placement: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipDate: PropTypes.string,
    startOfDay: PropTypes.bool,
    endOfDay: PropTypes.bool,
    format: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }),
  /** Dropzone properties for upload field */
  dropzoneProps: PropTypes.shape({
    type: PropTypes.oneOf([
      'image',
      'document',
      'audio',
    ]),
    /** Placeholder element */
    placeholder: PropTypes.element,
    /** Custom style for the dropzone */
    style: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    /** Custom style for the dropzone drag over container */
    dragOverStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
    image: PropTypes.string,
    mimeTypes: PropTypes.object,
    /** Allow a file to be dropped */
    allowUpload: PropTypes.bool,
  }),
  /** Toggle field properties */
  toggleProps: PropTypes.shape({
    label: PropTypes.string,
    helpText: PropTypes.string,
    helpTextStyle: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  /** Radio field properties */
  radioProps: PropTypes.shape({
    selected: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.func,
  }),
  phoneProps: PropTypes.shape({
    country: PropTypes.string,
  }),
  /** Dropdown Position for the AutoComplete Selections */
  dropdownPosition: PropTypes.oneOf([
    'bottom',
    'top',
  ]),
  /** Child elements for custom field */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** When the field is clicked */
  onClick: PropTypes.func,
  /** When the field changes */
  onChange: PropTypes.func,
  /** When a key is pressed */
  onKeyDown: PropTypes.func,
  /** When the field recieves focus */
  onFocus: PropTypes.func,
  /** When the field loses focus */
  onBlur: PropTypes.func,
  /** When the field recieved a drop event */
  onDrop: PropTypes.func,
  /** When the field goes through a validation check */
  onValidate: PropTypes.func,
};

Field2.defaultProps = {
  rootClassName: '',
  rootStyle: {},
  className: '',
  style: {},
  label: null,
  hideLabel: false,
  placeholder: '',
  name: '',
  value: '',
  error: false,
  tabIndex: -1,
  autoFocus: false,
  autoComplete: 'off',
  disabled: false,
  dataId: null,
  marginTop: 0,
  marginBottom: 0,
  options: [],
  required: false,
  multipleValues: false,
  validation: {
    check: false,
    checkEmpty: true,
    events: [],
  },
  formProps: {
    style: {},
  },
  labelProps: {
    tooltip: '',
    tooltipPlacement: 'top',
  },
  textProps: {
    lockButton: false,
    locked: false,
    onToggleLock: () => {},
  },
  selectProps: {
    hideArrow: false,
    arrowStyle: {},
  },
  numberProps: {
    min: null,
    max: null,
    step: 1,
  },
  companyProps: {
    companyName: '',
    companyId: '',
    inputStyle: {},
    setCompany: () => {},
  },
  dropdownProps: {
    type: 'category',
    attr: '',
    style: {},
    inputStyle: {},
    companyId: '',
    canCreate: false,
    successMessage: '',
    fetch: () => {},
    create: () => {},
  },
  baselineProps: {
    baselineName: '',
    baselineId: '',
    locationId: '',
  },
  dmaProps: {
    dmaName: '',
    dmaId: '',
    inputStyle: {},
  },
  chipProps: {
    chips: [],
    chipStyle: {},
    assign: true,
    showChipsList: true,
    showChipsInline: false,
    onSet: () => {},
  },
  timeProps: {
    min: '',
    max: '',
    endOfDay: false,
    inputStyle: {},
  },
  tagProps: {
    style: {},
    assign: false,
    companyId: null,
    tags: [],
    allowTypes: ['client', 'admin'],
    showAs: 'none',
  },
  dateProps: {
    placement: 'bottom',
    tooltip: '',
    tooltipDate: '',
    startOfDay: false,
    endOfDay: false,
    format: 'MM/DD/YYYY',
    minDate: new Date(),
    maxDate: new Date(),
  },
  dropzoneProps: {
    type: 'audio',
    placeholder: null,
    style: {},
    dragOverStyle: {},
    image: null,
    mimeTypes: {},
    allowUpload: false,
  },
  toggleProps: {
    label: '',
    helpText: null,
    helpTextStyle: {},
  },
  radioProps: {
    selected: false,
    title: '',
    icon: null,
  },
  phoneProps: {
    country: 'US',
  },
  dropdownPosition: 'bottom',
  children: null,
  onClick: () => {},
  onChange: () => {},
  onKeyDown: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onDrop: () => {},
  onValidate: () => {},
};

export default Field2;
