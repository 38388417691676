import React from 'react';
import capitalize from 'lodash/capitalize';
import {
  useParams,
} from 'react-router-dom';
import {
  TableOrders,
  API,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';

function OrderList() {
  const {
    status,
    orderType,
  } = useParams();

  const breadcrumbs = [
    {
      title: 'Dashboard',
      linkUrl: '/ad-ops/orders/dashboard',
    },
  ];

  const updateTitle = (status) => {
    const statusTitle = status !== 'overview' && status !== 'all'
      ? status
      : null;

    if (statusTitle) {
      breadcrumbs[0] = {
        title: `${capitalize(statusTitle)} Dashboard`,
        linkUrl: `/ad-ops/orders/dashboard/${statusTitle.toLowerCase()}`,
      };
    }
  };

  const defineApiRoute = () => {
    let apiRoute;
    let title;
    let linkUrl;

    updateTitle(status);

    switch (true) {
      case orderType === 'awaitingcreative':
        if (status === 'overview') {
          title = 'Awaiting Creatives';
          linkUrl = '../awaitingcreative';
          apiRoute = API.Order.Reporting.Lists.Overview.missingCreative;
        }
        break;
      // UNDERPACING ORDERS
      case orderType === 'underpacing':
        if (status === 'overview') {
          title = 'Under Pacing';
          linkUrl = '../underpacing';
          apiRoute = API.Order.Reporting.Lists.Overview.underPacing;
        }
        break;
      // ENDING ORDERS
      case orderType === 'ending':
        if (status === 'overview') {
          title = 'Ends in 30 Days or Less';
          linkUrl = '../ending';
          apiRoute = API.Order.Reporting.Lists.Overview.ending;
        } else if (status === 'live') {
          title = 'Ends in 30 Days or Less';
          linkUrl = '../ending';
          apiRoute = API.Order.Reporting.Lists.Live.ending;
        } else if (status === 'paused') {
          title = 'Ends in 30 Days or Less';
          linkUrl = '../ending';
          apiRoute = API.Order.Reporting.Lists.Paused.ending;
        }
        break;
      // SERVING ORDERS
      case orderType === 'serving':
        if (status === 'live') {
          title = 'Serving in the next 7 Days';
          linkUrl = '../serving';
          apiRoute = API.Order.Reporting.Lists.Live.serving;
        }
        break;
      // UPCOMING ORDERS
      case orderType === 'upcoming':
        if (status === 'overview') {
          title = 'Upcoming in the Next 30 Days';
          linkUrl = '../upcoming';
          apiRoute = API.Order.Reporting.Lists.Pending.upcoming;
        } else if (status === 'pending') {
          title = 'Upcoming in the Next 30 Days';
          linkUrl = '../upcoming';
          apiRoute = API.Order.Reporting.Lists.Pending.upcoming;
        }
        break;
      // CURRENT ORDERS
      case orderType === 'current':
        if (status === 'paused') {
          title = 'Running in the Next 7 Days';
          linkUrl = '../current';
          apiRoute = API.Order.Reporting.Lists.Paused.current;
        }
        break;
      // PAST ORDERS
      case orderType === 'past':
        if (status === 'completed') {
          title = 'Completed in the Last 30 Days';
          linkUrl = '../past';
          apiRoute = API.Order.Reporting.Lists.Completed.past;
        } else if (status === 'cancelled') {
          title = 'Cancelled in the Last 30 Days';
          linkUrl = '../past';
          apiRoute = API.Order.Reporting.Lists.Cancelled.past;
        }
        break;
      // ALL ORDERS
      default:
        title = 'All Orders';
        linkUrl = '../all';
        apiRoute = API.Order.list;
        break;
    }

    breadcrumbs.push({ title, linkUrl });

    return apiRoute;
  };

  const fetchMethod = defineApiRoute();

  return (
    <div
      className="OrderList"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        breadcrumbs={breadcrumbs}
      />

      <TableOrders
        columns={[
          'Name',
          'Status',
          'Opportunity Name',
          'Opportunity ID',
          'Deal ID',
          'Order Reference ID',
          'Advertiser',
          'Ad Provider',
          'Delivery System',
          'Media Format',
          'Start Date',
          'End Date',
          'Modified Date',
          'Created Date',
          'Created By',
          '...',
        ]}
        fetch={fetchMethod}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Orders',
          urlPaging: true,
          urlFilters: true,
        }}
        rowLink="/ad-ops/order/{_id}/details"
        menuItems={[
          {
            name: 'Copy',
            userCan: 'flight.create',
          },
          {
            name: 'Pause',
            // Allow pause if the user has permission
            userCan: 'order.pause',
            disabled: (row) => {
              return row.status !== 'live';
            },
          },
          {
            name: 'Resume',
            // Allow resume if the user has permission (currently no order.resume permission)
            userCan: 'order.pause',
            disabled: (row) => {
              return row.status !== 'paused';
            },
          },
          {
            name: 'Cancel',
            userCan: 'order.cancel',
            disabled: (row) => {
              return row.status === 'cancelled' || row.status === 'completed';
            },
          },
        ]}
      />
    </div>
  );
}

export default withRouter(OrderList);
