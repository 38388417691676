import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  Link,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import {
  transparent,
} from '../../utils/ColorUtil';
import vibeColor from '../../sass/color.scss';
import './VibeButtonNew.scss';

// Loading timeout to prevent enabling too soon
let loadingTimeout;

/**
 * Get the button style based on the variant
 * hover state, and color
 */
function getButtonStyle({
  variant = 'contained',
  color,
  hover,
  disabled,
}) {
  switch (variant) {
    case 'text': {
      const style = {
        backgroundColor: 'transparent',
        fontWeight: 700,
        textTransform: 'uppercase',
      };

      if (hover && !disabled) {
        style.backgroundColor = transparent(color, 16);
      }

      return style;
    }

    case 'outlined': {
      const style = {
        backgroundColor: 'transparent',
        border: `1px solid ${color}`,
      };

      if (hover && !disabled) {
        style.backgroundColor = transparent(color, 16);
      }

      return style;
    }

    case 'contained':
    default: {
      const style = {
        backgroundColor: transparent(color, 80),
        border: `1px solid ${color}`,
      };

      if (hover && !disabled) {
        style.backgroundColor = transparent(color, 70);
      }

      return style;
    }
  }
}

function VibeButtonNew({
  className,
  style,
  text,
  variant,
  height,
  color,
  icon,
  iconProps,
  tooltip,
  tooltipProps,
  disabled: propDisabled,
  link,
  loadingEvent,
  onClick,
}) {
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const disabled = propDisabled || loading;

  const iconPlacement = get(iconProps, 'placement', 'left');
  const iconColor = get(iconProps, 'color', variant === 'contained'
    ? vibeColor.white
    : color);
  const iconSize = get(iconProps, 'size', 16);
  const iconStyle = get(iconProps, 'style', {});
  const buttonStyle = getButtonStyle({
    variant,
    color,
    hover,
    disabled,
  });

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  /**
   * Loading event has started
   */
  const startLoading = () => {
    setLoading(true);
  };

  /**
   * Loading event has stopped
   */
  const stopLoading = () => {
    loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  /**
   * Get the text color for the button
   */
  const getTextColor = () => {
    // when loading, hide the text but don't remove it so the button width doesn't change
    if (loading) {
      return 'transparent';
    }

    if (variant === 'contained') {
      return vibeColor.white;
    }

    return color;
  };

  const buttonHtml = (
    <VibeTooltip
      title={tooltip}
      placement={get(tooltipProps, 'placement', 'bottom')}
      arrow
    >
      <div
        className={classNames('VibeButtonNew', className, { disabled })}
        style={{
          height,
          ...buttonStyle,
          ...style,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={!disabled
          ? onClick
          : null}
      >
        {icon && iconPlacement === 'left' && (
          <VibeIcon
            className="icon-left"
            style={iconStyle}
            icon={icon}
            color={iconColor}
            size={iconSize}
          />
        )}

        <div
          className={classNames('text', { 'variant-text': variant === 'text' })}
          style={{
            color: getTextColor(),
          }}
        >
          {text}
        </div>

        {icon && iconPlacement === 'right' && (
          <VibeIcon
            className="icon-right"
            style={iconStyle}
            icon={icon}
            color={iconColor}
            size={iconSize}
          />
        )}

        {loading && (
          <CircularProgress
            color="inherit"
            size={height / 2}
            sx={{
              position: 'absolute',
              zIndex: 1,
            }}
          />
        )}
      </div>
    </VibeTooltip>
  );

  /**
   * Listen for loading events
   */
  useEffect(() => {
    if (!loadingEvent) {
      // no event to listen for
      return null;
    }

    document.addEventListener(`${loadingEvent}Start`, startLoading);
    document.addEventListener(`${loadingEvent}Error`, stopLoading);
    document.addEventListener(loadingEvent, stopLoading);

    return () => {
      clearTimeout(loadingTimeout);
      document.removeEventListener(`${loadingEvent}Start`, startLoading);
      document.removeEventListener(`${loadingEvent}Error`, stopLoading);
      document.removeEventListener(loadingEvent, stopLoading);
    };
  }, []);

  return link && !disabled ? (
    <Link to={link}>
      {buttonHtml}
    </Link>
  ) : buttonHtml;
}

VibeButtonNew.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  variant: PropTypes.oneOf([
    'text',
    'contained',
    'outlined',
  ]),
  /** Height of the button */
  height: PropTypes.number,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  iconProps: PropTypes.shape({
    placement: PropTypes.oneOf([
      'left',
      'right',
    ]),
    color: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  tooltipProps: PropTypes.shape({
    className: PropTypes.string,
    placement: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  link: PropTypes.string,
  /** Change the icon to a loading spinner when actions are performed */
  loadingEvent: PropTypes.string,
  onClick: PropTypes.func,
};

VibeButtonNew.defaultProps = {
  className: '',
  style: {},
  variant: 'contained',
  height: 32,
  color: vibeColor.obsidian,
  icon: null,
  iconProps: {},
  tooltip: '',
  tooltipProps: {
    className: '',
    placement: 'bottom',
  },
  disabled: false,
  link: null,
  loadingEvent: null,
  onClick: () => {},
};

export default VibeButtonNew;
