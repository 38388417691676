import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

function Contact({
  user,
  firstName,
  lastName,
  companyId,
  email,
  title,
  phone,
  tags,
  disableInput,
  onUpdate,
}) {
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  const onChangeTag = (data) => {
    onUpdate(data);
  };

  return (
    <div className="Information info-user">
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Contact
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid xs={6} item>
          <Field
            type="text"
            label="First Name"
            name="fname"
            placeholder="First Name"
            value={firstName}
            tabIndex={1}
            disabled={disableInput}
            onChange={onChange}
            required
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="text"
            label="Last Name"
            name="lname"
            placeholder="Last Name"
            value={lastName}
            tabIndex={2}
            disabled={disableInput}
            onChange={onChange}
            required
          />
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >

        <Grid xs={6} item>
          <Field
            type="text"
            label="Title"
            name="title"
            placeholder="Title"
            value={title}
            tabIndex={5}
            disabled={disableInput}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid xs={6} item>
          <Field
            type="text"
            label="Email"
            name="email"
            placeholder="user@example.com"
            value={email}
            tabIndex={4}
            disabled={disableInput}
            onChange={onChange}
            required
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="phone"
            label="Phone"
            name="phone"
            value={phone}
            tabIndex={6}
            disabled={disableInput}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid xs={12} item>
          <Field
            type="tags"
            label="Tags"
            name="tags"
            tagProps={{
              companyId,
              tags,
              allowTypes: ['client', 'admin'],
              showAs: 'list',
              assign: user.can('integration_user.assign_tag'),
            }}
            disabled={disableInput}
            onChange={onChangeTag}
            tabIndex={7}
          />
        </Grid>
      </Grid>
    </div>
  );
}

Contact.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  title: PropTypes.string,
  companyId: PropTypes.string,
  phone: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Contact.defaultProps = {
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  companyId: '',
  phone: '',
  tags: [],
  disableInput: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Contact);
