import moment from 'moment';
import {
  sortBy,
  isFinite,
} from 'lodash';

/**
 * Convert H:MM to minutes
 *
 * @param {string} time - time in H:MM format
 * @returns {integer}
 */
export function getMinutes(time = '0:00') {
  const hours = parseInt(time.split(':')[0], 10);
  const minutes = parseInt(time.split(':')[1], 10);

  const totalMinutes = (hours * 60) + minutes;

  return totalMinutes;
}

/**
 * Get the number of minutes between 2 H:MM times
 *
 * @param {string} startTime - time in H:MM format
 * @param {string} stopTime - time in H:MM format
 * @returns {integer}
 */
export function getMinutesBetween(startTime, stopTime) {
  if (!startTime || !stopTime) {
    // Start time or stop time not provided
    return 0;
  }

  const startHour = parseInt(startTime.split(':')[0], 10);
  const stopHour = parseInt(stopTime.split(':')[0], 10);
  const hourSpan = stopHour - startHour;

  const startMinute = parseInt(startTime.split(':')[1], 10);
  const stopMinute = parseInt(stopTime.split(':')[1], 10);
  const minuteSpan = stopMinute - startMinute;

  const minutes = (hourSpan * 60) + minuteSpan;

  return minutes > 0
    ? minutes
    : 0;
}

/**
 * Convert minutes to 'HH:MM'
 *
 * @param {integer} minutes
 * @returns {string}
 */
export function convertMinutes(minutes) {
  const seconds = Number(minutes * 60);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hDisplay = `${h < 10 ? `0${h}` : h}:`;
  const mDisplay = `${m < 10 ? `0${m}` : m}`;

  return hDisplay + mDisplay;
}

/**
 * Convert seconds to 'HH:MM:SS'
 *
 * @param {Integer} seconds
 */
export function convertSeconds(seconds) {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = `${h < 10 ? `0${h}` : h}:`;
  const mDisplay = `${m < 10 ? `0${m}` : m}:`;
  // eslint-disable-next-line no-nested-ternary
  const sDisplay = s > 0 ? (s < 10 ? `0${s}` : s) : '00';

  return h > 0
    ? hDisplay + mDisplay + sDisplay
    : mDisplay + sDisplay;
}

/**
 * Convert seconds to a format
 *
 * @param {Integer} seconds
 */
export function convertDuration(totalSeconds, type) {
  const duration = moment.duration(totalSeconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.get('minutes');
  const seconds = duration.get('seconds');

  const minuteDisplay = minutes < 10
    ? `0${minutes}`
    : minutes;

  const secondDisplay = seconds < 10
    ? `0${seconds}`
    : seconds;

  switch (type) {
    case '0h 0m': {
      return `${hours}h ${minuteDisplay}m`;
    }

    case 'm:ss':
    default: {
      const hourDisplay = hours < 10
        ? `0${hours}`
        : hours;

      return hours > 0
        ? `${hourDisplay}:${minuteDisplay}:${secondDisplay}`
        : `${minuteDisplay}:${secondDisplay}`;
    }
  }
}

/**
 * Convert to twelve hour time
 *
 * @param {string} time
 * @param {boolean} short 12PM or 12:15PM
 * @returns {string} 12 hour time
 */
export function convertToTwelveHour({
  time = '',
  short = false,
}) {
  if (!time) {
    return '';
  }

  let hour = parseInt(time.split(':')[0], 10);
  const minute = parseInt(time.split(':')[1], 10);
  const isPm = hour >= 12 && hour < 24;

  if (isPm && hour !== 12) {
    hour -= 12;
  } else if (!isPm && (hour === 0 || hour === 24)) {
    hour = 12;
  }

  if (short) {
    // don't show minute if the time falls on the hour
    // eslint-disable-next-line no-nested-ternary
    const showMinute = minute !== 0
      ? minute < 10
        ? `:0${minute}`
        : `:${minute}`
      : '';

    return `${hour}${showMinute}${isPm ? 'PM' : 'AM'}`;
  }

  return `${hour}:${minute < 10 ? `0${minute}` : minute}${isPm ? 'pm' : 'am'}`;
}

/**
 * Convert to twenty four hour time
 *
 * @param {string} time
 * @returns {string} 24 hour time
 */
export function convertToTwentyFourHour({
  time = '',
  endOfDay = false,
}) {
  if (!time) {
    return '';
  }

  const timeStr = time.replace(/([A-Za-z]+)/g, '');
  let hour = parseInt(timeStr.split(':')[0], 10);
  const minute = parseInt(timeStr.split(':')[1], 10);
  const isPm = time.toLowerCase().indexOf('pm') >= 0;

  if (isPm && hour !== 12) {
    hour += 12;
  } else if (!isPm && hour === 12 && !endOfDay) {
    // if the max allowed time is not 24:00 use midnight at the start of the day
    hour = 0;
  } else if (!isPm && hour === 12 && endOfDay) {
    if (minute === 0) {
      // if the max allowed time is midnight at the end of the day (24:00) make the hour 24
      hour = 24;
    } else {
      // the minutes are greater than 0 indicating midnight of the same day instead of midnight the next day
      hour = 0;
    }
  }

  if (!isFinite(hour) || !isFinite(minute)) {
    console.error(`Hour: ${hour} Minute: ${minute} is not a number`);
    return '';
  }

  return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;
}

/**
 * Get duration between 2 times
 * @param {*} startTime
 * @param {*} stopTime
 * @returns {string}
 */
export function getDuration(startTime, stopTime) {
  const minutes = getMinutesBetween(startTime, stopTime);
  const duration = convertMinutes(minutes);

  return duration;
}

/**
 * Get the offset from the parent time
 * @param {*} parentStartTime
 * @param {*} childStartTime
 * @returns {string}
 */
export function getOffset(parentStartTime, childStartTime) {
  const minutes = getMinutesBetween(parentStartTime, childStartTime);
  const offset = convertMinutes(minutes);

  return offset;
}

/**
 * Return the index for the day of the week Sunday through Saturday (0-6)
 * @param {string} day
 * @returns integer
 */
export function getDayOfWeekIndex(day = '') {
  switch ((day || '').toLowerCase()) {
    case 'su':
    case 'sun':
    case 'sunday':
      return 0;

    case 'mo':
    case 'mon':
    case 'monday':
      return 1;

    case 'tu':
    case 'tue':
    case 'tuesday':
      return 2;

    case 'we':
    case 'wed':
    case 'wednesday':
      return 3;

    case 'th':
    case 'thu':
    case 'thursday':
      return 4;

    case 'fr':
    case 'fri':
    case 'friday':
      return 5;

    case 'sa':
    case 'sat':
    case 'saturday':
      return 6;

    default:
      return -1;
  }
}

/**
 * Return the day string for the day of the week Sunday through Saturday (0-6)
 * @param {integer} index
 * @returns string
 */
export function getDayFromIndex(index, returnFormat = 'dddd') {
  // Full day name (plural)
  if (returnFormat === 'dddd') {
    switch (index) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';

      default:
        return null;
    }
  }

  // 3 letter day name
  if (returnFormat === 'ddd') {
    switch (index) {
      case 0:
        return 'Sun';
      case 1:
        return 'Mon';
      case 2:
        return 'Tue';
      case 3:
        return 'Wed';
      case 4:
        return 'Thu';
      case 5:
        return 'Fri';
      case 6:
        return 'Sat';

      default:
        return null;
    }
  }

  // 2 letter day name
  if (returnFormat === 'dd') {
    switch (index) {
      case 0:
        return 'SU';
      case 1:
        return 'MO';
      case 2:
        return 'TU';
      case 3:
        return 'WE';
      case 4:
        return 'TH';
      case 5:
        return 'FR';
      case 6:
        return 'SA';

      default:
        return null;
    }
  }

  // unknown day format
  console.warn('getDayFromIndex: unknown format', returnFormat);
  return null;
}

/**
 * Sort the day array
 */
export function sortDays(daysArr) {
  // array to store the unsorted array of days
  const unsortedDays = [];

  daysArr.forEach((day) => {
    const dayLower = day.toLowerCase();

    if (dayLower === 'su' || dayLower === 'sun' || dayLower === 'sunday') {
      unsortedDays.push({
        dayIndex: 0,
        weekday: day,
      });
    } else if (dayLower === 'mo' || dayLower === 'mon' || dayLower === 'monday') {
      unsortedDays.push({
        dayIndex: 1,
        weekday: day,
      });
    } else if (dayLower === 'tu' || dayLower === 'tue' || dayLower === 'tuesday') {
      unsortedDays.push({
        dayIndex: 2,
        weekday: day,
      });
    } else if (dayLower === 'we' || dayLower === 'wed' || dayLower === 'wednesday') {
      unsortedDays.push({
        dayIndex: 3,
        weekday: day,
      });
    } else if (dayLower === 'th' || dayLower === 'thu' || dayLower === 'thursday') {
      unsortedDays.push({
        dayIndex: 4,
        weekday: day,
      });
    } else if (dayLower === 'fr' || dayLower === 'fri' || dayLower === 'friday') {
      unsortedDays.push({
        dayIndex: 5,
        weekday: day,
      });
    } else if (dayLower === 'sa' || dayLower === 'sat' || dayLower === 'saturday') {
      unsortedDays.push({
        dayIndex: 6,
        weekday: day,
      });
    }
  });

  const sortedDays = sortBy(unsortedDays, 'dayIndex');
  return sortedDays.map(sortedDay => sortedDay.weekday);
}

/**
 * Retrieve the Users Local Timezone
 */
export function resolveLocalTimeZone() {
  const defaultTimeZone = 'America/New_York';

  if (!Intl) {
    return defaultTimeZone;
  }

  const dtf = Intl.DateTimeFormat();

  if (!dtf) {
    return defaultTimeZone;
  }

  const options = dtf.resolvedOptions();

  if (!options) {
    return defaultTimeZone;
  }

  return options.timeZone || defaultTimeZone;
}

export default {
  getMinutes,
  getMinutesBetween,
  getDuration,
  getOffset,
  getDayOfWeekIndex,
  getDayFromIndex,
  convertMinutes,
  convertSeconds,
  convertDuration,
  convertToTwelveHour,
  convertToTwentyFourHour,
  sortDays,
  resolveLocalTimeZone,
};
