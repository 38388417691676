import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  VibeModal,
  GlobalActions,
  TableFlights,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  VibeButtonNew,
  VibeIcon,
  viClose,
  viAdd,
  color,
} from 'vibeguide';
import SegmentFlightNew from './SegmentFlightNew';
import './SegmentFlights.scss';

function SegmentFlights({
  className,
  user,
  flightId,
  segmentStartDate,
  segmentEndDate,
  setPanel,
  onUpdate,
}) {
  const [confirmFlightSelection, setConfirmFlightSelection] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState(null);

  const onClose = () => {
    setPanel({
      extraPanel: {
        show: false,
      },
      thirdPanel: {
        show: false,
      },
    });
  };

  const onCloseFlights = (flight) => {
    if (!flight) {
      onClose();
      return;
    }

    setSelectedFlight(flight);

    if (flight.adProvider !== 'vibenomics') {
      setConfirmFlightSelection(true);
    } else {
      onUpdate({
        flightId: flight._id,
      });
      onClose();
    }
  };

  /**
   * When a flight is selected from the table
   */
  const onSelectFlight = (flight) => {
    onCloseFlights(flight);
  };

  const onConfirmFlightSelection = () => {
    setConfirmFlightSelection(false);

    onUpdate({
      flightId: selectedFlight._id,
      dayParts: [{ startTime: '', endTime: '' }],
    });

    onClose();
  };

  const onCloseFlightSelection = () => {
    setConfirmFlightSelection(false);
    setSelectedFlight(null);
  };

  const onClickNewFlight = () => {
    setPanel({
      thirdPanel: {
        width: 400,
        show: true,
        children: (
          <SegmentFlightNew
            segmentStartDate={segmentStartDate}
            segmentEndDate={segmentEndDate}
            onCloseFlights={onCloseFlights}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  return (
    <SidePanelContainer className={classNames('SegmentFlights', className)}>
      <SidePanelHeader
        className="flight-header"
        icons={(
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <VibeButtonNew
              text="New Flight"
              style={{
                marginRight: 16,
              }}
              color={color.violetVibe}
              icon={viAdd}
              iconProps={{
                style: {
                  padding: 0,
                },
              }}
              disabled={!user.can('flight.create')}
              onClick={onClickNewFlight}
            />

            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onClose}
            />
          </div>
        )}
      >
        <div className="title">
          Select Flight
        </div>
      </SidePanelHeader>

      <SidePanelContent className="flight-content">
        <TableFlights
          columns={[
            'Select',
            'Name',
            'Status',
            'Opportunity',
            'Opportunity ID',
            'Deal ID',
            'Order Reference ID',
            'Ad Provider',
            'Start Date',
            'End Date',
            'Created By',
            'Created Date',
            '...',
          ]}
          // highlight the assigned flight
          highlight={[{
            _id: flightId,
          }]}
          available
          fetch={API.Flight.list}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Flights',
            urlPaging: false,
            urlFilters: false,
          }}
          onSelectFlight={onSelectFlight}
        />
      </SidePanelContent>

      <VibeModal
        show={confirmFlightSelection}
        type="confirm"
        title="Flight Select Confirmation"
        confirmProps={{
          text: 'Confirm',
          color: color.violetVibe,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmFlightSelection}
        onClose={onCloseFlightSelection}
      >
        <>
          <div>The flight you have selected has an Ad Provider that is not Vibenomics.</div>
          <div style={{ fontWeight: 'bold' }}>Your day-parts and assigned media will be removed.</div>
        </>
      </VibeModal>
    </SidePanelContainer>
  );
}

SegmentFlights.propTypes = {
  className: PropTypes.string,
  /** Selected Flight ID */
  flightId: PropTypes.string,
  /** Segment Start Date */
  segmentStartDate: PropTypes.string.isRequired,
  /** Segment End Date */
  segmentEndDate: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
};

SegmentFlights.defaultProps = {
  className: '',
  flightId: '',
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentFlights);
