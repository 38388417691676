import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EmptyState.scss';

function EmptyState({
  className,
  style,
  image,
  imageStyle,
  title,
  description,
  buttonHtml,
}) {
  return (
    <>
      <div
        className={classNames('EmptyState', className)}
        style={style}
      >
        <div className="empty-state-content">
          {image && (
            <div
              className={classNames('empty-state-image', {
                horizontal: style.height < 300,
              })}
            >
              <img
                src={image}
                style={{
                  maxHeight: 150,
                  ...imageStyle,
                }}
                alt="Empty"
              />
            </div>
          )}

          <div
            className={classNames('empty-state-text', {
              horizontal: style.height < 300,
            })}
          >
            <h2 className="title">
              {title}
            </h2>

            <div className="description">
              {description}
            </div>
          </div>
        </div>
      </div>
      {buttonHtml !== null && (
        <div className="button-wrapper">
          {buttonHtml}
        </div>
      )}
    </>
  );
}

EmptyState.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Image */
  image: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Image Style */
  imageStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Title */
  title: PropTypes.string.isRequired,
  /** Description */
  description: PropTypes.string.isRequired,
  /** Button HTML */
  buttonHtml: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

EmptyState.defaultProps = {
  className: '',
  image: '',
  style: {},
  imageStyle: {},
  buttonHtml: null,
};

export default EmptyState;
