import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../VibeIcon/VibeIcon';
import viRadioOn from '../../icons/viRadioOn';
import viRadioOff from '../../icons/viRadioOff';
import color from '../../sass/color.scss';
import './RadioField.scss';

function RadioField({
  className,
  icon,
  title,
  label,
  selected,
  disabled,
  onClick,
}) {
  return (
    <div
      className={classNames('RadioField', className, { selected, disabled })}
      onClick={disabled
        ? null
        : onClick}
    >
      <VibeIcon
        icon={selected
          ? viRadioOn
          : viRadioOff}
        color={selected
          ? color.violetVibe
          : color.manatee}
        size={24}
      />

      <div className="radio-info">
        {title && (
        <div className="radio-title">
          {icon && (
            <VibeIcon
              icon={icon}
              className="radio-icon"
              color={color.dimGray}
              hoverColor={color.obsidian}
              size={16}
            />
          )}
          <span>{title}</span>
        </div>
        )}
        {label}
      </div>
    </div>
  );
}

RadioField.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  title: PropTypes.string,
  /** Label for the field */
  label: PropTypes.string.isRequired,
  /** Is the field selected */
  selected: PropTypes.bool.isRequired,
  /** Is the field disabled */
  disabled: PropTypes.bool,
  icon: PropTypes.func,
  onClick: PropTypes.func,
};

RadioField.defaultProps = {
  className: '',
  title: '',
  disabled: false,
  icon: null,
  onClick: () => {},
};

export default RadioField;
