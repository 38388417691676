import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import last from 'lodash/last';
import {
  API,
  GlobalActions,
  ExpandedLocationsHelper,
  SpotTypeHelper,
  Notes,
  LocationsAssigned,
  Tag,
  VibeAlert,
  VibeTooltip,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import SubNavigation from '../../../Header/SubNavigation';
import SegmentSchedule from './SegmentSchedule';
import InfoCards from '../OrderDashboard/InfoCards/InfoCards';
import AllocationCompanies from '../../Flights/Sidebar/Allocations/AllocationCompanies';
import './OrderSegment.scss';
import OrderCreatives from './OrderCreatives';

function OrderSegment({
  user,
  setPanel,
}) {
  const [data, setData] = useState({});

  const {
    id: orderId,
    segmentId,
  } = useParams();

  const {
    order,
    segment,
  } = data;

  const getData = async (options = {}) => {
    if (!segmentId || !orderId) {
      return;
    }

    const newData = {};

    // get order details as well
    if (!options.segmentOnly) {
      newData.order = await API.Order.get(orderId);
    }

    // always get segment details
    newData.segment = await API.Order.Segment.get({
      _id: orderId,
      segmentId,
    });

    setData({
      ...data,
      ...newData,
    });
  };

  /**
   * Create a Note
   */
  const onCreateNote = async (note, onSuccess, onError) => {
    try {
      const response = await API.Order.Segment.addNote({
        _id: orderId,
        segmentId,
        note,
      });

      const success = get(response, '[0].type') === 'ORDERSEGMENT.NOTE_ADDED';

      if (success) {
        getData({
          segmentOnly: true,
        });
        onSuccess();
      } else {
        onError('Error Creating Note');
      }
    } catch (err) {
      // error adding the note
      onError(err.message);
    }
  };

  /**
   * Close the Main Sidebar
   * Allocation Companies is shown on the second panel, we need to remove the first panel as well
   */
  const onCloseSidebar = () => {
    setPanel({
      show: false,
      children: null,
    });
  };

  /**
   * When the user clicks location warnings icon
   */
  const onClickLocationWarnings = () => {
    setPanel({
      show: true,
      width: 1,
      extraPanel: {
        show: true,
        width: window.innerWidth,
        children: (
          <AllocationCompanies
            companies={get(data, 'segment.companies', [])}
            onClose={onCloseSidebar}
          />
        ),
      },
    });
  };

  /**
   * When the user clicks location count, get expanded locations
   */
  const onClickLocations = async () => {
    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    const expandedLocations = await API.Order.Segment.getExpandedLocations({
      _id: get(data, 'order._id'),
      segmentId: get(data, 'segment._id'),
    });

    const locationsData = ExpandedLocationsHelper.getLocationsData(expandedLocations);

    setPanel({
      show: true,
      width: 1,
      extraPanel: {
        show: true,
        width: window.innerWidth,
        children: (
          <LocationsAssigned
            companyId={adminCompanyId}
            locationsData={locationsData}
            disabled
          />
        ),
      },
    });
  };

  // Refresh segment data if segmentId changes
  useEffect(() => {
    getData();
  }, [segmentId, orderId]);

  const formatSpotType = find(SpotTypeHelper.list, { value: segment?.spotType })?.label || 'Unknown';

  // build extra links for the order
  const links = [];

  if (order?.opportunityId) {
    links.push({
      label: order?.opportunityName,
      value: order?.opportunityId,
      url: `../../orders/all?opportunityId=${order?.opportunityId}`,
    });
  }

  if (order?.dealId) {
    links.push({
      label: 'Deal ID',
      value: order?.dealId,
      url: `../../orders/all?dealId=${order?.dealId}`,
    });
  }

  const breadcrumbs = (order?.name && segment?.name) && [
    {
      title: 'Dashboard',
      linkUrl: '../../orders/dashboard',
    },
    {
      title: order.name,
      linkUrl: '../details',
      links,
    },
    {
      title: segment.name,
      linkUrl: './',
      tags: (
        <VibeTooltip
          title="Spot Type"
        >
          <div>
            <Tag
              tag={{
                name: formatSpotType,
              }}
              style={{
                background: color.lightGray,
                color: color.manatee,
                fontSize: 12,
              }}
            />
          </div>
        </VibeTooltip>
      ),
    },
  ];

  if (!segment || !order) {
    return null;
  }

  // Get segment day parts for the earliest start and latest end time
  const dayParts = get(segment, 'dayParts', []) || [];

  // Sort the day parts to make sure we get the correct start / stop time
  const dayPartSortStart = sortBy(dayParts, 'startTime');
  const dayPartSortEnd = sortBy(dayParts, 'endTime');

  // Get the earliest start time and the latest end time from the day parts
  const earliestStartTime = get(head(dayPartSortStart), 'startTime', 'Unknown');
  const latestEndTime = get(last(dayPartSortEnd), 'endTime', 'Unknown');

  return (
    <div className="OrderSegment">
      <SubNavigation
        breadcrumbs={breadcrumbs}
      />

      <div className="segment-header">
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <div className="segment-title">
            Order Detail Summary
          </div>

          <div className="segment-stats">
            <div className="stat">
              <div>Companies</div>
              <div className="value">{segment?.locationSummary?.companies || 0}</div>
            </div>

            <div className="stat">
              <div>Banners</div>
              <div className="value">{segment?.locationSummary?.banners || 0}</div>
            </div>

            <div className="stat">
              <div>Locations</div>

              {get(segment, 'warnings.length', 0) > 0 && (
                <VibeAlert
                  style={{
                    cursor: 'pointer',
                    marginLeft: 4,
                    fontSize: 12,
                  }}
                  severity="warning"
                  alerts={segment.warnings || []}
                  size={24}
                  iconOnly
                  onClick={onClickLocationWarnings}
                />
              )}

              <VibeTooltip
                title="View Locations"
                placement="bottom"
              >
                <div
                  className="value action"
                  onClick={onClickLocations}
                >
                  {segment?.locationSummary?.locations || 0}
                </div>
              </VibeTooltip>
            </div>
          </div>
        </div>

        <SegmentSchedule
          startDate={segment.startDate}
          endDate={segment.endDate}
          startTime={earliestStartTime}
          endTime={latestEndTime}
          days={segment.daysOfWeek || []}
        />
      </div>

      {segment.reportingAuthority === 'vibenomics' && (
        <InfoCards
          data={segment}
        />
      )}

      {dayParts.length > 0 && (
        <OrderCreatives
          segment={segment}
          order={order}
          orderId={orderId}
          segmentId={segmentId}
          allowCreativeManagement={segment.adProvider === 'vibenomics'}
        />
      )}

      <div
        style={{
          margin: '2rem',
        }}
      >
        <Grid spacing={2} container>
          <Grid xs={6} item>
            <Notes
              notes={segment?.activity?.map((item) => {
                return {
                  _id: item._id,
                  type: item.activityType,
                  userId: item.userId,
                  username: `${item.userFName} ${item.userLName}`,
                  date: item.date,
                  avatarUrl: item.userImageUrl,
                  note: item.note,
                };
              })}
              allowCreate={user.can('order_segment.modify')}
              numVisibleMessages={5}
              onCreate={onCreateNote}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSegment);
