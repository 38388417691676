import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
  toNumber,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import {
  list as mediaFormatList,
  getIcon as getMediaFormatIcon,
  getTooltip as getMediaFormatTooltip,
} from '../../../helpers/MediaFormat';
import {
  convertToTwentyFourHour,
} from '../../../utils/TimeUtil';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import VibeAlert from '../../VibeAlert/VibeAlert';
import CellTooltip from '../CellTypes/CellTooltip';
import IconCell from '../CellTypes/IconCell';
import TimestampCell from '../CellTypes/TimestampCell';
import DaySelector from '../../DaySelector/DaySelector';
import viSpeaker from '../../../icons/viSpeaker';
import color from '../../../sass/color.scss';
import './TableSegments.scss';

const tableId = 'table:segments';
const permissionPrefix = 'segment';

class TableSegments extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: 'Name',
      searchAttr: 'name',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Segment Type',
      searchAttr: 'segmentType',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Standard',
          value: 'standard',
        },
        {
          label: 'Added Value',
          value: 'added-value',
        },
      ],
    },
    {
      name: 'Media Format',
      searchAttr: 'mediaFormat',
      icon: viSpeaker,
      defaultWidth: 95,
      resizable: false,
      searchable: true,
      sortable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...mediaFormatList,
      ],
    },
    {
      name: 'Start Date',
      searchAttr: 'startDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'End Date',
      searchAttr: 'endDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Created By',
      searchAttr: 'createdByUserName',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Created Date',
      searchAttr: 'createdDate',
      defaultWidth: 175,
      datepicker: true,
      searchable: true,
      sortable: true,
      resizable: true,
    },
    {
      name: 'Start Time',
      searchAttr: 'startTime',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'End Time',
      searchAttr: 'endTime',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Budget',
      searchAttr: 'budget',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'CPM',
      searchAttr: 'cpm',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Target Impressions',
      searchAttr: 'targetImpressions',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Total Impressions',
      searchAttr: 'totalImpressions',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Spots',
      searchAttr: 'totalSpots',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Frequency',
      searchAttr: 'frequency',
      defaultWidth: 175,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Spot Type',
      searchAttr: 'spotType',
      defaultWidth: 175,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
        },
        {
          label: 'General',
          value: 'general',
        },
        {
          label: 'Endemic',
          value: 'endemic',
        },
        {
          label: 'Non Endemic',
          value: 'non-endemic',
        },
        {
          label: 'Adult Beverage',
          value: 'adult-beverage',
        },
      ],
    },
    {
      name: 'Spot Selection Strategy',
      searchAttr: 'spotSelectionStrategy',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
        },
        {
          label: 'Minimize Frequency',
          value: 'minimize-frequency',
        },
        {
          label: 'Maximize Frequency',
          value: 'maximize-frequency',
        },
      ],
    },
    {
      name: 'Days of Week',
      searchAttr: 'daysOfWeek',
      defaultWidth: 275,
      resizable: true,
    },
    {
      name: '...',
      defaultWidth: 72,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveSegment', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveSegment', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.fireBrick,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.aquaForest,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
        flightId,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        const response = await API.Flight.Segment.deactivate({ flightId, _id });

        const successText = 'FLIGHTSEGMENT.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = await API.Flight.Segment.reactivate({ flightId, _id });

        const successText = 'FLIGHTSEGMENT.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    const {
      segmentSummaries,
    } = this.props;

    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Name': {
        const segmentSummary = find(segmentSummaries, { _id: row._id }) || {};

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {get(segmentSummary, 'warnings.length', 0) > 0 && (
              <VibeAlert
                style={{
                  marginRight: 8,
                  fontSize: 12,
                }}
                severity="warning"
                alerts={segmentSummary.warnings || []}
                size={24}
                iconOnly
              />
            )}

            <CellTooltip title={value}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </div>
            </CellTooltip>
          </div>
        );
      }

      case 'Media Format':
        return (
          <IconCell
            rowId={row._rowId}
            icon={getMediaFormatIcon(value)}
            color={color.manatee}
            tooltip={getMediaFormatTooltip(value)}
            tooltipProps={{
              placement: 'right',
            }}
          />
        );

      case 'Start Date':
      case 'End Date':
      case 'Created Date':
        return (
          <TimestampCell
            time={value}
            format="ddd, MMM DD, YYYY"
          />
        );

      case 'Start Time':
      case 'End Time': {
        const formatValue = convertToTwentyFourHour({
          time: value,
        });

        return (
          <CellTooltip title={formatValue}>
            <div className="cell-content">
              {formatValue}
            </div>
          </CellTooltip>
        );
      }

      case 'Budget':
      case 'CPM': {
        const formatValue = toNumber(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        return (
          <CellTooltip title={formatValue}>
            <div className="cell-content">
              {formatValue}
            </div>
          </CellTooltip>
        );
      }

      case 'Target Impressions':
      case 'Total Impressions':
      case 'Spots':
      case 'Frequency': {
        const formatValue = toNumber(value).toLocaleString('en-US');

        return (
          <CellTooltip title={formatValue}>
            <div className="cell-content">
              {formatValue}
            </div>
          </CellTooltip>
        );
      }

      case 'Spot Type':
      case 'Spot Selection Strategy':
      case 'Segment Type': {
        const formatValue = value.replace(/-/g, ' ');
        return (
          <CellTooltip title={formatValue}>
            <div
              className="cell-content"
              style={{
                textTransform: 'capitalize',
              }}
            >
              {formatValue}
            </div>
          </CellTooltip>
        );
      }

      case 'Days of Week':
        return (
          <DaySelector
            dayFormat="dd"
            selectedDays={value}
            disabled
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
    } = this.state;

    return (
      <div className={classNames('Table', 'TableSegments', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableSegments.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  segmentSummaries: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
  ])),
  ...tablePropsType,
};

TableSegments.defaultProps = {
  segmentSummaries: [],
  ...tablePropsDefault,
};

export default withRouter(TableSegments);
