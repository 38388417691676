import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  NavigationSide,
  NavigationSideItem,
  viCity,
  viExact,
  viFlight,
  viAspectRatio,
  viLocalShipping,
  viBarChart,
  viGroupWork,
} from 'vibeguide';

function AdOpsContainer({
  user,
}) {
  let adTargetingUrl;

  if (user.can('targeting_tag.view')) {
    adTargetingUrl = '/ad-ops/targeting/triton';
  } else if (user.can('device_attribute.view')) {
    adTargetingUrl = '/ad-ops/targeting/vistar';
  }

  return (
    <div
      className="AdOpsContainer"
      style={{
        height: '100%',
        marginLeft: 80,
      }}
    >
      <NavigationSide>
        <NavigationSideItem
          text="Order Dashboard"
          url="/ad-ops/orders/dashboard"
          matchUrl="/ad-ops/order"
          icon={viBarChart}
          show={user.can('order.view')}
        />

        <NavigationSideItem
          text="Flights"
          url="/ad-ops/flights"
          matchUrl="/ad-ops/flight"
          icon={viFlight}
          show={user.can('flight.view')}
        />

        <NavigationSideItem
          text="Advertisers"
          url="/ad-ops/advertisers"
          icon={viCity}
          show={user.can('advertiser.view')}
        />

        <NavigationSideItem
          text="Ad Targeting"
          url={adTargetingUrl}
          matchUrl="/ad-ops/targeting"
          icon={viExact}
          show={user.hasAnyOf([
            'targeting_tag.view',
            'device_attribute.view',
          ])}
        />

        <NavigationSideItem
          text="Target Groups"
          url="/ad-ops/target-groups"
          icon={viGroupWork}
          show={user.can('target_group.view')}
        />

        <NavigationSideItem
          text="Screen Resolutions"
          url="/ad-ops/resolutions"
          icon={viAspectRatio}
          show={user.can('screen_resolution.view')}
        />

        <NavigationSideItem
          text="Delivery Systems"
          url="/ad-ops/delivery-systems"
          icon={viLocalShipping}
          show={user.can('flight_delivery_system.view')}
        />
      </NavigationSide>

      <Outlet />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(AdOpsContainer);
