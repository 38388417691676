import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  keysIn,
  size,
  get,
  find,
} from 'lodash';
import {
  GlobalActions,
  AdProviderHelper,
  Field2 as Field,
  Assignments,
  Tag,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import AdProviders from './AdProviders/AdProviders';

function AdSettings({
  user,
  adProgramEnabled,
  adNetworkEnabled,
  adProviderConfig,
  onUpdate,
  setPanel,
}) {
  const disableInput = !user.can('company.modify');

  /**
   * When Ad Providers is clicked
   */
  const onClickAdProviders = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <AdProviders
            adProviderConfig={adProviderConfig}
            disabled={disableInput}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  const onChangeAdsEnabled = () => {
    onUpdate({
      adProgramEnabled: !adProgramEnabled,
    });
  };

  const onChangeAdNetworkEnabled = () => {
    onUpdate({
      adNetworkEnabled: !adNetworkEnabled,
    });
  };

  /**
   * Build the tag string for the configured Ad Provider's
   */
  const getAdProviderString = () => {
    if (size(adProviderConfig) <= 0) {
      return 0;
    }

    const adProviders = keysIn(adProviderConfig);

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {adProviders.map((adProviderKey) => (
          <Tag
            key={`provider-${adProviderKey}`}
            tag={{
              name: get(find(AdProviderHelper.list, { value: adProviderKey }), 'name', 'Unknown'),
            }}
            style={{
              background: color.manatee16,
              color: color.manatee,
              textTransform: 'capitalize',
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="AdSettings">
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={12}
          item
        >
          Ad Settings
        </Grid>
      </Grid>

      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid xs={6} item>
          <Field
            type="toggle"
            label="Ad Network"
            toggleProps={{
              label: 'Enable Ad Network',
              helpText: 'Enabling affects all locations and banners in this company',
              helpTextStyle: {
                color: color.manatee,
                fontWeight: 'bold',
              },
            }}
            value={adNetworkEnabled ? 'on' : 'off'}
            tabIndex={11}
            disabled={disableInput
              || adProgramEnabled
              || !user.can('location-ad-program.modify')}
            onChange={onChangeAdNetworkEnabled}
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="toggle"
            label="Ad Programs"
            toggleProps={{
              label: 'Enable Ad Programs',
            }}
            value={adProgramEnabled ? 'on' : 'off'}
            tabIndex={11}
            disabled={disableInput
              || !adNetworkEnabled
              || !user.can('location-ad-program.modify')}
            onChange={onChangeAdsEnabled}
          />
        </Grid>

        <Grid xs={12} item>
          <Assignments
            style={{
              marginTop: 0,
            }}
            title="Network SSP"
            items={[
              {
                label: 'Ad Providers',
                count: getAdProviderString(),
                disabled: disableInput,
                onClick: onClickAdProviders,
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
}

AdSettings.propTypes = {
  adProviderConfig: PropTypes.object,
  onUpdate: PropTypes.func,
};

AdSettings.defaultProps = {
  adProviderConfig: {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdSettings);
