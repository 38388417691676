import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  NavigationHelper,
  TableUsers,
  API,
  GlobalActions,
  viAdd,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../Header/SubNavigation';
import UserSidebar from './Sidebar/UserSidebar';
import './UserList.scss';

function UserList({
  user,
  location,
  history,
  setPanel,
}) {
  const {
    type: accountType,
  } = useParams();

  const prevSearchRef = useRef(location.search);

  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      userId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, userId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <UserSidebar
          userId={userId}
          accountType={accountType}
          isNew={type === 'new'}
          isDuplicate={type === 'new' && userId !== null}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.userId && qs.type !== 'new';

    if (isNew || isView) {
      setTimeout(() => {
        sidebar(qs.type, qs.userId);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    const {
      search,
    } = location;

    if (search !== prevSearchRef) {
      const qs = NavigationHelper.getParams();

      if (qs.userId || qs.type === 'new') {
        sidebar(qs.type, qs.userId);
      }
    }
  }, [location.search]);

  const rowMenu = [
    {
      name: 'Archive',
      // Allow archive if the user has permission and access to all the locations
      userCan: (row) => {
        const exceedsLocations = user.exceedsLocations(row.locationsAllowed);
        const allowArchive = user.can('user.delete')
          && user.hasAccessToCompany(row.companyId)
          && exceedsLocations;

        return allowArchive;
      },
    },
  ];

  if (accountType !== 'player') {
    rowMenu.unshift({
      name: 'Copy and Edit',
      userCan: 'user.create',
    });
  }

  return (
    <div className="UserList">
      <SubNavigation
        title="Accounts"
        buttons={[
          {
            text: 'New Account',
            icon: viAdd,
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
            userCan: [
              'user.create',
            ],
          },
        ]}
      />

      <TableUsers
        columns={[
          '.',
          'First Name',
          'Last Name',
          'Title',
          'Email',
          'Phone #',
          'Company',
          'Tags',
          '...',
        ]}
        fetch={API.User.list}
        defaultSortBy={{
          label: 'First Name',
          attr: 'fname',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Accounts',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Users',
          tooltip: true,
        }}
        bulk
        bulkProps={{
          edit: false,
          add: false,
          block: false,
          remove: false,
          archive: true,
        }}
        submenu
        submenuProps={{
          rootUrl: '/users/accounts',
          items: [
            {
              name: 'User',
              label: 'User Accounts',
              show: user.can('user.view'),
              enabled: true,
              paginatorLabel: 'User Accounts',
              filters: {
                accountType: 'standard',
              },
            },
            {
              name: 'System',
              label: 'System Accounts',
              show: user.can('integration_user.view'),
              enabled: true,
              paginatorLabel: 'System Accounts',
              columns: [
                '.',
                'System',
                'First Name',
                'Last Name',
                'Title',
                'Email',
                'Phone #',
                'Company',
                'Tags',
                '...',
              ],
              fetch: API.IntegrationUser.list,
            },
            {
              name: 'Player',
              label: 'Player Accounts',
              show: user.can('user.view'),
              enabled: true,
              paginatorLabel: 'Player Accounts',
              filters: {
                accountType: 'player_service',
              },
            },
          ],
        }}
        rowLink={{
          userId: '_id',
          // accountType: 'accountType',
        }}
        menuItems={rowMenu}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList));
