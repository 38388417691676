import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './LabelCell.scss';

function LabelCell({
  className,
  style,
  background,
  color,
  value,
}) {
  return (
    <div
      className={classNames('LabelCell', className)}
      style={{
        backgroundColor: background,
        color,
        ...style,
      }}
    >
      {value}
    </div>
  );
}

LabelCell.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
};

LabelCell.defaultProps = {
  className: '',
  style: {},
  value: '',
  background: 'rgba(149, 154, 173, 0.16)',
  color: '#959aad',
};

export default LabelCell;
