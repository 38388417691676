import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Routes as RouterRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  withRouter,
} from 'vibeguide';
import RequireAuth from '../RequireAuth/RequireAuth';
import LoginContainer from '../Login/LoginContainer';
import MessagesContainer from '../Messages/MessagesContainer';
import MusicContainer from '../Music/MusicContainer';
import ProgramsContainer from '../Programs/ProgramsContainer';
import AdOpsContainer from '../Advertisers/AdOpsContainer';
import LocationsContainer from '../Locations/LocationsContainer';
import UsersContainer from '../Users/UsersContainer';
import IndividualLocationContainer from '../Locations/IndividualLocation/IndividualLocationContainer';
// Route: /users
import UserList from '../Users/UserList';
import ViewUsersWithPermissions from '../Users/ViewUsersWithPermissions';
import PermissionSetList from '../Users/PermissionSetList';
// Route: /ad-ops
import AdvertiserList from '../Advertisers/AdvertiserList';
import TargetTagsContainer from '../Advertisers/TargetTags/TargetTagsContainer';
import FlightContainer from '../AdOps/Flights/FlightContainer';
import FlightDetails from '../AdOps/Flights/FlightDetails';
import TargetGroupContainer from '../AdOps/TargetGroups/TargetGroupContainer';
import ScreenResolutionContainer from '../AdOps/ScreenResolutions/ScreenResolutionContainer';
import DeliverySystemContainer from '../AdOps/DeliverySystems/DeliverySystemContainer';
import OrderContainer from '../AdOps/Orders/OrderContainer';
import OrderList from '../AdOps/Orders/OrderList';
import OrderDetails from '../AdOps/Orders/Order/OrderDetails';
import OrderDashboard from '../AdOps/Orders/OrderDashboard/OrderDashboard';
import DefaultOrderTables from '../AdOps/Orders/OrderDashboard/OrderTables/DefaultOrderTables';
import ActiveOrderTables from '../AdOps/Orders/OrderDashboard/OrderTables/ActiveOrderTables';
import PendingOrderTables from '../AdOps/Orders/OrderDashboard/OrderTables/PendingOrderTables';
import CreativeOrderTables from '../AdOps/Orders/OrderDashboard/OrderTables/CreativeOrderTables';
import PausedOrderTables from '../AdOps/Orders/OrderDashboard/OrderTables/PausedOrderTables';
import CompletedOrderTables from '../AdOps/Orders/OrderDashboard/OrderTables/CompletedOrderTables';
import CancelledOrderTables from '../AdOps/Orders/OrderDashboard/OrderTables/CancelledOrderTables';
import OrderSegment from '../AdOps/Orders/OrderSegment/OrderSegment';
import InfoCardsOutlet from '../AdOps/Orders/OrderDashboard/InfoCards/InfoCardsOutlet';
import InfoGraph from '../AdOps/Orders/OrderDashboard/InfoGraph/InfoGraph';
// Route: /companies
import CompaniesList from '../Companies/CompaniesList';
import CompanyCategories from '../Companies/CompanyCategories';
// Route: /programs
import BaselinesContainer from '../Programs/Baselines/BaselinesContainer';
import EventsContainer from '../Programs/Events/EventsContainer';
// Route: /music
import SongsContainer from '../Music/Songs/SongsContainer';
import PlaylistsContainer from '../Music/Playlists/PlaylistsContainer';
import MixesContainer from '../Music/Mixes/MixesContainer';
import UploadContainer from '../Music/Upload/UploadContainer';
import PublishContainer from '../Music/Publish/PublishContainer';
// Route: /messages
import RequestsContainer from '../Messages/Request/RequestsContainer';
import LibraryContainer from '../Messages/Library/LibraryContainer';
import MessageBlocksContainer from '../Messages/MessageBlocks/MessageBlocksContainer';
// Route: /locations
import LocationsList from '../Locations/LocationsList';
import ImportLocations from '../Locations/Import/ImportLocations';
// Route: /dashboard
import DashboardAlerts from '../Dashboard/DashboardAlerts';
import DashboardSupport from '../Dashboard/DashboardSupport';
import IntegrationLocationFile from '../Locations/Integration/IntegrationLocationFile';
import DashboardExcluded from '../Dashboard/DashboardExcluded';
// Route: /alerts
import AlertList from '../Dashboard/Alerts/AlertList';
// Route: /location
import ScheduleContainer from '../Locations/IndividualLocation/Schedule/ScheduleContainer';
import AdPrograms from '../Locations/IndividualLocation/AdPrograms/AdPrograms';
import ProfileContainer from '../Locations/IndividualLocation/EditLocation/ProfileContainer';
import Content from '../Locations/IndividualLocation/EditLocation/Content';
import TargetTags from '../Locations/IndividualLocation/EditLocation/TargetTags';
import Telemetry from '../Locations/IndividualLocation/EditLocation/Telemetry';
import CompaniesContainer from '../Companies/CompaniesContainer';

function getRootPagePrograms(user) {
  if (user.can('baseline.view')) {
    return '/programs/baselines';
  }

  if (user.can('event.view')) {
    return '/programs/events';
  }

  return user.getRootUrl();
}

function getRootPageMessages(user) {
  if (user.can('message.view') || user.can('voice.talent')) {
    return '/messages/requests';
  }

  if (user.can('messagelist.view')) {
    return '/messages/blocks';
  }

  return user.getRootUrl();
}

function getRootPageMusic(user) {
  if (user.can('playlist.view')) {
    return '/music/playlists';
  }

  if (user.can('mix.view')) {
    return '/music/stations';
  }

  if (user.can('music.view')) {
    return '/music/songs';
  }

  return user.getRootUrl();
}

function getRootPageCompanies(user) {
  if (user.can('company.view')) {
    return '/companies/list';
  }

  if (user.can('company_category.view')) {
    return '/companies/categories';
  }

  if (user.can('company_banner.view')) {
    return '/companies/banners';
  }

  return user.getRootUrl();
}

function getRootPageAdOps(user) {
  if (user.can('order.view')) {
    return '/ad-ops/orders/dashboard';
  }

  if (user.can('flight.view')) {
    return '/ad-ops/flights';
  }

  if (user.can('advertiser.view')) {
    return '/ad-ops/advertisers';
  }

  if (user.can('targeting_tag.view')) {
    return '/ad-ops/targeting/triton';
  }

  if (user.can('device_attribute.view')) {
    return '/ad-ops/targeting/vistar';
  }

  if (user.can('screen_resolution.view')) {
    return '/ad-ops/resolutions';
  }

  if (user.can('flight_delivery_system.view')) {
    return '/ad-ops/delivery-systems';
  }

  return user.getRootUrl();
}

function getRootPageUsers(user) {
  if (user.can('user.view')) {
    return '/users/accounts/user';
  }

  if (user.can('integration_user.view')) {
    return '/users/accounts/system';
  }

  if (user.can('permissionset.view')) {
    return '/users/permissions';
  }

  return user.getRootUrl();
}

function Routes({
  isAuthenticated,
  scrollRef,
  user,
}) {
  useEffect(() => {
    // scroll to the top when the page changes (used for card views and react-perfect-scrollbar content containers)
    scrollRef.scrollTop = 0;
  });

  const rootUrl = user.getRootUrl();

  return (
    <RouterRoutes>
      {/* Locations */}
      <Route
        path="/locations"
        element={(
          <RequireAuth can={['location.view']}>
            <LocationsContainer />
          </RequireAuth>
        )}
      >
        <Route
          path=""
          element={<LocationsList />}
        />
        <Route
          path="import"
          element={(
            <RequireAuth can={['admin.system_admin', 'location.create']}>
              <ImportLocations />
            </RequireAuth>
          )}
        />
      </Route>

      {/* Dashboard */}
      <Route
        path="/dashboard"
        element={(
          <RequireAuth can={['admin.system_admin']}>
            <LocationsContainer />
          </RequireAuth>
        )}
      >
        <Route
          path="alerts"
          element={(
            <RequireAuth can={['alert.view']}>
              <DashboardAlerts />
            </RequireAuth>
          )}
        />
        <Route
          path="support"
          element={(
            <RequireAuth can={['location.view_telemetry']}>
              <DashboardSupport />
            </RequireAuth>
          )}
        />
        <Route
          path="excluded"
          element={<DashboardExcluded />}
        />
      </Route>

      {/* Alerts */}
      <Route
        path="/alerts"
        element={(
          <RequireAuth can={['admin.system_admin', 'alert.view']}>
            <LocationsContainer />
          </RequireAuth>
        )}
      >
        <Route
          path=":type"
          element={<AlertList />}
        />
      </Route>

      {/* Integration */}
      <Route
        path="/integration"
        element={(
          <RequireAuth can={['admin.system_admin']}>
            <LocationsContainer />
          </RequireAuth>
        )}
      >
        <Route
          path="files"
          element={(
            <RequireAuth can={['location_control_file.view']}>
              <IntegrationLocationFile />
            </RequireAuth>
          )}
        />
      </Route>

      {/* Individual Location */}
      <Route
        path="/location/:id"
        element={(
          <RequireAuth can={['location.view']}>
            <IndividualLocationContainer />
          </RequireAuth>
        )}
      >
        <Route
          path="schedule"
          element={<ScheduleContainer />}
        />
        <Route
          path="profile"
          element={<ProfileContainer />}
        />
        <Route
          path="profile/:type"
          element={<ProfileContainer />}
        />
        <Route
          path="assignments"
          element={<Content />}
        />
        <Route
          path="assignments/:tab"
          element={<Content />}
        />
        <Route
          path="assignments/:tab/:type"
          element={<Content />}
        />
        <Route
          path="programs"
          element={(
            <RequireAuth can={['location-ad-program.view']}>
              <AdPrograms />
            </RequireAuth>
          )}
        />
        <Route
          path="targeting/:type"
          exact
          element={(
            <RequireAuth
              anyOf={[
                'location.assign_targeting_tag',
                'location.assign_device_attribute',
                'location.assign_order_id',
              ]}
            >
              <TargetTags />
            </RequireAuth>
          )}
        />
        <Route
          path="telemetry"
          element={(
            <RequireAuth can={['admin.system_admin', 'location.view_telemetry']}>
              <Telemetry />
            </RequireAuth>
          )}
        >
          <Route
            path=":type"
            element={<Telemetry />}
          />
        </Route>
      </Route>

      {/* Messages (Requests, Library, Message Blocks) */}
      <Route
        path="/messages"
        element={(
          <RequireAuth anyOf={['message.view', 'messagelist.view', 'voice.talent']}>
            <MessagesContainer />
          </RequireAuth>
        )}
      >
        <Route
          path=""
          element={(
            <Navigate
              to={getRootPageMessages(user)}
            />
          )}
        />
        <Route
          path="requests"
          element={(
            <RequireAuth anyOf={['message.view', 'voice.talent']}>
              <RequestsContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="requests/:type"
          element={(
            <RequireAuth anyOf={['message.view', 'voice.talent']}>
              <RequestsContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="library"
          element={(
            <RequireAuth can={['message.view']}>
              <LibraryContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="library/:type"
          element={(
            <RequireAuth can={['message.view']}>
              <LibraryContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="blocks"
          element={(
            <RequireAuth can={['messagelist.view']}>
              <MessageBlocksContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="ad/library"
          element={(
            <RequireAuth can={['message.view']}>
              <LibraryContainer />
            </RequireAuth>
          )}
        />
      </Route>

      {/* Music (Playlists, Stations, Songs) */}
      <Route
        path="/music"
        element={(
          <RequireAuth anyOf={['playlist.view', 'mix.view', 'music.view']}>
            <MusicContainer />
          </RequireAuth>
        )}
      >
        <Route
          path=""
          element={(
            <Navigate
              to={getRootPageMusic(user)}
            />
          )}
        />
        <Route
          path="playlists"
          element={(
            <RequireAuth can={['playlist.view']}>
              <PlaylistsContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="stations"
          element={(
            <RequireAuth can={['mix.view']}>
              <MixesContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="songs"
          element={(
            <RequireAuth can={['music.view']}>
              <SongsContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="songs/upload"
          element={(
            <RequireAuth can={['music.create']}>
              <UploadContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="songs/publish"
          element={(
            <RequireAuth can={['music.create']}>
              <PublishContainer />
            </RequireAuth>
          )}
        />
      </Route>

      {/* Programs (Baselines, Events) */}
      <Route
        path="/programs"
        element={(
          <RequireAuth anyOf={['baseline.view', 'event.view']}>
            <ProgramsContainer />
          </RequireAuth>
        )}
      >
        <Route
          path=""
          element={(
            <Navigate
              to={getRootPagePrograms(user)}
            />
          )}
        />
        <Route
          path="baselines"
          element={(
            <RequireAuth can={['baseline.view']}>
              <BaselinesContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="events"
          element={(
            <RequireAuth can={['event.view']}>
              <EventsContainer
                scrollRef={scrollRef}
              />
            </RequireAuth>
          )}
        />
      </Route>

      {/* Companies */}
      <Route
        path="/companies"
        element={(
          <RequireAuth anyOf={['company.view', 'company_category.view', 'company_banner.view']}>
            <CompaniesContainer />
          </RequireAuth>
        )}
      >
        <Route
          path=""
          element={(
            <Navigate
              to={getRootPageCompanies(user)}
            />
          )}
        />
        <Route
          path="list"
          element={(
            <RequireAuth can={['company.view']}>
              <CompaniesList />
            </RequireAuth>
          )}
        />
        <Route
          path="categories"
          element={(
            <RequireAuth can={['company_category.view']}>
              <CompanyCategories />
            </RequireAuth>
          )}
        />
      </Route>

      {/* Ad Ops */}
      <Route
        path="/ad-ops"
        element={(
          <RequireAuth can={['admin.system_admin']}>
            <AdOpsContainer
              scrollRef={scrollRef}
            />
          </RequireAuth>
        )}
      >
        <Route
          path=""
          element={(
            <Navigate
              to={getRootPageAdOps(user)}
            />
          )}
        />
        <Route
          path="advertisers"
          element={(
            <AdvertiserList
              scrollRef={scrollRef}
            />
          )}
        />
        <Route
          path="targeting/:type"
          element={(
            <RequireAuth anyOf={['targeting_tag.view', 'device_attribute.view']}>
              <TargetTagsContainer />
            </RequireAuth>
          )}
        />

        <Route
          path="orders"
          element={(
            <RequireAuth can={['order.view']}>
              <OrderContainer />
            </RequireAuth>
          )}
        >
          <Route
            path="dashboard"
            element={(
              <OrderDashboard />
            )}
          >
            <Route
              index
              element={(
                <DefaultOrderTables />
              )}
            />
            <Route
              path="live"
              element={(
                <ActiveOrderTables />
              )}
            />
            <Route
              path="pending"
              element={(
                <PendingOrderTables />
              )}
            />
            <Route
              path="awaitingcreative"
              element={(
                <CreativeOrderTables />
              )}
            />
            <Route
              path="paused"
              element={(
                <PausedOrderTables />
              )}
            />
            <Route
              path="completed"
              element={(
                <CompletedOrderTables />
              )}
            />
            <Route
              path="cancelled"
              element={(
                <CancelledOrderTables />
              )}
            />
          </Route>

          <Route path=":status">
            <Route
              index
              element={(
                <OrderList />
              )}
            />
            <Route
              path=":orderType"
              element={(
                <OrderList />
              )}
            />
          </Route>
        </Route>

        {/* Individual Order */}
        <Route
          path="order/:id"
          element={(
            <RequireAuth can={['order.view']}>
              <OrderContainer />
            </RequireAuth>
          )}
        >
          <Route
            path="details"
            element={<OrderDetails />}
          >
            <Route
              index
              element={(
                <InfoCardsOutlet />
              )}
            />

            <Route
              path="graph"
              element={(
                <InfoGraph />
              )}
            />
          </Route>

          <Route
            path="segment/:segmentId"
            element={<OrderSegment />}
          />
        </Route>

        <Route
          path="flights"
          element={(
            <FlightContainer />
          )}
        />
        <Route
          path="flight/:id"
          element={(
            <RequireAuth can={['flight.view']}>
              <FlightDetails />
            </RequireAuth>
          )}
        />
        <Route
          path="target-groups"
          element={(
            <RequireAuth can={['target_group.view']}>
              <TargetGroupContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="resolutions"
          element={(
            <RequireAuth can={['screen_resolution.view']}>
              <ScreenResolutionContainer />
            </RequireAuth>
          )}
        />
        <Route
          path="delivery-systems"
          element={(
            <RequireAuth can={['flight_delivery_system.view']}>
              <DeliverySystemContainer />
            </RequireAuth>
          )}
        />
      </Route>

      {/* Users */}
      <Route
        path="/users"
        element={(
          <RequireAuth anyOf={['user.view', 'permissionset.view', 'integration_user.view']}>
            <UsersContainer />
          </RequireAuth>
        )}
      >
        <Route
          path=""
          element={(
            <Navigate
              to={getRootPageUsers(user)}
            />
          )}
        />
        <Route
          path="accounts"
          element={(
            <RequireAuth anyOf={['user.view', 'integration_user.view']}>
              <UserList />
            </RequireAuth>
          )}
        />
        <Route
          path="accounts/:type"
          element={(
            <RequireAuth anyOf={['user.view', 'integration_user.view']}>
              <UserList />
            </RequireAuth>
          )}
        />
        <Route
          path="view-users-with-permissions"
          element={(
            <RequireAuth can={['user.view', 'admin.system_admin']}>
              <ViewUsersWithPermissions />
            </RequireAuth>
          )}
        />
        <Route
          path="permissions"
          element={(
            <RequireAuth can={['permissionset.view']}>
              <PermissionSetList />
            </RequireAuth>
          )}
        />
      </Route>

      <Route
        path="/login"
        element={(
          <LoginContainer
            path="/login"
          />
        )}
      />

      <Route
        path="/"
        element={isAuthenticated ? (
          <Navigate
            to={rootUrl}
          />
        ) : (
          <LoginContainer
            path="/login"
          />
        )}
      />

      {!isAuthenticated ? (
        <Route
          path="/forgot"
          element={(
            <LoginContainer
              path="/forgot"
            />
          )}
        />
      ) : null}

      {!isAuthenticated ? (
        <Route
          path="/reset"
          element={(
            <LoginContainer
              path="/reset"
            />
          )}
        />
      ) : null}

    </RouterRoutes>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default withRouter(connect(mapStateToProps)(Routes));
