import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  setPanel,
  resetPanel,
} from '../../actions/Global/GlobalActions';
import VibeIcon from '../VibeIcon/VibeIcon';
import viClose from '../../icons/viClose';
import color from '../../sass/color.scss';
import './SidePanel.scss';

class SidePanel extends PureComponent {
  constructor(props) {
    super(props);

    // const {
    //   show,
    // } = props;

    // this.panelRootRef = React.createRef();

    this.state = {
      // show,
      // remove transition when show changes to true
      transition: true,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      show,
    } = this.props;

    const {
      show: prevShow,
    } = prevProps;

    const {
      transition: currentTransition,
    } = this.state;

    let updateState = false;

    let transition = currentTransition;

    if (show !== prevShow && show && currentTransition) {
      transition = false;
      updateState = true;

      // setTimeout(() => {
      //   this.setState({
      //     show,
      //   });
      // }, 200);
    } else if (show !== prevShow && !show) {
      this.onClose();
    }

    if (updateState) {
      this.setState({
        transition,
      });
    }
  }

  /**
   * When the backdrop/close icon is clicked
   */
  onToggleClose = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: false,
    });
  };

  /**
   * When the close icon is clicked on the extra panel
   */
  onToggleCloseSecond = () => {
    const {
      setPanel,
      onCloseSecond,
    } = this.props;

    onCloseSecond();

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: (<div />),
        },
      });
    }, 900);
  };

  /**
   * When the close icon is clicked on the extra panel
   */
  onToggleCloseThird = () => {
    const {
      setPanel,
      onCloseThird,
    } = this.props;

    onCloseThird();

    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: (<div />),
        },
      });
    }, 900);
  };

  /**
   * When the close icon is clicked on the extra panel
   */
  onToggleCloseFourth = () => {
    const {
      setPanel,
      onCloseFourth,
    } = this.props;

    onCloseFourth();

    setPanel({
      fourthPanel: {
        show: false,
      },
    });

    setTimeout(() => {
      setPanel({
        fourthPanel: {
          children: (<div />),
        },
      });
    }, 900);
  };

  /**
   * When the panel is closed
   */
  onClose = () => {
    const {
      resetPanel,
      onClose,
    } = this.props;

    // const {
    //   panelRootRef: {
    //     current: panelRootRef,
    //   },
    // } = this;

    // this.setState({
    //   show: false,
    // });

    // Reset the panel after the animation completes
    setTimeout(() => {
      resetPanel();
      onClose();

      this.setState({
        transition: true,
      });
    }, 900);
  };

  render() {
    const {
      title,
      width,
      show,
      backdrop,
      icons,
      children,
      extraPanel,
      thirdPanel,
      fourthPanel,
    } = this.props;

    const {
      // show: stateShow,
      transition,
    } = this.state;

    // console.log('stateShow', stateShow);

    // const {
    //   panelRootRef: {
    //     current: panelRootRef,
    //   },
    // } = this;

    // Get the width of all panels
    const totalWidth = width + extraPanel.width + thirdPanel.width + fourthPanel.width;

    // get the total visible width
    let totalVisibleWidth = show
      ? width
      : 0;

    if (extraPanel.show) {
      // extra sidebar is being shown add to total visible width
      totalVisibleWidth += extraPanel.width;
    }

    if (thirdPanel.show) {
      // extra sidebar is being shown add to total visible width
      totalVisibleWidth += thirdPanel.width;
    }

    if (fourthPanel.show) {
      // extra sidebar is being shown add to total visible width
      totalVisibleWidth += fourthPanel.width;
    }

    // set the width of the panel root to 100% if the panel container width is less than the window width
    const panelRootWidth = totalVisibleWidth > window.innerWidth
      ? '100%'
      : 'auto';

    // calculate the right value for the panel container
    // const panelScrollLeft = panelRootRef
    //   ? panelRootRef.scrollLeft
    //   : 0;

    let right = show
      ? 0
      : totalWidth * -1;
      // : (window.innerWidth + panelScrollLeft) * -1;

    if (show && !extraPanel.show && !thirdPanel.show && !fourthPanel.show) {
      // Show main panel but not the extra one
      right = (extraPanel.width + thirdPanel.width + fourthPanel.width) * -1;
    } else if (show && !extraPanel.show) {
      // Show main panel but not the extra one
      right = extraPanel.width * -1;
    } else if (show && !thirdPanel.show) {
      // Show main panel but not the third one
      right = (thirdPanel.width + fourthPanel.width) * -1;
    } else if (show && !fourthPanel.show) {
      // Show main panel but not the fourth one
      right = fourthPanel.width * -1;
    }

    return (
      <div className={classNames('SidePanel', { show, backdrop })}>
        {backdrop ? (
          <div className="backdrop-container">
            <div
              className="backdrop-left"
              onClick={this.onToggleClose}
            />
            <div
              style={{
                width,
              }}
            />
          </div>
        ) : null}

        <div
          // ref={this.panelRootRef}
          className="panel-root"
          style={{
            width: panelRootWidth,
            direction: 'rtl',
            overflow:
              extraPanel.show
                ? 'auto'
                : 'hidden',
          }}
        >
          <div
            className="panel-container"
            style={{
              width: totalWidth,
              right,
              direction: 'ltr',
            }}
          >
            <div
              className={classNames('panel', { transition })}
              style={{
                width,
              }}
              onDragOver={this.onDragOver}
              onDragEnter={this.onDragEnter}
            >
              {title !== null ? (
                <div className="header">
                  <div className="toolbar">
                    <div className="title">
                      {title}
                    </div>

                    {icons || (
                      <VibeIcon
                        className="close"
                        icon={viClose}
                        color={color.manatee}
                        hoverColor={color.obsidian}
                        size={24}
                        onClick={this.onToggleClose}
                      />
                    )}
                  </div>
                </div>
              ) : null}

              <div
                className="content"
                style={{
                  marginTop: 0,
                }}
              >
                {children}
              </div>
            </div>

            <div
              className={classNames('panel', 'panel-secondary', { transition })}
              style={{
                width: extraPanel.width,
              }}
            >
              {extraPanel.title !== null ? (
                <div className="header">
                  <div className="toolbar">
                    <div className="title">
                      {extraPanel.title}
                    </div>

                    <VibeIcon
                      className="close"
                      icon={viClose}
                      color={color.manatee}
                      hoverColor={color.obsidian}
                      size={24}
                      onClick={this.onToggleCloseSecond}
                    />
                  </div>
                </div>
              ) : null}

              <div className="content">
                {extraPanel.children}
              </div>
            </div>

            <div
              className={classNames('panel', 'panel-tertiary', { transition })}
              style={{
                width: thirdPanel.width || 0,
              }}
            >
              {thirdPanel.title !== null ? (
                <div className="header">
                  <div className="toolbar">
                    <div className="title">
                      {thirdPanel.title}
                    </div>

                    <VibeIcon
                      className="close"
                      icon={viClose}
                      color={color.manatee}
                      hoverColor={color.obsidian}
                      size={24}
                      onClick={this.onToggleCloseThird}
                    />
                  </div>
                </div>
              ) : null}

              <div className="content">
                {thirdPanel.children}
              </div>
            </div>

            <div
              className={classNames('panel', 'panel-fourth', { transition })}
              style={{
                width: fourthPanel.width || 0,
              }}
            >
              {fourthPanel.title !== null ? (
                <div className="header">
                  <div className="toolbar">
                    <div className="title">
                      {fourthPanel.title}
                    </div>

                    <VibeIcon
                      className="close"
                      icon={viClose}
                      color={color.manatee}
                      hoverColor={color.obsidian}
                      size={24}
                      onClick={this.onToggleCloseFourth}
                    />
                  </div>
                </div>
              ) : null}

              <div className="content">
                {fourthPanel.children}
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

SidePanel.propTypes = {
  /** Width of Panel */
  width: PropTypes.number,
  /** Title of Panel */
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  /** Show Panel Immediately */
  show: PropTypes.bool,
  /** Show Backdrop behind the Panel */
  backdrop: PropTypes.bool,
  /** Icons for the main panel */
  icons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Additional Panels */
  extraPanel: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  thirdPanel: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fourthPanel: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** When the panel is closed */
  onClose: PropTypes.func,
  onCloseSecond: PropTypes.func,
  onCloseThird: PropTypes.func,
  onCloseFourth: PropTypes.func,
};

SidePanel.defaultProps = {
  width: 600,
  title: null,
  show: false,
  backdrop: false,
  icons: null,
  children: null,
  extraPanel: {},
  thirdPanel: {},
  fourthPanel: {},
  onClose: () => {},
  onCloseSecond: () => {},
  onCloseThird: () => {},
  onCloseFourth: () => {},
};

const mapDispatchToProps = {
  setPanel,
  resetPanel,
};

export default connect(null, mapDispatchToProps)(SidePanel);
