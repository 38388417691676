import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  API,
  Authentication,
  GlobalActions,
  ToastActions,
  LocationHelper,
  MimeTypeHelper,
  NavigationHelper,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  Field2 as Field,
  VibeTooltip,
  VibeButton,
  VibeModal,
  VibeIcon,
  viClose,
  viCopy,
  viArchive,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import UserLocations from './UserLocations';
import UserPermissions from './UserPermissions';
import Information from './Content/Information';
import NotificationSettings from './Content/NotificationSettings';
import ChangePassword from './Content/ChangePassword';
import defaultUserImage from '../../../assets/users_t.png';
import AccountTypeInfo from './Content/AccountTypeInfo';
import './UserDetails.scss';

function UserDetails({
  selectedAccountType,
  user,
  editUser,
  isNew,
  setPanel,
  onClose,
  onCloseExtraPanel,
  onUpdate,
  history,
  queueToast,
}) {
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [passwordData, setPasswordData] = useState({
    changing: false,
    currentPassword: null,
    newPassword: null,
  });
  const [confirmArchive, setConfirmArchive] = useState(false);

  const permissionPrefix = 'user';

  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  const onClickUnarchive = async () => {
    const { _id, locations } = editUser;

    await API.User.reactivate({
      _id,
      locations,
    });

    onClose();

    // tell listening components the user was saved
    document.dispatchEvent(new Event('onSaveUser'));
  };

  const onCloseArchive = () => {
    setConfirmArchive(false);
  };

  const onConfirmArchive = async () => {
    const { _id } = editUser;

    await API.User.deactivate(_id);

    onCloseArchive();
    onClose();

    // tell listening components the user was saved
    document.dispatchEvent(new Event('onSaveUser'));
  };

  const onClickLocations = () => {
    const {
      _id,
      companyId,
      locations,
      locationsData,
    } = editUser;

    // is the user looking at themself?
    const isSelf = user._id === _id;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('user.assign_locations')
      && !isSelf
      && ((_id && user.can(`${permissionPrefix}.modify`))
        || (!_id && user.can(`${permissionPrefix}.create`)));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <UserLocations
            companyId={companyId}
            locations={locations}
            locationsData={locationsData}
            disabled={!allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  const onClickPermissions = () => {
    const { _id, companyId, permissionIds } = editUser;

    // is the user looking at themself?
    const isSelf = user._id === _id;

    const allowChanges = user.hasAccessToCompany(companyId)
      && !isSelf
      && ((_id && user.can(`${permissionPrefix}.modify`))
      || (!_id && user.can(`${permissionPrefix}.create`)));

    setPanel({
      extraPanel: {
        width: 600,
        show: true,
        children: (
          <UserPermissions
            permissionIds={permissionIds}
            disabled={!allowChanges}
            onClose={onCloseExtraPanel}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  const onDrop = async (fileObj) => {
    const file = get(fileObj, '[0]', {
      file: null,
    });

    if (file && file.error) {
      console.warn('File has an error', fileObj);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const {
        target: {
          result,
        },
      } = e;

      // Save file data to state to show as a preview
      setFile(file.file);
      setFileData(result);
    };

    reader.onabort = () => console.error('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');

    reader.readAsDataURL(file.file);
  };

  const onChangePassword = (passwordData) => {
    setPasswordData(passwordData);
  };

  const onSave = async () => {
    const {
      _id,
      fname,
      lname,
      companyId,
      email,
      locations,
      permissionIds,
      notifications,
      phone,
      title,
      tags = [],
    } = editUser;

    const { currentPassword, newPassword } = passwordData;

    document.dispatchEvent(new Event('onSaveUserStart'));

    // is the user looking at themself?
    // allow changes normally prevented if they are duplicating themselves (any changes will be for a new user)
    const isSelf = user._id === _id && !isNew;

    const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');

    // data users with modify permission can edit
    const data = {
      fname,
      lname,
      companyId,
      email,
      phone,
      title,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
    };

    if (_id && !isNew) {
      // editing a user
      data._id = _id;
    }

    if (!isSelf) {
      // user is not modifying their own profile
      data.locationsAllowed = locations;
      data.permissions = permissionIds;
    }

    if (isSelf || user.can(`${permissionPrefix}.modify`)) {
      // user is modifying their own notification settings, or is allowed to modify another user
      data.notifications = notifications;
    }

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      let response;
      if (_id && !isNew) {
        response = await API.User.modify(data);
      } else {
        response = await API.User.create(data);
      }

      const userId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = _id && !isNew
        ? responseType === 'USER.UPDATED'
        : responseType === 'USER.CREATED';

      if (success) {
        // Successfully saved the user
        const saveMessage = _id && !isNew
          ? 'User Updated!'
          : 'User Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      if (success && file) {
        // Successfully saved the user, now upload the image
        queueToast({
          type: 'info',
          title: 'Uploading Image...',
          allowClose: true,
        });

        const imageResponse = await API.User.uploadImage(userId, file);

        const imageSuccess = get(imageResponse, '[0].type') === 'USER.IMAGE_UPLOADED';

        if (imageSuccess) {
          queueToast({
            type: 'success',
            title: 'Image Uploaded!',
            allowClose: true,
            delay: 500,
          });
        }
      }

      if (success
        && currentPassword
        && newPassword
      ) {
        // Change the users password
        Authentication.changeUserPassword(_id, currentPassword, newPassword)
          .then(() => {
            // password was changed
            queueToast({
              type: 'success',
              title: 'Password Changed!',
              allowClose: true,
              delay: 500,
            });
          })
          .catch(() => {
            // something went wrong changing the password
            queueToast({
              type: 'error',
              title: 'Change Password Failed',
              allowClose: true,
              delay: 500,
            });
          });
      }

      // Refresh the user details and redirect to their edit screen
      if (success && isNew) {
        const redirectUrl = NavigationHelper.updateParams({
          userId,
          type: null,
        }, {
          pathname: '/users/accounts/user',
        });

        history(redirectUrl);
      }

      // tell listening components the user was saved
      document.dispatchEvent(new Event('onSaveUser'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveUserError'));
    }
  };

  const {
    changing,
    currentPassword,
    newPassword,
  } = passwordData;

  const isSelf = user._id === editUser._id;

  const isACAF = user.isAllLocations({
    companyId: editUser.companyId,
    locations: editUser.locations,
  });

  const locationCount = LocationHelper.getLocationCount(get(editUser, 'locationsData.companies', []), isACAF);
  const permissionCount = get(editUser, 'permissionIds.length', 0);

  const exceedsLocations = user.exceedsLocations(editUser.locations);
  const fullName = `${editUser.fname} ${editUser.lname}`;

  let disableSave = false;

  disableSave = !editUser.fname
    || !editUser.lname
    || !editUser.companyId
    || !editUser.email
    || (changing && (!currentPassword || !newPassword));

  const disableCompany = !isNew || (isNew && !user.sysAdmin);

  const disableInput = !user.hasAccessToCompany(editUser.companyId)
    || !exceedsLocations
    || (editUser._id && !user.can(`${permissionPrefix}.modify`))
    || (!editUser._id && !user.can(`${permissionPrefix}.create`));

  const showDuplicate = user.can(`${permissionPrefix}.create`)
    && user.exceedsLocations(editUser.locations);

  const showArchive = user.can(`${permissionPrefix}.delete`)
    && user.hasAccessToCompany(editUser.companyId)
    && exceedsLocations;
  const isArchived = !editUser.active;

  return (
    <SidePanelContainer className="UserDetails">
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="details-header">
          <div className="user-image">
            <Field
              type="dropzone"
              dropzoneProps={{
                type: 'image',
                style: {
                  width: 40,
                  height: 40,
                },
                dragOverStyle: {
                  background: 'rgba(0, 0, 0, 0.5)',
                },
                placeholder: (
                  <VibeTooltip
                    title={isSelf || !disableInput
                      ? 'Click or drag an image to change user avatar'
                      : ''}
                  >
                    <img
                      className="upload-image"
                      src={fileData || editUser.imageUrl || defaultUserImage}
                      alt="User"
                    />
                  </VibeTooltip>
                ),
                allowUpload: isSelf || !disableInput,
                mimeTypes: MimeTypeHelper.images,
              }}
              onDrop={onDrop}
            />
          </div>

          {editUser._id ? (
            <div className="flex-horizontal">
              <div className="title">
                <VibeTooltip
                  title={fullName}
                >
                  <span>
                    {fullName}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New User Account
            </div>
          )}
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <AccountTypeInfo
          selectedAccountType={selectedAccountType}
        />

        <Information
          firstName={editUser.fname}
          lastName={editUser.lname}
          companyId={editUser.companyId}
          companyName={editUser.companyName}
          email={editUser.email}
          title={editUser.title}
          phone={editUser.phone}
          tags={editUser.tags}
          disableInput={disableInput}
          disableCompany={disableCompany}
          onUpdate={onUpdate}
        />

        {(isSelf || user.can(`${permissionPrefix}.modify`)) && (
          <NotificationSettings
            email={get(editUser, 'notifications.email', false)}
            sms={get(editUser, 'notifications.sms', false)}
            // disableInput={disableInput}
            onUpdate={onUpdate}
          />
        )}

        {!disableInput && (
          <Assignments
            items={[{
              label: 'Locations Assigned',
              count: locationCount.display.element,
              tooltip: locationCount.display.tooltip,
              required: true,
              disabled: disableInput || !editUser.companyId,
              warning: !exceedsLocations
                ? 'You do not have access to all the locations assigned to this object'
                : null,
              onClick: !disableInput && editUser.companyId
                ? onClickLocations
                : null,
            },
            {
              label: 'Permissions Assigned',
              count: permissionCount,
              required: true,
              disabled: disableInput,
              onClick: !disableInput
                ? onClickPermissions
                : null,
            },
            ]}
          />
        )}

        {(isSelf && !isNew) && (
          <ChangePassword
            onChange={onChangePassword}
          />
        )}
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButton
          className="btn-save"
          text="Save Changes"
          btnColor="purple"
          textColor="white"
          loadingEvent="onSaveUser"
          disabled={disableSave || (!isSelf && disableInput)}
          onClick={onSave}
        />

        {!isNew && (
          <div className="toolbar-buttons">
            {showDuplicate && (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viCopy}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.violetVibe,
                  }}
                  color={color.violetVibe}
                  size={16}
                  tooltip="Duplicate"
                  link={NavigationHelper.updateParams({
                    type: 'new',
                  })}
                />
              </div>
            )}

            {(showArchive && !isArchived) && (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.fireBrick,
                  }}
                  tooltip="Archive"
                  color={color.fireBrick}
                  size={20}
                  onClick={onClickArchive}
                />
              </div>
            )}

            {(showArchive && isArchived) && (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.aquaForest,
                  }}
                  tooltip={locationCount.total > 0
                    ? 'Unarchive'
                    : 'Must have at least 1 active location to unarchive'}
                  color={color.aquaForest}
                  size={20}
                  disabled={locationCount.total <= 0}
                  onClick={onClickUnarchive}
                />
              </div>
            )}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${fullName}?`}
        confirmProps={{
          text: 'Archive',
          color: color.fireBrick,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCloseArchive}
      />
    </SidePanelContainer>
  );
}

UserDetails.propTypes = {
  isNew: PropTypes.bool,
  editUser: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onCloseExtraPanel: PropTypes.func,
  onUpdate: PropTypes.func,
};

UserDetails.defaultProps = {
  isNew: false,
  editUser: {},
  onClose: () => {},
  onCloseExtraPanel: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));
