import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  find,
  get,
  sortBy,
  isUndefined,
  isEqual,
  size,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  NavigationHelper,
  LocationHelper,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  Tracking,
  MessageBlockCard,
  PlayControl,
  VibeModal,
  VibeTooltip,
  VibeButtonNew,
  VibeIcon,
  viChat,
  viTimeHistory,
  viClose,
  viChangeRequest,
  viCheckCircle,
  viMusicVoice,
  viDownload,
  viUpload,
  viArchive,
  viUnarchive,
  viRedo,
  viEmail,
  viCloseCircle,
  withRouter,
  color,
} from 'vibeguide';
import Information from './Section/Information';
import Upload from './Section/Upload';
import Script from './Section/Script';
import PublishingOptions from './Section/PublishingOptions';
import ApproverOptions from './Section/Approvers/ApproverOptions';
import RequestChanges from './Actions/RequestChanges';
import AssignVoiceTalent from './Actions/AssignVoiceTalent';
import AssignApprover from './Actions/AssignApprover';
import ApproverList from './Actions/ApproverList';
import DownloadCut from './Actions/DownloadCut';
import UploadCut from './Actions/UploadCut';
import MessageLocations from './MessageLocations';
import MessageBlocks from './MessageBlocks';
import './MessageDetails.scss';
// import Attachments from './Section/Attachments';

class MessageDetails extends PureComponent {
  constructor(props) {
    super(props);

    // Store which values are updated that require an endpoint
    this.updated = {};
    this.origMessage = {};
    this.origMessageSaved = false;

    this.state = {
      requestChanges: false,
      assignVoiceTalent: false,
      assignApprover: false,
      downloadCut: false,
      uploadCut: false,
      confirmArchive: false,
      confirmApprove: false,
      confirmApproveOverride: false,
      localAttachments: [],
      showEmailApprover: false,
      showConfirmNoMB: false,
      showInvalidMessageBlocks: false,
      invalidMessageBlocks: [],
      // TODO: Update to use both client/partner when the partner objects get added
      approverType: 'client',
    };
  }

  componentDidMount() {
    const {
      user,
      message: {
        companyId,
      },
      onUpdate,
    } = this.props;

    const canSetCompany = user.sysAdmin;

    if (!canSetCompany && !companyId) {
      onUpdate({
        companyId: user.companyId,
        companyName: user.companyName,
      });
    }
  }

  componentDidUpdate() {
    const {
      type,
      message,
    } = this.props;

    if (!this.origMessageSaved && message._id) {
      this.origMessageSaved = true;
      this.origMessage = message;
    }

    if (this.origMessage._id !== message._id) {
      // The message was changed, user clicked another request without closing the sidebar
      this.origMessage = message;
    }

    const {
      origMessage,
    } = this;

    if (this.origMessageSaved) {
      const messageBlockIds = type === 'library'
        ? get(message, 'messageBlocks', []).map(messageBlock => messageBlock._id)
        : get(message, 'messageLists', []).map(messageBlock => messageBlock._id);
      const origMessageBlockIds = type === 'library'
        ? get(origMessage, 'messageBlocks', []).map(messageBlock => messageBlock._id)
        : get(origMessage, 'messageLists', []).map(messageBlock => messageBlock._id);

      // Only track what fields are updated once the original message is saved
      if (message.name !== origMessage.name) {
        this.updated.name = true;
      }
      if (message.publishMode !== origMessage.publishMode) {
        this.updated.publishMode = true;
      }
      if (message.script !== origMessage.script) {
        this.updated.script = true;
      }
      if (message.instructions !== origMessage.instructions) {
        this.updated.instructions = true;
      }
      if (
        message.startDate !== origMessage.startDate
        || message.endDate !== origMessage.endDate
        || message.dueDate !== origMessage.dueDate
      ) {
        // We can't check if there was a previous start/end date in case they add one when originally it had none
        this.updated.dates = true;
      }
      if (JSON.stringify(message.daysOfWeek) !== JSON.stringify(origMessage.daysOfWeek)) {
        this.updated.daysOfWeek = true;
      }
      if (JSON.stringify(message.tags) !== JSON.stringify(origMessage.tags)) {
        // We can't check if there were previous tags in case they add one when originally it had none
        this.updated.tags = true;
      }
      if (JSON.stringify(messageBlockIds) !== JSON.stringify(origMessageBlockIds)) {
        this.updated.messageLists = true;
      }
      if (!isEqual(message.locations, origMessage.locations)) {
        this.updated.locations = true;
      }
      if (!isEqual(message.trackingUrls, origMessage.trackingUrls)) {
        this.updated.trackingUrls = true;
      }
    }
  }

  /**
   * When the locations assigned is clicked
   */
  onClickLocations = () => {
    const {
      user,
      message: {
        _id,
        companyId,
        locations,
        locationsData,
      },
      type,
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('message.assign_locations')
      && ((_id && user.can('message.modify'))
        || (!_id && user.can('message.request')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MessageLocations
            type={type}
            messageId={_id}
            companyId={companyId}
            locations={locations}
            locationsData={locationsData}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  // /**
  //  * When an attachment is dropped on a new message request
  //  */
  // onLocalAttachment = async (file) => {
  //   const {
  //     message: {
  //       script,
  //     },
  //     onUpdate,
  //   } = this.props;

  //   const {
  //     localAttachments,
  //   } = this.state;

  //   const newLocalAttachments = localAttachments.concat({
  //     fileName: file.name,
  //     fileSizeBytes: file.size,
  //     attachmentId: uniqueId('local-attachment-'),
  //     file,
  //   });

  //   this.setState({
  //     localAttachments: newLocalAttachments,
  //   });

  //   if (script === '') {
  //     onUpdate({
  //       script: 'See attached',
  //     });
  //   }
  // };

  /**
   * When a local attachment is deleted
   */
  onDeleteLocalAttachment = async (attachmentId) => {
    this.setState(state => {
      const localAttachments = state.localAttachments.filter((attachment) => attachment.attachmentId !== attachmentId);

      return {
        localAttachments,
      };
    });
  };

  /**
   * When the message blocks assigned is clicked
   */
  onClickMessageBlocks = async () => {
    const {
      type,
      user,
      message: {
        _id,
        companyId,
        locations,
        messageBlocks = [],
        messageLists = [],
      },
      setPanel,
      onUpdate,
    } = this.props;

    const allowChanges = user.hasAccessToCompany(companyId)
      && user.can('message.assign_locations')
      && ((_id && user.can('message.modify'))
        || (!_id && user.can('message.request')));

    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MessageBlocks
            companyId={companyId}
            locations={locations}
            messageBlocks={type === 'library'
              ? messageBlocks
              : messageLists}
            allowChanges={allowChanges}
            onUpdate={onUpdate}
          />
        ),
      },
    });
  };

  /**
   * When the request changes button is clicked
   */
  onClickRequestChanges = () => {
    this.setState({
      requestChanges: true,
    });
  };

  /**
   * When the request changes is closed
   */
  onCloseRequestChanges = () => {
    this.setState({
      requestChanges: false,
    });

    // tell listening components the message/request was saved
    document.dispatchEvent(new Event('onSaveMessage'));
  };

  /**
   * When the assign voice talent button is clicked
   */
  onClickAssignVoiceTalent = () => {
    this.setState({
      assignVoiceTalent: true,
    });
  };

  /**
   * When the assign voice talent is closed
   */
  onCloseAssignVoiceTalent = (voiceTalent) => {
    const {
      onUpdate,
    } = this.props;

    this.setState({
      assignVoiceTalent: false,
    });

    // ensure the object passed is a voice talent, not a click event handler
    if (voiceTalent && voiceTalent._id) {
      onUpdate({
        voiceTalent,
        voiceTalentId: voiceTalent._id,
        voiceTalentFname: voiceTalent.fname,
        voiceTalentLname: voiceTalent.lname,
        voiceTalentImageUrl: voiceTalent.imageUrl,
      });
    }

    // tell listening components the message/request was saved
    document.dispatchEvent(new Event('onSaveMessage'));
  };

  /**
   * When the upload button is clicked
   */
  onClickUpload = () => {
    this.setState({
      uploadCut: true,
    });
  };

  /**
   * When the upload cut is closed
   */
  onCloseUpload = () => {
    const {
      user,
      message,
      onRefresh,
    } = this.props;

    const isVoiceTalent = user.voiceTalent && user._id === message.voiceTalentId;

    this.setState({
      uploadCut: false,
    });

    // tell listening components the message/request was saved
    document.dispatchEvent(new Event('onSaveMessage'));

    if (!isVoiceTalent) {
      // Do not refresh for voice talent because they can only view rough cuts
      onRefresh();
    }
  };

  /**
   * When the download button is clicked
   */
  onClickDownload = () => {
    this.setState({
      downloadCut: true,
    });
  };

  /**
   * When the download cut is closed
   */
  onCloseDownload = () => {
    this.setState({
      downloadCut: false,
    });
  };

  /**
   * When the archive button is clicked
   */
  onClickArchive = () => {
    this.setState({
      confirmArchive: true,
    });
  };

  /**
   * When the unarchive button is clicked
   */
  onClickUnarchive = async () => {
    const {
      type,
      message: {
        _id,
        locations,
      },
      onClose,
    } = this.props;

    if (type === 'request') {
      await API.MessageRequest.reactivate({
        _id,
        locations,
      });
    } else {
      await API.Message.reactivate({
        _id,
        locations,
      });
    }

    onClose();

    // tell listening components the message/request was saved
    document.dispatchEvent(new Event('onSaveMessage'));
  };

  /**
   * When the archive modal is closed
   */
  onCloseArchive = () => {
    this.setState({
      confirmArchive: false,
    });
  };

  /**
   * When the no message block modal is closed
   */
  onConfirmAndSend = () => {
    this.setState({
      showConfirmNoMB: false,
    });

    this.onSave({
      confirmNoMB: true,
    });
  };

  /**
   * When the no message block modal is closed
   */
  onCancelAndMsgBlocks = () => {
    this.setState({
      showConfirmNoMB: false,
    });

    this.onClickMessageBlocks();
  };

  /**
   * When the archive modal is confirmed
   */
  onConfirmArchive = async () => {
    const {
      type,
      message: {
        _id,
      },
      onClose,
    } = this.props;

    if (type === 'request') {
      await API.MessageRequest.deactivate(_id);
    } else {
      await API.Message.deactivate(_id);
    }

    this.onCloseArchive();
    onClose();

    // tell listening components the message/request was saved
    document.dispatchEvent(new Event('onSaveMessage'));
  };

  /**
   * When the approve button is clicked
   */
  onClickApprove = () => {
    this.setState({
      confirmApprove: true,
    });
  };

  /**
   * When the approve modal is closed
   */
  onCloseApprove = () => {
    this.setState({
      confirmApprove: false,
    });
  };

  /**
   * When the approve message modal is confirmed
   */
  onConfirmApprove = async () => {
    const {
      message: {
        _id,
      },
      onRefresh,
    } = this.props;

    const response = await API.MessageRequest.Approval.approve({
      _id,
      response: 'accept',
      approvalType: 'client',
    });

    const success = get(response, '[0].type') === 'MESSAGEREQUEST.APPROVED'
      || get(response, '[0].type') === 'MESSAGEREQUEST.PARTIALLY_APPROVED';

    if (success) {
      // tell listening components the message/request was saved
      document.dispatchEvent(new Event('onSaveMessage'));
      this.onCloseApprove();
      onRefresh();
    }
  };

  /**
   * When the override approve button is clicked
   */
  onClickApproveOverride = () => {
    this.setState({
      confirmApproveOverride: true,
    });
  };

  /**
   * When the approve modal is closed
   */
  onCloseApproveOverride = () => {
    this.setState({
      confirmApproveOverride: false,
    });
  };

  /**
   * When the approve override modal is confirmed
   */
  onConfirmApproveOverride = async () => {
    const {
      message: {
        _id,
      },
      onRefresh,
    } = this.props;

    const response = await API.MessageRequest.Approval.approve({
      _id,
      response: 'accept',
      approvalType: 'override-global',
    });

    const success = get(response, '[0].type') === 'MESSAGEREQUEST.APPROVED'
      || get(response, '[0].type') === 'MESSAGEREQUEST.PARTIALLY_APPROVED';

    if (success) {
      // tell listening components the message/request was saved
      document.dispatchEvent(new Event('onSaveMessage'));
      this.onCloseApproveOverride();
      onRefresh();
    }
  };

  /**
   * User does not auto remove the invalid message blocks
   */
  onCancelRemoveInvalidMessageBlocks = () => {
    this.setState({
      showInvalidMessageBlocks: false,
      invalidMessageBlocks: [],
    });
  };

  /**
   * User wants to automatically remove the invalid message blocks
   */
  onConfirmRemoveInvalidMessageBlocks = async () => {
    const {
      type,
      message: {
        _id,
        messageLists,
        messageBlocks,
      },
      onUpdate,
    } = this.props;

    const {
      invalidMessageBlocks,
    } = this.state;

    // message library uses messageBlocks and requests use messageLists
    const blocks = type === 'request'
      ? messageLists
      : messageBlocks;

    const newMessageBlocks = [];

    blocks.forEach((block) => {
      const isInvalid = find(invalidMessageBlocks, { _id: block._id }) !== undefined;

      if (!isInvalid) {
        newMessageBlocks.push(block);
      }
    });

    const data = type === 'request'
      ? {
        messageLists: newMessageBlocks,
      }
      : {
        messageBlocks: newMessageBlocks,
      };

    onUpdate(data);

    // change the selected message blocks
    const updateMessageBlocksResponse = type === 'request'
      // update message request message blocks
      ? await API.MessageRequest.changeMessageBlocks({
        _id,
        messageLists: newMessageBlocks.map(block => block._id),
      })
      // update message library message blocks
      : await API.Message.changeMessageBlocks({
        _id,
        messageBlocks: newMessageBlocks.map(block => block._id),
      });

    // did the message blocks get removed from the message
    const updateMessageBlocksSuccess = type === 'request'
      ? get(updateMessageBlocksResponse, '[0].type') === 'MESSAGEREQUEST.MESSAGELISTS_CHANGED'
      : get(updateMessageBlocksResponse, '[0].type') === 'MESSAGE.MESSAGE_BLOCKS_UPDATED';

    if (updateMessageBlocksSuccess) {
      // update the message with the new location selection / message block assignments
      this.onSave({
        // skip the message block update call since it just occurred
        skipMessageBlockUpdate: true,
      });
    }

    this.setState({
      showInvalidMessageBlocks: false,
      invalidMessageBlocks: [],
    });
  };

  /**
   * Save the Message
   */
  onSave = async (options = {}) => {
    const {
      message: {
        _id,
        requestId,
        name,
        messageType,
        companyId,
        advertiserId,
        locations,
        trackingUrls,
        messageBlocks = [],
        messageLists = [],
        tags,
        readType,
        script,
        instructions,
        startDate,
        endDate,
        dueDate,
        publishMode,
        file,
        daysOfWeek,
      },
      message,
      type,
      isNew,
      isDuplicate,
      history,
      queueToast,
    } = this.props;

    const {
      localAttachments,
      approverType,
    } = this.state;

    const {
      updated,
    } = this;

    // custom data to send to the message create/update
    const customData = {};

    if (message.messageType === 'ad' || message.messageType === 'ext-ad') {
      // include tracking URLs for Ad message types
      customData.trackingUrls = trackingUrls;
    }

    const invalidMessageBlocks = this.validateMessageBlocks();

    if (invalidMessageBlocks.length > 0) {
      this.setState({
        showInvalidMessageBlocks: true,
        invalidMessageBlocks,
      });

      return;
    }

    if (isNew
        // save with no message blocks if they've confirmed
        && (size(messageBlocks) <= 0 && !options.confirmNoMB)
        && messageType !== 'ext-ad'
        && messageType !== 'air'
        && messageType !== 'spec'
    ) {
      this.setState({
        showConfirmNoMB: true,
      });

      return;
    }

    document.dispatchEvent(new Event('onSaveMessageStart'));

    // Get tags to add/remove
    const modifyTags = tags.filter(tag => tag.status === 'add' || tag.status === 'remove');
    // is this a message request we're updating?
    const isUpdateMessageRequest = type === 'request' && !isNew;
    let messageResponse;

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      if (isNew || isDuplicate) {
        // Create a message request
        // get the approver settings based on the approverType (client, partner)
        const approverObject = approverType === 'client'
          ? 'clientApproval'
          : 'partnerApproval';

        const approverSettings = get(message, approverObject, {}) || {};

        if (publishMode === 'review') {
          // only include approver data if the message requires approver
          customData[approverObject] = {
            ...approverSettings,
            approvers: approverSettings.approvers.map((approver) => {
              return {
                _id: approver._id,
                approvalType: approverType,
              };
            }),
          };
        }

        messageResponse = await API.MessageRequest.create({
          originalRequestId: requestId,
          name,
          locations,
          publishMode,
          messageType,
          advertiserId,
          companyId,
          script,
          instructions,
          messageLists: messageBlocks.map(messageBlock => messageBlock._id),
          readType,
          startDate: startDate
            ? new moment(startDate).format('YYYY-MM-DD')
            : '',
          endDate: endDate
            ? new moment(endDate).format('YYYY-MM-DD')
            : '',
          // only include a due date if not an upload
          dueDate: dueDate && message.messageType !== 'ext' && message.messageType !== 'ext-ad'
            ? new moment(dueDate).format('YYYY-MM-DD')
            : '',
          daysOfWeek,
          tags: modifyTags.map((tag) => {
            return {
              _id: tag._id,
              action: tag.status,
            };
          }),
          ...customData,
        });
      } else if (isUpdateMessageRequest) {
        // Update a message request
        if (updated.name) {
          // Update the message name
          const response = await API.MessageRequest.rename({
            _id,
            name,
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.RENAMED';

          if (success) {
            this.origMessage.name = name;

            queueToast({
              type: 'success',
              title: 'Name Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        if (updated.publishMode) {
          // Update the message publish mode
          const data = {
            _id,
            publishMode,
          };

          const response = await API.MessageRequest.changePublishMode(data);

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.PUBLISH_MODE_CHANGED';

          if (success) {
            this.origMessage.publishMode = publishMode;

            queueToast({
              type: 'success',
              title: 'Publish Mode Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        if (updated.script) {
          // Update the message script
          const response = await API.MessageRequest.changeScript({
            _id,
            script,
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.SCRIPT_CHANGED';

          if (success) {
            this.origMessage.script = script;

            queueToast({
              type: 'success',
              title: 'Script Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        if (updated.instructions) {
          // Update the message special instructions
          const response = await API.MessageRequest.changeInstructions({
            _id,
            instructions,
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.INSTRUCTIONS_CHANGED';

          if (success) {
            this.origMessage.instructions = instructions;

            queueToast({
              type: 'success',
              title: 'Instructions Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        if (updated.dates || updated.daysOfWeek) {
          // Update the message dates
          const response = await API.MessageRequest.changeDates({
            _id,
            startDate: new moment(startDate).format('YYYY-MM-DD'),
            endDate: new moment(endDate).format('YYYY-MM-DD'),
            dueDate: new moment(dueDate).format('YYYY-MM-DD'),
            daysOfWeek,
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.DATES_CHANGED';

          if (success) {
            this.origMessage.startDate = startDate;
            this.origMessage.endDate = endDate;
            this.origMessage.dueDate = dueDate;
            this.origMessage.daysOfWeek = daysOfWeek;

            queueToast({
              type: 'success',
              title: 'Dates Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        if (updated.tags) {
          // Update the message tags
          const response = await API.MessageRequest.changeTags({
            _id,
            tags: modifyTags.map((tag) => {
              return {
                _id: tag._id,
                action: tag.status,
              };
            }),
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.TAGS_CHANGED';

          if (success) {
            this.origMessage.tags = modifyTags;

            queueToast({
              type: 'success',
              title: 'Tags Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        // Update the message blocks for a message request
        // do not update if the option is set (when message blocks are removed due to a location change)
        if (updated.messageLists && !options.skipMessageBlockUpdate) {
          const response = await API.MessageRequest.changeMessageBlocks({
            _id,
            messageLists: messageLists.map(messageBlock => messageBlock._id),
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.MESSAGELISTS_CHANGED';

          if (success) {
            this.origMessage.messageLists = messageLists;

            queueToast({
              type: 'success',
              title: 'Message Blocks Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        if (updated.locations) {
          const response = await API.MessageRequest.changeLocations({
            _id,
            locations,
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.LOCATIONS_CHANGED';

          if (success) {
            this.origMessage.locations = locations;

            queueToast({
              type: 'success',
              title: 'Locations Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }

        if (updated.trackingUrls && (message.messageType === 'ad' || message.messageType === 'ext-ad')) {
          const response = await API.MessageRequest.changeTrackingUrls({
            _id,
            trackingUrls,
          });

          const success = get(response, '[0].type') === 'MESSAGEREQUEST.TRACKINGURLS_CHANGED';

          if (success) {
            this.origMessage.trackingUrls = trackingUrls;

            queueToast({
              type: 'success',
              title: 'Tracking URLs Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }
      } else {
        // Update a message
        messageResponse = await API.Message.update({
          _id,
          name,
          locations,
          startDate: startDate
            ? new moment(startDate).format('YYYY-MM-DD')
            : '',
          endDate: endDate
            ? new moment(endDate).format('YYYY-MM-DD')
            : '',
          dueDate: dueDate
            ? new moment(dueDate).format('YYYY-MM-DD')
            : '',
          daysOfWeek,
          tags: modifyTags.map((tag) => {
            return {
              _id: tag._id,
              action: tag.status,
            };
          }),
          ...customData,
        });

        // Update the message blocks for a message in the library
        // do not update if the option is set (when message blocks are removed due to a location change)
        if (updated.messageLists && !options.skipMessageBlockUpdate) {
          const response = await API.Message.changeMessageBlocks({
            _id,
            messageBlocks: messageBlocks.map(messageBlock => messageBlock._id),
          });

          const success = get(response, '[0].type') === 'MESSAGE.MESSAGE_BLOCKS_UPDATED';

          if (success) {
            this.origMessage.messageLists = messageLists;

            queueToast({
              type: 'success',
              title: 'Message Blocks Changed!',
              allowClose: true,
              delay: 500,
            });
          }
        }
      }

      const newMessageId = get(messageResponse, '[0].documentId', null);

      if (localAttachments.length > 0 && newMessageId) {
        localAttachments.forEach(async attachment => {
          const uploadResponse = await API.MessageRequest.uploadAttachment(newMessageId, attachment.file);

          const uploadId = get(uploadResponse, '[0].documentId', null);

          if (uploadId) {
            this.onDeleteLocalAttachment(attachment.attachmentId);
          }
        });
      }

      if (file) {
        // Upload message
        await API.MessageRequest.uploadFinal(newMessageId, file);
      }

      if (newMessageId || isUpdateMessageRequest) {
        // Created or updated a message
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
          delay: 500,
        });

        if (isUpdateMessageRequest) {
          // Reset the updated fields
          this.updated = {};
        }

        // Refresh the message details
        if (isNew) {
          const redirectUrl = NavigationHelper.updateParams({
            messageId: newMessageId,
            type: null,
          }, {
            pathname: '/messages/requests',
          });

          history(redirectUrl);
        }
      } else {
        queueToast({
          type: 'error',
          title: 'Error Saving Message',
          timeout: 10,
          allowClose: true,
        });
      }

      // tell listening components the message/request was saved
      document.dispatchEvent(new Event('onSaveMessage'));
    } catch (err) {
      console.error('Error Saving Message', err);
      document.dispatchEvent(new Event('onSaveMessageError'));
    }
  };

  onClickApprover = () => {
    this.setState({
      assignApprover: true,
    });
  };

  /**
   * When the approver list is closed
   */
  onCloseApprovers = () => {
    this.setState({
      assignApprover: false,
    });
  };

  onClickEmailApprover = async () => {
    this.setState({
      showEmailApprover: true,
    });
  };

  onChooseApproverToEmail = async (approverId) => {
    const {
      message: {
        _id,
      },
      queueToast,
    } = this.props;

    try {
      queueToast({
        type: 'info',
        title: 'Emailing Approver...',
        allowClose: true,
      });

      const response = await API.MessageRequest.sendApprovalNotification(_id, {
        _id,
        approverId,
      });

      const success = get(response, '[0].type') === 'MESSAGEREQUEST.NOTIFY_APPROVER_STARTED';

      if (success) {
        queueToast({
          type: 'success',
          title: 'Email Sent!',
          allowClose: true,
          delay: 500,
        });
      } else {
        queueToast({
          type: 'error',
          title: 'Error Emailing Approver',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      queueToast({
        type: 'error',
        title: 'Error Emailing Approver',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  onCloseApproverEmail = () => {
    this.setState({
      showEmailApprover: false,
    });
  };

  onReRequest = () => {
    const {
      message,
      onUpdate,
    } = this.props;

    const {
      approverType,
    } = this.state;

    const defaultDate = this.calculateDueDate();

    const approverObject = approverType === 'client'
      ? 'clientApproval'
      : 'partnerApproval';

    // approvers to use (remove any override approvers)
    const approvers = (get(message, `${approverObject}.approvers`, []) || [])
      .filter(approver => approver.approvalType !== 'override-client' && approver.approvalType !== 'override-global')
      .map((approver) => {
        return {
          ...approver,
          // reset the approved time and approval status
          reviewedAt: '',
          status: '',
        };
      });

    // reset data needed for a new message request
    onUpdate({
      dueDate: defaultDate,
      messageLists: [],
      tags: message.tags.map(tag => {
        return {
          ...tag,
          status: 'add',
        };
      }),
      [approverObject]: {
        ...message[approverObject],
        approvers,
      },
    });
  };

  /**
   * Validate the message has at least one location overlap with each assigned message block
   */
  validateMessageBlocks = () => {
    const {
      type,
      message: {
        locations,
        messageLists = [],
        messageBlocks = [],
      },
    } = this.props;

    // message library uses messageBlocks and requests use messageLists
    const blocks = type === 'request'
      ? messageLists
      : messageBlocks;

    const invalidMessageBlocks = [];

    if (blocks.length <= 0) {
      return [];
    }

    blocks.forEach((block) => {
      const hasOverlap = LocationHelper.overlap(locations, block.locations);

      if (!hasOverlap) {
        // the message block is no longer valid for this message based on the location selection
        invalidMessageBlocks.push(block);
      }
    });

    return sortBy(invalidMessageBlocks, 'name');
  };

  calculateDueDate = () => {
    // Default Due Date = +3 Business Days From Today
    let daysToAdd = 3;
    const dayBefore = moment().day();
    const dayAfter = moment().add(daysToAdd, 'days').day();

    // If rolled over to the next week or Saturday, skip the weekend
    if (dayBefore > dayAfter || dayAfter === 6) {
      daysToAdd += 2;
    }

    const defaultDate = moment().add(daysToAdd, 'days').endOf('day').format();

    return defaultDate;
  };

  render() {
    const {
      user,
      message,
      type,
      isNew,
      onToggleChat,
      onToggleHistory,
      onClose,
      onUpdate,
      onRefresh,
      // isDuplicate,
    } = this.props;

    const {
      requestChanges,
      assignVoiceTalent,
      assignApprover,
      downloadCut,
      uploadCut,
      confirmArchive,
      confirmApprove,
      confirmApproveOverride,
      showConfirmNoMB,
      // localAttachments,
      showEmailApprover,
      showInvalidMessageBlocks,
      invalidMessageBlocks,
      approverType,
    } = this.state;

    const isContentCurator = user.can('message.content_curator');
    const isVoiceTalent = user.voiceTalent && user._id === message.voiceTalentId;

    // get the approver settings and list based on the approverType (client, partner)
    const approverSettings = get(message, `${approverType}Approval`, {}) || {};
    const approvers = approverSettings.approvers || [];

    // ACAF match
    const isACAF = user.isAllLocations({
      companyId: message.companyId,
      locations: message.locations,
    });

    const locationCount = LocationHelper.getLocationCount(get(message, 'locationsData.companies', []), isACAF);
    const messageBlockCount = type === 'library'
      ? get(message, 'messageBlocks.length', 0)
      : get(message, 'messageLists.length', 0);
    const exceedsLocations = user.exceedsLocations(message.locations);

    const disableSave = !message.name
      || !message.companyId
      || !message.messageType
      || (message.messageType === 'ad' && !message.advertiserId)
      // Script not required when a file has been uploaded or editing and existing message
      || (isNew
          && (message.messageType !== 'ext' || message.messageType !== 'ext-ad' || message.messageType === 'ad')
          && isUndefined(message.file)
          && !message.script)
      || message.locations.length <= 0
      // Require due date for message requests (except for ext/ext-ad)
      || (type === 'request' && message.messageType !== 'ext' && message.messageType !== 'ext-ad' && !message.dueDate)
      // Require an advertiser for ad messages
      || ((message.messageType === 'ad' || message.messageType === 'ext-ad') && !message.advertiserId)
      // Message requires approval (one approver must be selected)
      || ((message.requireApproval || message.publishMode === 'review') && approvers.length <= 0)
      // Message has approvers selected but no approver setting selected
      || (approvers.length > 0 && !approverSettings.style && message.publishMode === 'review');

    const disableInput = !user.hasAccessToCompany(message.companyId)
      || !exceedsLocations
      || (message._id && !user.can('message.modify'))
      || (!message._id && !user.can('message.request'));

    // disable the assignment sections if not filled in
    const disableLocations = disableInput
      || message.messageType === 'ext-ad'
      || message.messageType === 'air'
      || message.messageType === 'spec'
      || !message.companyId;

    const disableMessageBlocks = disableInput
      || message.locations.length <= 0
      || message.messageType === 'ext-ad'
      || message.messageType === 'air'
      || message.messageType === 'spec'
      || !user.can('messagelist.view');

    // const disableInput = (isNew && !user.can('message.request'))
    //   || (!isNew && !user.can('message.modify'))
    //   || (!isNew && !companyMatch);

    const showReRequest = type === 'library'
      && user.can('message.request')
      && exceedsLocations
      // prevent regular and ad uploads from being re-requested
      && (message.messageType !== 'ext' && message.messageType !== 'ext-ad');

    const showApproveActions = approvers.length > 0
      ? find(approvers, { _id: user._id }) !== undefined
        && user.can('message.approve')
        && message.status === 'final-cut-uploaded'
        && exceedsLocations
      : user.can('message.approve')
        && message.status === 'final-cut-uploaded'
        && exceedsLocations;

    // show the request changes button
    const showRequestChanges = showApproveActions
      && message.messageType !== 'ext'
      && message.messageType !== 'ext-ad';

    // show the deny button
    const showDeny = showApproveActions
      && (message.messageType === 'ext' || message.messageType === 'ext-ad');

    const showApproveOverrideAction = user.can('message.global_approver')
        && message.status === 'final-cut-uploaded'
        && exceedsLocations;

    const showEmailAction = isContentCurator
      && message.status === 'final-cut-uploaded';

    const showDownload = isContentCurator
      ? message.roughCutUrl || message.finalCutUrl
      : message.finalCutUrl;

    const showUpload = (isContentCurator || isVoiceTalent)
      && (message.status !== 'completed');

    const showArchive = user.can('message.delete')
      && user.hasAccessToCompany(message.companyId)
      && exceedsLocations;

    const isArchived = !message.active;

    const isScriptVisible = message.messageType !== 'ext' && message.messageType !== 'ext-ad';

    const icons = (
      <div>
        {!isNew ? (
          <VibeIcon
            id="chat"
            icon={viChat}
            color={message.changeRequested
              ? color.flamingo
              : color.manatee}
            hoverColor={color.obsidian}
            tooltip="Chat"
            size={24}
            onClick={onToggleChat}
          />
        ) : null}

        {isContentCurator && !isNew ? (
          <VibeIcon
            id="history"
            icon={viTimeHistory}
            color={color.manatee}
            hoverColor={color.obsidian}
            tooltip="History"
            size={24}
            onClick={onToggleHistory}
          />
        ) : null}

        <VibeIcon
          id="close-sidebar"
          className="close"
          icon={viClose}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>
    );

    // const attachments = message.activity ? message.activity
    //   .filter((activity) => activity.activityType === 'attachment')
    //   .map((activity) => activity.detail) : [];

    return (
      <SidePanelContainer className="MessageDetails">
        <SidePanelHeader
          icons={icons}
        >
          {message._id ? (
            <div className="flex-horizontal">
              <PlayControl
                id={message._id}
                name={message.name}
                src={message.url}
                durationSeconds={message.durationSeconds}
                color={color.aquaForest}
                playText="Play Message"
                pauseText="Pause Message"
                disabled={!message.url}
                size={24}
              />

              <div className="title">
                <VibeTooltip title={message.name}>
                  <span>
                    {message.name}
                  </span>
                </VibeTooltip>
              </div>
            </div>
          ) : (
            <div className="title">
              New Message Request
            </div>
          )}
        </SidePanelHeader>

        <SidePanelContent>
          <Information
            name={message.name}
            type={message.messageType}
            companyId={message.companyId}
            companyName={message.companyName}
            advertiserName={message.advertiserName}
            tags={message.tags}
            voiceTalentId={message.voiceTalentId}
            disableInput={disableInput}
            isNew={isNew}
            onUpdate={onUpdate}
          />

          {!message._id
            && (message.messageType === 'ext' || message.messageType === 'ext-ad')
            && (user.can('message.upload') || user.can('advertisement.request'))
            && (
              <Upload
                required={!isScriptVisible}
                onUpdate={onUpdate}
              />
            )}

          {isScriptVisible && (
            <Script
              script={message.script}
              readType={message.readType}
              instructions={message.instructions}
              scriptVisible={isScriptVisible}
              disableScript={disableInput
                || (!isNew && type === 'library')
                || (!isNew && !isContentCurator)}
              disableInstructions={disableInput
                || (!isNew && type === 'library')
                || (!isNew && !isContentCurator)}
              hasFile={!isUndefined(message.file)}
              onUpdate={onUpdate}
            />
          )}

          {!isVoiceTalent && (
            <>
              <PublishingOptions
                type={message.messageType}
                startDate={message.startDate}
                endDate={message.endDate}
                dueDate={message.dueDate}
                daysOfWeek={message.daysOfWeek}
                disableInput={disableInput}
                allowDueDate={message.messageType !== 'ext' && message.messageType !== 'ext-ad'}
                isNew={isNew}
                isRequest={type === 'request'}
                onUpdate={onUpdate}
              />

              {(
                (type === 'library' && message.publishMode !== 'automatic')
                  || type === 'request'
                  || isNew
              ) && (
                <ApproverOptions
                  type={type}
                  approverType={approverType}
                  message={message}
                  disableInput={disableInput}
                  isNew={isNew}
                  isRequest={type === 'request'}
                  allowEditApprover={isContentCurator || isNew}
                  onUpdate={onUpdate}
                  onClickApprover={this.onClickApprover}
                />
              )}

              <Assignments
                items={[{
                  label: 'Locations Assigned',
                  count: locationCount.display.element,
                  tooltip: locationCount.display.tooltip,
                  required: true,
                  disabled: disableLocations,
                  warning: !exceedsLocations
                    ? 'You do not have access to all the locations assigned to this object'
                    : null,
                  onClick: !disableLocations
                    ? this.onClickLocations
                    : null,
                },
                {
                  label: 'Message Blocks Assigned',
                  count: messageBlockCount,
                  disabled: disableMessageBlocks,
                  warning: !user.can('messagelist.view')
                    ? 'You do not have permission to view message blocks'
                    : null,
                  onClick: !disableMessageBlocks
                    ? this.onClickMessageBlocks
                    : null,
                }]}
              />
            </>
          )}

          {(message.messageType === 'ad' || message.messageType === 'ext-ad')
            && (
              <Tracking
                onUpdate={onUpdate}
                trackingUrls={message.trackingUrls}
              />
            )}

          {/* {type === 'request' || isDuplicate
            ? (
              <Attachments
                attachments={attachments}
                localAttachments={localAttachments}
                onLocalAttachment={this.onLocalAttachment}
                onDeleteLocalAttachment={this.onDeleteLocalAttachment}
                messageId={message._id}
                onRefresh={onRefresh}
                isDuplicate={isDuplicate}
              />
            )
            : null} */}
        </SidePanelContent>

        <SidePanelFooter className="panel-footer">
          {!isVoiceTalent && (
            <VibeButtonNew
              className="btn-save"
              text={isNew
                ? 'Send Request'
                : 'Save Changes'}
              color={color.violetVibe}
              loadingEvent="onSaveMessage"
              disabled={disableSave || disableInput}
              onClick={this.onSave}
            />
          )}

          {!isNew ? (
            <div className="toolbar-buttons">
              {showReRequest && (
                <VibeButtonNew
                  variant="outlined"
                  className="toolbar-button"
                  text="Re-request"
                  color={color.violetVibe}
                  link={NavigationHelper.updateParams({
                    type: 'new',
                  })}
                  icon={(
                    <VibeIcon
                      icon={viRedo}
                      color={color.violetVibe}
                      size={16}
                    />
                  )}
                  onClick={this.onReRequest}
                />
              )}

              {showRequestChanges && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viChangeRequest}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.flamingo,
                    }}
                    tooltip="Request Changes"
                    color={color.flamingo}
                    size={20}
                    onClick={this.onClickRequestChanges}
                  />
                </div>
              )}

              {showDeny && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viCloseCircle}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.fireBrick,
                    }}
                    tooltip="Deny Message"
                    color={color.fireBrick}
                    size={20}
                    onClick={this.onClickRequestChanges}
                  />
                </div>
              )}

              {showApproveActions && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viCheckCircle}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.aquaForest,
                    }}
                    tooltip="Approve Message"
                    color={color.aquaForest}
                    size={20}
                    onClick={this.onClickApprove}
                  />
                </div>
              )}

              {showApproveOverrideAction && (
                <div className="toolbar-button">
                  <VibeButtonNew
                    variant="outlined"
                    text="Override"
                    color={color.violetVibe}
                    tooltip="Globally Approve Message"
                    onClick={this.onClickApproveOverride}
                  />
                </div>
              )}

              {showEmailAction && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viEmail}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.manatee,
                    }}
                    tooltip="Email Approver"
                    color={color.manatee}
                    size={20}
                    onClick={this.onClickEmailApprover}
                  />
                </div>
              )}

              {isContentCurator
                && type === 'request'
                && !message.isExternal
                && message.status !== 'completed'
                && (
                  <div className="toolbar-button">
                    <VibeIcon
                      icon={viMusicVoice}
                      type="button"
                      buttonProps={{
                        size: 32,
                        borderColor: color.manatee,
                      }}
                      tooltip={message.voiceTalentId
                        ? 'Re-assign DJ'
                        : 'Assign DJ'}
                      color={color.manatee}
                      size={20}
                      onClick={this.onClickAssignVoiceTalent}
                    />
                  </div>
                )}

              {showDownload && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viDownload}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.manatee,
                    }}
                    tooltip="Download"
                    color={color.manatee}
                    size={20}
                    onClick={this.onClickDownload}
                  />
                </div>
              )}

              {showUpload && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viUpload}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.manatee,
                    }}
                    tooltip="Upload"
                    color={color.manatee}
                    size={20}
                    onClick={this.onClickUpload}
                  />
                </div>
              )}

              {showArchive && !isArchived && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viArchive}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.fireBrick,
                    }}
                    tooltip="Archive"
                    color={color.fireBrick}
                    size={20}
                    onClick={this.onClickArchive}
                  />
                </div>
              )}

              {showArchive && isArchived && (
                <div className="toolbar-button">
                  <VibeIcon
                    icon={viUnarchive}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.aquaForest,
                    }}
                    tooltip={locationCount.total > 0
                      ? 'Unarchive'
                      : 'Must have at least 1 active location to unarchive'}
                    color={color.aquaForest}
                    size={20}
                    disabled={locationCount.total <= 0}
                    onClick={this.onClickUnarchive}
                  />
                </div>
              )}
            </div>
          ) : null}
        </SidePanelFooter>

        {requestChanges && (
          <RequestChanges
            messageId={message._id}
            messageType={message.messageType}
            onClose={this.onCloseRequestChanges}
            onRefresh={onRefresh}
          />
        )}

        {assignVoiceTalent && (
          <AssignVoiceTalent
            messageId={message._id}
            voiceTalentId={message.voiceTalentId}
            voiceTalentName={`${message.voiceTalentFname} ${message.voiceTalentLname}`}
            onClose={this.onCloseAssignVoiceTalent}
          />
        )}

        {assignApprover && (
          <ApproverList
            message={message}
            approverType={approverType}
            onUpdate={onUpdate}
            onClose={this.onCloseApprovers}
          />
        )}

        {showEmailApprover ? (
          <AssignApprover
            companyId={message.companyId}
            locations={message.locations}
            onChoose={this.onChooseApproverToEmail}
            onClose={this.onCloseApproverEmail}
          />
        ) : null}

        {downloadCut ? (
          <DownloadCut
            roughCutUrl={message.roughCutUrl}
            finalCutUrl={message.finalCutUrl}
            onClose={this.onCloseDownload}
          />
        ) : null}

        {uploadCut ? (
          <UploadCut
            messageId={message._id}
            messageType={message.messageType}
            voiceTalentId={message.voiceTalentId}
            onClose={this.onCloseUpload}
          />
        ) : null}

        <VibeModal
          show={confirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${message.name}?`}
          confirmProps={{
            text: 'Archive',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCloseArchive}
        />

        <VibeModal
          show={showConfirmNoMB}
          type="confirm"
          title="Assign Message Blocks"
          text="This request has not been assigned to any message blocks. Proceed?"
          confirmProps={{
            text: 'Confirm and Send Request',
            color: color.violetVibe,
          }}
          cancelProps={{
            text: 'Assign Message Blocks',
            color: color.aquaForest,
          }}
          onConfirm={this.onConfirmAndSend}
          onClose={this.onCancelAndMsgBlocks}
        />

        <VibeModal
          show={confirmApprove}
          type="confirm"
          title="Approve Message"
          text="Would you like to approve this message?"
          confirmProps={{
            text: 'Approve',
            color: color.aquaForest,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmApprove}
          onClose={this.onCloseApprove}
        />

        <VibeModal
          show={confirmApproveOverride}
          type="confirm"
          title="Approve Message - Override"
          text="Override the approval process and immediately approve this message?"
          confirmProps={{
            text: 'Confirm',
            color: color.violetVibe,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmApproveOverride}
          onClose={this.onCloseApproveOverride}
        />

        <VibeModal
          show={showInvalidMessageBlocks}
          type="confirm"
          maxWidth="md"
          fullWidth
          title="Current location selection no longer allows for these message blocks"
          text={(
            <div
              style={{
                display: 'flex',
                justifyContent:
                  invalidMessageBlocks.length <= 2
                    ? 'center'
                    : 'left',
                overflow: 'auto',
              }}
            >
              {invalidMessageBlocks.map((block) => {
                return (
                  <MessageBlockCard
                    key={block._id}
                    message={block}
                    height={250}
                    hideTags
                  />
                );
              })}
            </div>
          )}
          confirmProps={{
            text: 'Remove Message Blocks',
            color: color.fireBrick,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmRemoveInvalidMessageBlocks}
          onClose={this.onCancelRemoveInvalidMessageBlocks}
        />
      </SidePanelContainer>
    );
  }
}

MessageDetails.propTypes = {
  /** Type of Message */
  type: PropTypes.string.isRequired,
  /** New Message */
  isNew: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onToggleChat: PropTypes.func,
  onToggleHistory: PropTypes.func,
  onUpdate: PropTypes.func,
  /** Refresh the message from the API */
  onRefresh: PropTypes.func,
};

MessageDetails.defaultProps = {
  isNew: false,
  isDuplicate: false,
  message: {},
  onClose: () => {},
  onToggleChat: () => {},
  onToggleHistory: () => {},
  onUpdate: () => {},
  onRefresh: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageDetails));
