import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  get,
  isArray,
} from 'lodash';
import {
  Tag,
  VibeButtonNew,
  VibeIcon,
  VibeTooltip,
  viMoreVertical,
  viChevronRight,
  color,
} from 'vibeguide';
import './SubNavigation.scss';

function SubNavigation({
  user,
  buttons,
  className,
  title,
  breadcrumbs,
  statusTag,
  style,
  menuName,
  isDropMenu,
  disabled,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getButtonsToShow = () => {
    return buttons.map((button) => {
      let show = true;

      if (button.userCan) {
        if (!isArray(button.userCan)) {
          // convert the permission check to an array
          button.userCan = [button.userCan];
        }

        // check permissions to see if the button should be shown
        button.userCan.forEach((permission) => {
          if (!user.can(permission)) {
            show = false;
          }
        });
      }

      if (!show) {
        // do not show the button
        return null;
      }

      return button;
    }).filter(button => button !== null);
  };

  const onMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = getButtonsToShow();

  return (
    <div
      className={classNames('SubNavigation', className)}
      style={style}
    >
      <div className="header">
        {(breadcrumbs && breadcrumbs.length) ? (
          <div className="title breadcrumb">
            {breadcrumbs.map((bc, idx) => {
              const title = get(bc, 'title');
              // extra items to show under the breadcrumb title
              const extraLinks = get(bc, 'links', []);

              return (
                <div key={idx} className="breadcrumb-item">
                  <div className="breadcrumb-title">
                    <div className="breadcrumb-title-inner">
                      <div className="link-container">
                        <Link to={bc.linkUrl}>
                          {title}
                        </Link>
                      </div>

                      {bc.tags && (
                        <div className="tags">
                          {bc.tags}
                        </div>
                      )}
                    </div>

                    {extraLinks.length > 0 && (
                      <div className="extra-details">
                        {extraLinks.map((link) => (
                          <div
                            key={`extra-${link.label}`}
                            className="extra-item"
                          >
                            <div className="label">
                              {link.label}
                            </div>

                            {link.url && (
                              <Link to={link.url}>
                                {link.value}
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {idx !== (breadcrumbs.length - 1) && (
                    <div className="arrow-container">
                      <VibeIcon
                        style={{
                          margin: '0 4px',
                        }}
                        icon={viChevronRight}
                        color={color.manatee}
                        size={32}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <h1 className="title">
            {title}
          </h1>
        )}

        {(menuItems.length > 0 && !isDropMenu) && (
          <div className="sub-navigation-buttons">
            {menuItems.map((button) => {
              return (
                <div key={`sub-nav-btn-${button.text}`}>
                  <VibeButtonNew
                    className="sub-nav-btn"
                    text={button.text}
                    icon={button.icon ? (
                      <VibeIcon
                        icon={button.icon}
                        color={get(button, 'iconProps.color', color.white)}
                        size={get(button, 'iconProps.size', 16)}
                      />
                    ) : null}
                    tooltip={button.tooltip}
                    disabled={button.disabled}
                    link={button.url}
                    color={get(button, 'color', color.aquaForest)}
                    onClick={button.onClick}
                  />
                </div>
              );
            })}
          </div>
        )}

        {((menuItems.length > 0 && isDropMenu) && !disabled) && (
          <div className="actions-container">
            {statusTag && statusTag.label && (
              <div className="status-container">
                <Tag
                  tag={{
                    name: statusTag.label,
                  }}
                  style={{
                    fontSize: 12,
                    textTransform: 'uppercase',
                    ...statusTag.style,
                  }}
                />
              </div>
            )}

            <VibeIcon
              icon={viMoreVertical}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onMenuOpen}
            />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={onMenuClose}
              className="sub-nav-menu"
            >
              {menuName && (
                <h3 className="sub-menu-header">{menuName}</h3>
              )}

              {menuItems.map((button, index) => (
                (button.disabled && button.tooltip) ? (
                  <VibeTooltip
                    key={`tooltip-${index}`}
                    title={button.tooltip}
                    placement="left"
                  >
                    <div>
                      <MenuItem
                        key={index}
                        onClick={button.onClick}
                        className="sub-menu-item"
                        disabled={button.disabled}
                        style={{
                          color: button.color === color.fireBrick
                            ? button.color
                            : color.obsidian,
                        }}
                      >
                        <ListItemIcon>
                          <VibeIcon
                            icon={button.icon}
                            color={button.color === color.fireBrick
                              ? button.color
                              : color.obsidian}
                            size={16}
                          />
                        </ListItemIcon>
                        <ListItemText color={button.color}>{button.text}</ListItemText>
                      </MenuItem>
                    </div>
                  </VibeTooltip>
                ) : (
                  <MenuItem
                    key={index}
                    onClick={button.onClick}
                    className="sub-menu-item"
                    disabled={button.disabled}
                    style={{
                      color: button.color === color.fireBrick
                        ? button.color
                        : color.obsidian,
                    }}
                  >
                    <ListItemIcon>
                      <VibeIcon
                        icon={button.icon}
                        color={button.color === color.fireBrick
                          ? button.color
                          : color.obsidian}
                        size={16}
                      />
                    </ListItemIcon>
                    <ListItemText color={button.color}>{button.text}</ListItemText>
                  </MenuItem>
                )
              ))}
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
}

SubNavigation.propTypes = {
  className: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Title of the page */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  /** Buttons */
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.func,
    iconProps: PropTypes.shape({
      color: PropTypes.string,
      size: PropTypes.number,
    }),
    url: PropTypes.string,
    userCan: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    textColor: PropTypes.string,
    onClick: PropTypes.func,
  })),
  statusTag: PropTypes.shape({
    label: PropTypes.string,
    style: PropTypes.object,
  }),
  menuName: PropTypes.string,
  isDropMenu: PropTypes.bool,
  disabled: PropTypes.bool,
};

SubNavigation.defaultProps = {
  className: '',
  style: {},
  title: '',
  buttons: [],
  statusTag: {
    label: '',
    style: {},
  },
  menuName: '',
  isDropMenu: false,
  disabled: false,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(SubNavigation);
