import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  size,
  map,
  omit,
  uniqueId,
} from 'lodash';
import {
  GlobalActions,
  AdProviderHelper,
  Field2 as Field,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  EmptyState,
  VibeModal,
  VibeButtonNew,
  VibeIcon,
  viAdd,
  viClose,
  color,
} from 'vibeguide';
import ProviderGoodvibes from './ProviderItems/ProviderGoodvibes';
import ProviderTriton from './ProviderItems/ProviderTriton';
import ProviderVibenomics from './ProviderItems/ProviderVibenomics';
import ProviderVistar from './ProviderItems/ProviderVistar';
import ProviderHeader from './ProviderHeader';

let fieldErrors = [];

function AdProviders({
  className,
  style,
  adProviderConfig: propAdProviderConfig,
  disabled,
  setPanel,
  onUpdate,
}) {
  const [showNewAdProvider, setShowNewAdProvider] = useState(false);
  const [adProviderConfig, setAdProviderConfig] = useState(propAdProviderConfig);
  const [confirmClose, setConfirmClose] = useState(false);

  // get total number of providers configured
  const totalProviders = size(adProviderConfig);

  /**
   * Close the Panel
   */
  const onClose = () => {
    if (showNewAdProvider) {
      setShowNewAdProvider(false);
    }

    setPanel({
      extraPanel: {
        show: false,
      },
    });
  };

  /**
   * Validates that providers are filled out
   */
  const providersValidOnClose = () => {
    return fieldErrors.every(field => field.valid !== false);
  };

  const onValidate = ({
    name,
    dataId,
    valid,
  }) => {
    fieldErrors = [
      ...fieldErrors.filter(error => error.dataId !== dataId),
      {
        name,
        dataId,
        valid,
      },
    ];
  };

  /**
   * When the user clicks on closing the panel, check for unsaved changed
   */
  const onClickClose = () => {
    const configs = Object.keys(adProviderConfig);

    if (configs.length > 0) {
      configs.forEach(provider => {
        if (provider.length > 0 && providersValidOnClose()) {
          // Providers are valid, so we update.
          onUpdate({
            adProviderConfig,
          });

          onClose(); // close the panel
        } else {
          // Show invalid provider modal
          setConfirmClose(true);
        }
      });
    } else {
      // no providers are provided, update to remove them
      onUpdate({
        adProviderConfig: {},
      });
      onClose();
    }
  };

  /**
   * When the confirm close panel modal is confirmed
   */
  const onConfirmClose = () => {
    // close the modal
    setConfirmClose(false);
  };

  /**
   * When an update is made to the ad provider, make sure save changes is enabled
   */
  const onUpdateAdProvider = (data) => {
    // update the ad provider locally. User still has to apply changes
    setAdProviderConfig(data);
  };

  /**
   * When an ad provider item is changed
   */
  const onChangeProviderItem = (e, itemId, providerKey) => {
    onUpdateAdProvider({
      ...adProviderConfig,
      [providerKey]: adProviderConfig[providerKey].map((item) => {
        if (item._id === itemId) {
          const {
            target: {
              name,
              value,
            },
          } = e;

          return {
            ...item,
            [name]: value,
          };
        }

        return item;
      }),
    });
  };

  /**
   * When an ad provider item is added
   */
  const onAddProviderItem = (data, providerKey) => {
    onUpdateAdProvider({
      ...adProviderConfig,
      [providerKey]: [
        ...adProviderConfig[providerKey],
        {
          _id: uniqueId('source-'),
          ...data,
        },
      ],
    });
  };

  /**
   * When an ad provider item is removed
   */
  const onRemoveProviderItem = (itemId, providerKey) => {
    // Remove all field validation checks for this config item
    fieldErrors = fieldErrors.filter(error => error.dataId.indexOf(itemId) === -1);

    if (adProviderConfig[providerKey].length === 1) {
      onUpdateAdProvider({
        // remove the entire property from the object
        ...omit(adProviderConfig, [providerKey]),
      });
    } else {
      onUpdateAdProvider({
        ...adProviderConfig,
        [providerKey]: adProviderConfig[providerKey].filter(item => item._id !== itemId),
      });
    }
  };

  /**
   * When the entire ad provider is removed
   */
  const onRemoveProvider = (providerKey) => {
    onUpdateAdProvider({
      // remove the entire property from the object
      ...omit(adProviderConfig, [providerKey]),
    });
  };

  /**
   * When New Ad Provider is Clicked
   */
  const onClickNewAdProvider = () => {
    setShowNewAdProvider(true);
  };

  /**
   * When the new ad provider select changes
   */
  const onChangeNewAdProvider = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    setShowNewAdProvider(false);
    onUpdateAdProvider({
      ...adProviderConfig,
      [value]: [],
    });
  };

  /**
   * Whenever the adProviderConfig is updated from props
   */
  useEffect(() => {
    setAdProviderConfig(propAdProviderConfig);
  }, [propAdProviderConfig]);

  return (
    <SidePanelContainer
      className={classNames('AdProviders', className)}
      style={style}
    >
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClickClose}
          />
        )}
      >
        <div className="title">
          Ad Provider Configuration
        </div>

        <div>
          <VibeButtonNew
            text="New Ad Provider"
            variant="outlined"
            icon={viAdd}
            color={color.violetVibe}
            disabled={disabled || showNewAdProvider}
            style={{
              marginRight: 12,
            }}
            onClick={onClickNewAdProvider}
          />

        </div>
      </SidePanelHeader>

      <SidePanelContent
        style={{
          padding: 0,
        }}
      >
        {totalProviders > 0 || showNewAdProvider ? (
          <div>
            {showNewAdProvider && (
              <div
                style={{
                  padding: '16px 24px',
                  borderBottom: `4px solid ${color.violetVibe}`,
                }}
              >
                <Field
                  type="select"
                  value=""
                  rootStyle={{
                    width: 240,
                  }}
                  options={[
                    {
                      label: '',
                      value: '',
                      placeholder: 'Select Ad Provider',
                    },
                    ...AdProviderHelper.list.map((adProvider) => {
                      return {
                        label: adProvider.name,
                        value: adProvider.value,
                        disabled: get(adProviderConfig, `${adProvider.value}.length`) >= 0,
                      };
                    }),
                  ]}
                  disabled={disabled}
                  onChange={onChangeNewAdProvider}
                />
              </div>
            )}

            {map(adProviderConfig, (provider, key) => {
              if (key === 'goodvibes') {
                return (
                  <ProviderGoodvibes
                    key={`provider-${key}`}
                    providerKey={key}
                    onRemoveProvider={onRemoveProvider}
                  />
                );
              }

              if (key === 'triton') {
                return (
                  <ProviderTriton
                    key={`provider-${key}`}
                    providerKey={key}
                    onRemoveProvider={onRemoveProvider}
                  />
                );
              }

              if (key === 'vibenomics') {
                return (
                  <ProviderVibenomics
                    key={`provider-${key}`}
                    providerKey={key}
                    onRemoveProvider={onRemoveProvider}
                  />
                );
              }

              if (key === 'vistar') {
                return (
                  <ProviderVistar
                    key={`provider-${key}`}
                    providerKey={key}
                    provider={provider}
                    onValidate={onValidate}
                    onChange={onChangeProviderItem}
                    onAddItem={onAddProviderItem}
                    onRemoveItem={onRemoveProviderItem}
                    onRemoveProvider={onRemoveProvider}
                  />
                );
              }

              // Unknown Provider
              return (
                <ProviderHeader
                  key={`provider-${key}`}
                  title={`Unknown Provider: ${key}`}
                  providerKey={key}
                />
              );
            })}

          </div>
        ) : (
          <EmptyState
            title="No Ad Providers Configured"
            description="Choose new ad provider to configure for this company"
          />
        )}
      </SidePanelContent>

      <VibeModal
        show={confirmClose}
        type="error"
        title="Required Fields Missing"
        text="One or more fields are missing in your configuration."
        onClose={onConfirmClose}
      />
    </SidePanelContainer>
  );
}

AdProviders.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  adProviderConfig: PropTypes.object,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func,
};

AdProviders.defaultProps = {
  className: '',
  style: {},
  adProviderConfig: {},
  disabled: false,
  onUpdate: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(AdProviders);
