// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContainerColumns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 65px;
  margin-left: 55px;
  padding-left: 48px;
  border-bottom: 1px solid #E1E1E9;
  box-sizing: border-box;
}
.ContainerColumns > .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.333333%;
  height: 65px;
  padding: 0 16px;
  border-left: 1px solid #E1E1E9;
  font-size: 14px;
  font-weight: bold;
}
.ContainerColumns > .column > .content {
  display: flex;
  align-items: center;
}
.ContainerColumns > .column > .content > .title {
  flex-grow: 1;
  margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/web/client/src/components/Locations/IndividualLocation/Schedule/Controls/ContainerColumns.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,gCAAA;EACA,sBAAA;AA6EF;AA3EE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,8BAAA;EACA,eAAA;EACA,iBAAA;AA6EJ;AA3EI;EACE,aAAA;EACA,mBAAA;AA6EN;AA3EM;EACE,YAAA;EACA,gBAAA;AA6ER","sourcesContent":["@import '~vibeguide/sass/color.scss';\n\n.ContainerColumns {\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  height: 65px;\n  margin-left: 55px;\n  padding-left: 48px;\n  border-bottom: 1px solid $whiteSmoke;\n  box-sizing: border-box;\n\n  > .column {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 33.333333%;\n    height: 65px;\n    padding: 0 16px;\n    border-left: 1px solid $whiteSmoke;\n    font-size: 14px;\n    font-weight: bold;\n\n    > .content {\n      display: flex;\n      align-items: center;\n\n      > .title {\n        flex-grow: 1;\n        margin-left: 4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aquaForest": `#00a474`,
	"aquaForest8": `rgba(0, 164, 116, 0.08)`,
	"aquaForest16": `rgba(0, 164, 116, 0.16)`,
	"aquaForest75": `rgba(0, 164, 116, 0.75)`,
	"black": `#000000`,
	"boxRed": `#cf0838`,
	"boxPink": `#f57a7a`,
	"boxOrange": `#ff892e`,
	"boxYellow": `#ffd22e`,
	"boxGreen": `#95c653`,
	"boxDarkGreen": `#00a474`,
	"boxBlue": `#33ace1`,
	"boxDarkBlue": `#3361e1`,
	"boxPurple": `#6d6db5`,
	"boxDarkPurple": `#332a4b`,
	"carrot": `#ff892e`,
	"carrot16": `rgba(255, 137, 46, 0.16)`,
	"cerulean": `#33ace1`,
	"cerulean16": `rgba(51, 172, 225, 0.16)`,
	"cosmicVibe": `#332a4b`,
	"cosmicVibe25": `rgba(51, 42, 75, 0.25)`,
	"cosmicVibe50": `rgba(51, 42, 75, 0.5)`,
	"cosmicVibe75": `rgba(51, 42, 75, 0.75)`,
	"crimson": `#c90a31`,
	"darkGray": `#a4a7b7`,
	"darkSlateGray": `#2f2947`,
	"dimGray": `#545454`,
	"fireBrick": `#cf0838`,
	"fireBrick8": `rgba(207, 8, 56, 0.08)`,
	"fireBrick10": `rgba(207, 8, 56, 0.1)`,
	"fireBrick16": `rgba(207, 8, 56, 0.16)`,
	"fireBrick20": `rgba(207, 8, 56, 0.2)`,
	"fireBrick50": `rgba(207, 8, 56, 0.5)`,
	"fireBrick75": `rgba(207, 8, 56, 0.75)`,
	"fireBrick85": `rgba(207, 8, 56, 0.85)`,
	"flamingo": `#f66733`,
	"flamingo16": `rgba(246, 103, 51, 0.16)`,
	"flamingo75": `rgba(246, 103, 51, 0.75)`,
	"grainsboro": `#d5d8e1`,
	"graphBlue": `#1570EF`,
	"gray": `#757575`,
	"lavendarVibe": `#eae9fc`,
	"lightBlue": `#ccdfe8`,
	"lightDimGray": `#6b6b6b`,
	"lighterGray": `#e3e7f4`,
	"lightGray": `#f6f6f6`,
	"lightSlateGray": `#8a8fa3`,
	"lightSmoke": `#f5f4f5`,
	"manatee": `#959aad`,
	"manatee16": `rgba(149, 154, 173, 0.16)`,
	"manatee50": `rgba(149, 154, 173, 0.5)`,
	"mediumGray": `#e0e0e0`,
	"mediumOrchid": `#9932cc`,
	"lightOrchid": `#7F56D9`,
	"obsidian": `#383838`,
	"periwinkle": `#e7e7ff`,
	"rainboots": `#3361e1`,
	"rainboots16": `rgba(51, 97, 225, 0.16)`,
	"royalBlue": `#4085de`,
	"royalBlue16": `rgba(64, 133, 222, 0.16)`,
	"sushi": `#95c653`,
	"teal": `#029a69`,
	"vibeLightPurple": `#e2e1fe`,
	"vibeMediumPurple": `#e7e7ff`,
	"violetVibe": `#6d6db5`,
	"violetVibe16": `rgba(109, 109, 181, 0.16)`,
	"violetVibe40": `rgba(109, 109, 181, 0.4)`,
	"violetVibe50": `rgba(109, 109, 181, 0.5)`,
	"violetVibe60": `rgba(109, 109, 181, 0.6)`,
	"white": `#ffffff`,
	"whiteSmoke": `#E1E1E9`,
	"yellow": `#ffc107`,
	"yellow16": `rgba(255, 193, 7, 0.16)`,
	"yellow75": `rgba(255, 193, 7, 0.75)`,
	"lemon": `#ffd22e`
};
export default ___CSS_LOADER_EXPORT___;
