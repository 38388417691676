import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';

function Information({
  user,
  firstName,
  lastName,
  companyName,
  companyId,
  companyId: currCompanyId,
  email,
  title,
  phone,
  systemIntegrator,
  isIntegrationAccount,
  disableInput,
  disableCompany,
  tags,
  onUpdate,
}) {
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  const onSetCompany = ({ id: companyId, value: companyName }) => {
    const data = !companyId
      ? {
        allLocations: false,
        locations: [],
        locationsData: {
          companies: [],
          locations: [],
        },
        tags: currCompanyId !== companyId
          ? tags.filter(tag => tag.type === 'admin')
          : tags,
      }
      : {};

    onUpdate({
      companyId,
      companyName,
      ...data,
    });
  };

  const onChangeTag = (data) => {
    onUpdate(data);
  };

  return (
    <div className="Information info-user">
      <Grid
        className="row"
        spacing={2}
        container
      >
        <Grid
          className="details-title"
          xs={6}
          item
        >
          Information
        </Grid>
      </Grid>
      {isIntegrationAccount ? (
        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={6} item>
            <Field
              type="text"
              label="System Integrator"
              name="sysIntegrator"
              placeholder="System Integrator"
              value={systemIntegrator}
              tabIndex={1}
              disabled={disableInput}
              onChange={onChange}
              required
              autoFocus
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={6} item>
              <Field
                type="text"
                label="First Name"
                name="fname"
                placeholder="First Name"
                value={firstName}
                tabIndex={1}
                disabled={disableInput}
                onChange={onChange}
                required
                autoFocus
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                label="Last Name"
                name="lname"
                placeholder="Last Name"
                value={lastName}
                tabIndex={2}
                disabled={disableInput}
                onChange={onChange}
                required
              />
            </Grid>
          </Grid>

          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={6} item>
              <Field
                type="company"
                label="Company"
                name="companyName"
                value={companyName}
                tabIndex={3}
                companyProps={{
                  companyId,
                  companyName,
                }}
                disabled={disableCompany || disableInput}
                onChange={onSetCompany}
                required
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="text"
                label="Email"
                name="email"
                placeholder="user@example.com"
                value={email}
                tabIndex={4}
                disabled={disableInput}
                onChange={onChange}
                required
              />
            </Grid>
          </Grid>

          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={6} item>
              <Field
                type="text"
                label="Title"
                name="title"
                placeholder="Title"
                value={title}
                tabIndex={5}
                disabled={disableInput}
                onChange={onChange}
              />
            </Grid>

            <Grid xs={6} item>
              <Field
                type="phone"
                label="Phone"
                name="phone"
                value={phone}
                tabIndex={6}
                disabled={disableInput}
                onChange={onChange}
              />
            </Grid>
          </Grid>

          <Grid
            className="row"
            spacing={2}
            container
          >
            <Grid xs={12} item>
              <Field
                type="tags"
                label="Tags"
                name="tags"
                tagProps={{
                  companyId,
                  tags,
                  allowTypes: ['client', 'admin'],
                  showAs: 'list',
                  assign: user.can('user.assign_tag'),
                }}
                disabled={disableInput}
                onChange={onChangeTag}
                tabIndex={7}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

Information.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  email: PropTypes.string,
  title: PropTypes.string,
  phone: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  disableInput: PropTypes.bool,
  disableCompany: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Information.defaultProps = {
  firstName: '',
  lastName: '',
  companyId: '',
  companyName: '',
  email: '',
  title: '',
  phone: '',
  tags: [],
  disableInput: false,
  disableCompany: false,
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Information);
