import React from 'react';
import PropTypes from 'prop-types';
import {
  VibeIcon,
  color,
} from 'vibeguide';
import './AccountTypeInfo.scss';

function AccountTypeInfo({
  selectedAccountType,
}) {
  return (
    <div className="AccountTypeInfo">

      {selectedAccountType && (
        <>
          <div className="account-type-title">
            <VibeIcon
              icon={selectedAccountType.icon}
              className="account-type-icon"
              color={color.dimGray}
              hoverColor={color.obsidian}
              size={16}
            />
            <span>{selectedAccountType.title}</span>
          </div>
          {selectedAccountType.subTitle}
        </>
      )}
    </div>
  );
}

AccountTypeInfo.propTypes = {
  selectedAccountType: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    icon: PropTypes.func,
  }),
};

AccountTypeInfo.defaultProps = {
  selectedAccountType: {},
};

export default AccountTypeInfo;
