import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Field2 as Field,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './Script.scss';

class Script extends PureComponent {
  /**
   * When a field is changed
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    const {
      onUpdate,
    } = this.props;

    onUpdate({
      [name]: value,
    });
  };

  /**
   * Set the script type to help
   */
  setScriptTypeHelp = () => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      readType: 'interpret',
    });
  };

  /**
   * Set the script type to exact
   */
  setScriptTypeExact = () => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      readType: 'exact',
    });
  };

  render() {
    const {
      script,
      readType,
      instructions,
      scriptVisible,
      disableScript,
      disableInstructions,
      hasFile,
    } = this.props;

    return (
      <div className="Script">
        <Grid
          className="row extra-space"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={12}
            item
          >
            Script
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={6} item>
            <Field
              type="radio"
              label="Help me with my script"
              radioProps={{
                selected: readType === 'interpret',
              }}
              disabled={disableScript}
              onClick={this.setScriptTypeHelp}
            />
          </Grid>

          <Grid xs={6} item>
            <Field
              type="radio"
              label="Use my exact script"
              radioProps={{
                selected: readType === 'exact',
              }}
              disabled={disableScript}
              onClick={this.setScriptTypeExact}
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={12} item>
            <Field
              className="script-textarea"
              type="textarea"
              label="Script"
              name="script"
              placeholder="Message script..."
              value={script}
              tabIndex={6}
              disabled={disableScript}
              required={!hasFile && !disableScript && scriptVisible}
              onChange={this.onChange}
            />
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid xs={12} item>
            <Field
              className="special-instructions-textarea"
              type="textarea"
              label="Special Instructions"
              name="instructions"
              placeholder="(Optional)"
              value={instructions}
              tabIndex={7}
              disabled={disableInstructions}
              onChange={this.onChange}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Script.propTypes = {
  script: PropTypes.string,
  readType: PropTypes.oneOf([
    'interpret',
    'exact',
  ]),
  instructions: PropTypes.string,
  scriptVisible: PropTypes.bool,
  disableScript: PropTypes.bool,
  disableInstructions: PropTypes.bool,
  hasFile: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Script.defaultProps = {
  script: '',
  readType: 'interpret',
  instructions: '',
  scriptVisible: false,
  disableScript: false,
  disableInstructions: false,
  hasFile: false,
  onUpdate: () => {},
};

export default Script;
