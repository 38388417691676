import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import ProfileExternalIdItem from './ProfileExternalIdItem';

function ProfileExternalIds({
  className,
  style,
  externalIds,
}) {
  // sort the external ID's alphabetically
  externalIds = sortBy(externalIds, 'source');

  return (
    <div
      className={classNames('ProfileExternalIds', className)}
      style={style}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}
      >
        {externalIds.map((item) => (
          <ProfileExternalIdItem
            key={`external-id-${item.extId}`}
            source={item.source}
            extId={item.extId}
          />
        ))}
      </div>
    </div>
  );
}

ProfileExternalIds.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  externalIds: PropTypes.arrayOf(PropTypes.object),
};

ProfileExternalIds.defaultProps = {
  className: '',
  style: {},
  externalIds: [],
};

export default ProfileExternalIds;
